import axiosInstance from '../../../utils/apiHandler/axiosInstance';

const getAll = async (payload) => {
  try {
    const response = await axiosInstance.get(payload.endpoint);
    return response.data;
  } catch (error) {
    console.log(error);
    return {error: error.response.data.message};
  }
};

const deleteSite = async (payload) => {
  try {
    const response = await axiosInstance.delete(payload?.endpoint);
    return response.data;
  } catch (error) {
    return {error: error.response.data.message};
  }
};

const getSingleSite = async (payload) => {
  try {
    const response = await axiosInstance.get(payload?.endpoint);
    return response.data;
  } catch (error) {
    return {error: error.response.data.message};
  }
};

const createSite = async (payload) => {
  try {
    const response = await axiosInstance.post(
      payload.endpoint,
      payload.data,
    );
    return response.data;
  } catch (error) {
    return {error: error.response.data.message};
  }
};

const updateSite = async (payload) => {
  try {
    const response = await axiosInstance.put(
      payload.endpoint,
      payload.data,
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return {error: error.response.data.message};
  }
};

const markSites = async (payload) => {
  try {
    const response = await axiosInstance.put(
      payload?.endpoint,
      payload?.data,
    );
    return response.data;
  } catch (error) {
    return {error: error.response.data.message};
  }
};


const deleteOperativeSiteLink = async (payload) => {
  try {
    const response = await axiosInstance.delete(payload?.endpoint);
    return response.data;
  } catch (error) {
    return {error: error.response.data.message};
  }
};

const createOperativeSiteLink = async (payload) => {
  try {
    const response = await axiosInstance.post(
      payload.endpoint,
      payload.data,
    );
    return response.data;
  } catch (error) {
    return {error: error.response.data.message};
  }
};

const updateOperativeSiteLink = async (payload) => {
  try {
    const response = await axiosInstance.put(
      payload.endpoint,
      payload.data,
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return {error: error.response.data.message};
  }
};

export const sitesService = {
  getAll,
  createSite,
  updateSite,
  deleteSite,
  markSites,
  getSingleSite,
  createOperativeSiteLink,
  deleteOperativeSiteLink,
  updateOperativeSiteLink
};

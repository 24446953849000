import axiosInstance from '../../../utils/apiHandler/axiosInstance';

const getDashboard = async () => {
  try {
    const response = await axiosInstance.get('/Dashboard/get-dashboard');
    return response.data;
  } catch (error) {
    console.log(error);
    return {error: error.response.data.message};
  }
};
const getGraphs = async () => {
  try {
    const response = await axiosInstance.get('/Dashboard/graphs');
    return response.data;
  } catch (error) {
    console.log(error);
    return {error: error.response.data.message};
  }
};

const getAll = async (data) => {
  try {
    console.log('get Data', data);
    const response = await axiosInstance.get(
      `Employee/get-all?SortField=${data?.SortField}&SortDir=${data?.SortDir}&PageNumber=${data?.PageNumber}&PageSize=${data?.PageSize}&operativeName=${data?.operativeName}&jobType=${data?.jobType}`,
      data,
    );
    if (response.data.httpStatus === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (err) {
    return {error: err.response.data.message};
  }
};

const getAllRecords = async (payload) => {
  try {
    const response = await axiosInstance.get(
      payload?.endpoint,
    );
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (err) {
    return {error: err.response.data.message};
  }
};

const post = async (payload) => {
  console.log('inside post');
  try {
    const response = await axiosInstance.post(
      payload?.endpoint,
      payload.data,
    );
    if (response.data) {
      return response.data;
    } else {
      return false;
    }
  } catch (err) {
    return {error: err.response.data.message};
  }
};

const update = async (data) => {
  try {
    const response = await axiosInstance.put(
      `Employee/update/${data?.id}`,
      data,
    );
    if (response.data.success) {
      return response.data;
    } else {
      return false;
    }
  } catch (err) {
    return {error: err.response.data.message};
  }
};

const deleteRecord = async (payload) => {
  const response = await axiosInstance.delete(
    payload?.endpoint,
    payload.data,
  );
  return response.data;
};

const updateAllRecords = async (payload) => {
  try {
    const response = await axiosInstance.put(
      payload?.endpoint,
      payload?.data,
    );
    if (response.data) {
      return response.data;
    } else {
      return false;
    }
  } catch (err) {
    return {error: err.response.data.message};
  }
};

export const appServices = {
  getDashboard,
  getGraphs,
  getAll,
  update,
  getAllRecords,
  post,
  updateAllRecords,
  deleteRecord,
};

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {TextField, InputAdornment, IconButton, Box} from '@mui/material';
import {MdOutlineEdit, MdMailOutline, MdOutlineDelete} from 'react-icons/md';
import {MdOutlineClose} from 'react-icons/md';
import ReactSelect from 'react-select';
import {toast} from 'react-toastify';

import {
  getAllOperatives,
  updateOperative,
  createOperative,
  deleteOperative,
  exportOperatives,
  operativesActions
} from '../../../data/reducers/app/operatives.reducer';
import {getAll} from '../../../data/reducers/app/dropdown.reducer';
//import {details} from '../../../utils/details';
import {tableData} from '../../../utils/tableData';

import AddModalOperative from './AddModalOperative';
import DataTable from '../../../components/dataTable/DataTable';
import Navigation from '../../../components/navigation/Navigation';
import ProButton from '../../../components/common-comp/ProButton';
import Title from '../../../components/common-comp/Title';
import ConfirmationDialog from '../../../components/ConfirmationDialog';

import '../../../components/common-comp/CommonComp.css';

function Employees() {
  const [addPopup, setAddPopup] = useState(false);
  const [editRow, setEditRow] = useState('');
  const [showButtons, setShowButtons] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [SortField, setSortField] = useState('');

  const [filters, setFilters] = useState({
    PageNumber: 1,
    SortDir: 1,
    SortField: '',
    PageSize: 10,
    operativeName: '',
    operativeType: '',
    jobRoleId:''
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {operatives, error, message, paging} = useSelector(
    (state) => state.operatives,
  );
  const {lstAllJobRoles, lstAllUserRoles} = useSelector(
    (state) => state.dropdown,
  ).dropdown;

  const stateData = operatives;

  const role = JSON.parse(localStorage.getItem('user'))?.user?.role;

  const customContent = {
    key: 'employees',
    title: 'Operatives',
    filters: [
      {name: 'Filter by Operative Name'},
      {name: 'Filter by Operative Type'},
    ],
    cta: [
      {
        className: 'view-all',
        key: 'employees',
        value: '+ Add Operative',
      },
    ],
  };

  useEffect(() => {
    //<-- every time the component renders, it calls handleFetch() to fetch all the records.
    fetchData();
    if (filters?.operativeName) {
      setShowButtons(true);
    }
  }, [filters],[SortField]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    } else if (message) {
      toast.success(message, {
        toastId: 'success1',
      });
    }
  }, [error, message]);

  const fetchData = async () => {
    // <--- Gets all operative data
    setAddPopup(false);
    setShowButtons(false);
    dispatch(operativesActions?.operativeReset)
    {role === 'Admin' && (
      await dispatch(
        getAllOperatives({
          endpoint: `Employee/get-all?SortField=${
            SortField
          }&SortDir=${filters?.SortDir}&PageNumber=${
            filters?.PageNumber
          }&PageSize=${filters?.PageSize}&operativeName=${
            filters?.operativeName
          }&jobType=${
            filters?.operativeType ? filters?.operativeType?.name : ''
          }`,
        }),
      )
    )}

    {role === 'Site Manager' && (
      await dispatch(
        getAllOperatives({
          endpoint: `Employee/get-all?SortField=${
            SortField
          }&SortDir=${filters?.SortDir}&PageNumber=${
            filters?.PageNumber
          }&PageSize=${filters?.PageSize}&operativeName=${
            filters?.operativeName
          }&jobType=${
            filters?.jobRoleId ? filters?.jobRoleId?.jobRole : ''
          }`,
        }),
      )
    )}
    
    await dispatch(getAll());
  };

  const sortHandle = (name) => {
		//debugger
		if (SortField === name) {
			console.log("yes same field")
      setSortField(name);
			if (filters?.SortDir === 0){
        setFilters({...filters, SortDir: 1});
      }else{
        setFilters({...filters, SortDir: 0});
      }
		} else {
			setSortField(name);
			setFilters({...filters, SortDir: 1});
		}
	};

  // const employeeType = [
  //   {value: 'Site Manager'},
  //   {value: 'Site Operative'},
  // ];

  const tableCols = tableData.operatives?.columns.map((column) => {
    if (column?.link) {
      return {
        name: column.name,
        label: column.label,
        options: {
          customBodyRender: (value, tableMeta) => {
            let temp = stateData[tableMeta?.rowIndex];
            return (
              <div
                onClick={() => {
                  navigate(`/operatives/detail`, {
                    state: {content: temp},
                  });
                }}
                style={{cursor: 'pointer', textDecoration: 'underline'}}>
                {value}
              </div>
            );
          },
          customHeadRender: () =>{
            return (
              <Title
                name={column?.name}
                title={column?.label}
                sortField={SortField}
                sortDir={filters?.SortDir}
                sortHandle={sortHandle}
				      />
            )
          },
        },
      };
    }else if(column?.noSort){
      return {
        name: column.name,
        label: column.label,
      };
    }else {
      return {
        name: column.name,
        label: column.label,
        options: {
          customHeadRender: () =>{
            return (
              <Title
                name={column?.name}
                title={column?.label}
                sortField={SortField}
                sortDir={filters?.SortDir}
                sortHandle={sortHandle}
				      />
            )
          },
        }
      };
    }
  });

  tableCols?.push({
    name: 'Actions',
    options: {
      customBodyRender: (_, tableMeta) => {
        return (
          <span
            style={{
              padding: '.5rem',
              width: 'max-content',
            }}>
            {role === 'Admin' && (
              <>
                <button
                onClick={() => handleEdit(tableMeta)}
                className='table-buttons'
                >
                  <MdOutlineEdit size="1.8rem" />
                </button>

                <button 
                className='table-buttons'
                onClick={()=>{
                  setConfirmPopup(true);
                  setDeleteId(stateData[tableMeta.rowIndex]?.id);
                }}
                >
                  <MdOutlineDelete size="1.8rem" />
                </button>
              </>
            )}

            <button className='table-buttons'>
              <MdMailOutline size="1.8rem" />
            </button>
          </span>
        );
      },
    },
  });

  const handleButtons = (val) => {
    {
      val ? setShowButtons(true) : setShowButtons(false);
    }
  };

  const addEmployee = () => {
    // <--- on click of "Add Employee" button
    setAddPopup(true);
    setEditRow('');
  };

  const submitModal = async (data, updated) => {
    // <--- Add-modal submission to create a new employee
    if (updated) {
      await dispatch(
        updateOperative({endpoint: `Employee/update/${data?.id}`, data}),
      );
      if (!error) {
        setAddPopup(false);
      }
    } else {
      await dispatch(
        createOperative({
          endpoint: `Authentication/register-operative`,
          data,
        }),
      );
      if (!error) {
        setAddPopup(false);
      }
    }
    await fetchData();
    setAddPopup(false);
  };

  const deleteOperativeFunction = async () => {
    if (deleteId) {
      await dispatch(
        deleteOperative({endpoint: `Employee/delete/${deleteId}`}),
      );
    }
    setDeleteId('');
    fetchData();
  };

  const exportOperativesFn = async() =>{
    console.log("filters:", filters);
    await (dispatch(exportOperatives({
      endpoint: `Employee/export-operatives?operativeName=${
        filters?.operativeName
      }&jobType=${
        filters?.jobRoleId ? filters?.jobRoleId?.jobRole : ''
      }`,
    })))
  }

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: 190,
      fontSize: '1.3rem',
      marginTop: '.6rem',
    }),
    option: (provided, {isFocused}) => ({
      ...provided,
      fontSize: '1.3rem',
      backgroundColor: isFocused ? '#329BEF' : 'white',
      color: isFocused ? 'white' : 'black',
      zIndex: 400,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 900,
    }),
    control: (styles) => ({...styles, padding: '0.2rem'}),
  };

  const handleEdit = (tableMeta) => {
    // <--- For editing a record
    console.log('edit');

    setAddPopup(true);
    setEditRow(stateData[tableMeta?.rowIndex]);
    console.log(editRow);
  };

  const navigation = [
    
    {
      name: ' Operatives',
      path: ''
    },
  ]

  return (
    <Box className="container">
      <Box className="page-container">
        <section className="sp-between">
          <Navigation path={navigation} />
        </section>
        <section className="section-top">
          <h1>{customContent.title}</h1>
          {customContent?.weeklyCost && (
            <section className="weekly-cost">
              <span>Weekly Cost: </span>
              <span>
                <strong>£18,920.20</strong>
              </span>
            </section>
          )}
        </section>
        <section className="section-filters">
          <Box className="filter">
            <Box>
              <p
                style={{
                  fontSize: '1.3rem',
                  fontWeight: '500',
                  marginBottom: '.4rem',
                }}>
                Operative name
              </p>
              <TextField
                id="outlined-required"
                placeholder="Search..."
                fullWidth
                value={filters?.operativeName}
                onChange={(e) => {
                  setFilters({...filters, operativeName: e.target.value});
                  
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {showButtons && (
                        <IconButton
                          onClick={() => {
                            setFilters({...filters, operativeName: ''});
                          }}>
                          <MdOutlineClose size="2rem" />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            {role === 'Admin' && (
              <Box>
                <p
                  style={{
                    fontSize: '1.3rem',
                    fontWeight: '500',
                    marginBottom: '.4rem',
                  }}>
                  Operative Type
                </p>
                <ReactSelect
                  name="role"
                  required
                  styles={customStyles}
                  options={lstAllUserRoles}
                  getOptionLabel={(option) => option?.name}
                  value={filters?.operativeType || ''}
                  defaultValue=""
                  isClearable
                  placeholder="Select Operative Type"
                  onChange={(e) => {
                    setFilters({...filters, operativeType: e});
                  }}
                />
              </Box>
            )}
            {role === 'Site Manager' && (
              <Box>
                <p
                  style={{
                    fontSize: '1.3rem',
                    fontWeight: '500',
                    marginBottom: '.4rem',
                  }}>
                  Job Role
                </p>
                <ReactSelect
                  name="role"
                  required
                  styles={customStyles}
                  options={lstAllJobRoles}
                  getOptionLabel={(option) => option?.jobRole}
                  value={filters?.jobRoleId || ''}
                  defaultValue=""
                  isClearable
                  placeholder="Select Job Role"
                  onChange={(e) => {
                    setFilters({...filters, jobRoleId: e});
                  }}
                />
              </Box>
            )}
          </Box>
          <Box className="bottom-buttons">
            {role === 'Admin' && (
              <ProButton
                text="+ Add Operative"
                color="#6eb066"
                handleClick={addEmployee}
                variant="contained"
                mx0
              />
            )}
            {role === 'Site Manager' && (
              <>
                <Box mr={1}>
                  <ProButton
                    text="Send E-Mail"
                    color="#cb272f"
                    variant="outlined"
                    mx0
                  />
                </Box>
                <ProButton
                  text="Export Data"
                  color="#cb272f"
                  variant="contained"
                  handleClick={exportOperativesFn}
                  mx0
                />
              </>
            )}
          </Box>
        </section>
        <section className="section-table">
          <DataTable
            newCols={tableCols ? tableCols : []}
            newData={stateData ? stateData : []}
            handleButtons={handleButtons}
            pagination={true}
            filter={filters}
            setFilter={setFilters}
            totalCount={paging?.totalItemsCount}
          />
        </section>
      </Box>

      {/* Add Modal */}
      <AddModalOperative
        open={addPopup}
        handleClose={() => setAddPopup(false)}
        submitHandle={submitModal}
        selectedRecord={editRow}
      />

      <ConfirmationDialog
        open={confirmPopup}
        text="Are you sure you want to remove this operative?"
        handleClose={() => setConfirmPopup(false)}
        submitHandle={() => {
          deleteOperativeFunction();
          setConfirmPopup(false);
        }}
      />
    </Box>
  );
}

export default Employees;

import React, {useState, useEffect} from 'react';
import ReactSelect from 'react-select';
import {toast} from 'react-toastify';
import {useLocation, useNavigate} from 'react-router-dom';
import {Box, Grid, TextField, Typography} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';

import {
  rateCardActions,
  saveRateCard,
} from '../../../data/reducers/app/rateCards.reducer';
import {getAll} from '../../../data/reducers/app/dropdown.reducer';

import AddModalJobRate from './AddModalJobRate';
import DataTable from '../../../components/dataTable/DataTable';
import Navigation from '../../../components/navigation/Navigation';
import ProButton from '../../../components/common-comp/ProButton';

import '../../../components/common-comp/CommonComp.css';

function AddPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customContent = location.state.content;

  const [addPopup, setAddPopup] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [inputVal, setInputVal] = useState('');

  const {lstActiveJobRoles} = useSelector(
    (state) => state.dropdown.dropdown,
  );

  const {error, message} = useSelector((state) => state.rateCards);

  useEffect(() => {
    dispatch(getAll());
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(error);
    } else if (message) {
      toast.success(message);
    }
  }, [error, message]);

  const addJobRole = () => {
    setAddPopup(true);
  };

  const saveRecordFunction = async () => {
    if(inputVal){
      const data = {
        rateCardName: inputVal,
        rateDetailsList: tableData,
      };
      await dispatch(
        saveRateCard({
          endpoint: `RateCards/create`,
          data,
        }),
      );
      setInputVal('');
      dispatch(rateCardActions.rateCardReset());
      navigate('/rate-cards');
    }else{
      toast.error("Please enter rate card name")
    }
    
  };

  const submitModal = (data) => {
    setTableData((tableData) => [...tableData, data]);
    setAddPopup(false);
  };

  const customStyles = {
    container: (provided) => ({
      ...provided,
      fontSize: '1.3rem',
    }),
    option: (provided, {isFocused}) => ({
      ...provided,
      fontSize: '1.3rem',
      backgroundColor: isFocused ? '#329BEF' : 'white',
      color: isFocused ? 'white' : 'black',
      zIndex: 400,
    }),
  };

  const cols = [
    {
      name: 'jobRole',
      label: 'Job Role',
      options: {
        customBodyRender: (_, tableMeta) => {
          return (
            <ReactSelect
              value={
                lstActiveJobRoles?.filter(
                  (val) =>
                    val.id === tableData[tableMeta.rowIndex]?.jobRoleId,
                )[0]
              }
              isDisabled
              options={lstActiveJobRoles}
              getOptionLabel={(option) => option?.jobRole}
              styles={customStyles}
              menuPortalTarget={document.body}
            />
          );
        },
      },
    },
    {
      name: 'nightShiftRate',
      label: 'Night Shift Rate',
    },
    {
      name: 'overtimeRate',
      label: 'Overtime Rate',
    },
    {
      name: 'regularRate',
      label: 'Regular Rate',
    },
  ];

  return (
    <div>
      <div className="page-container">
        <Navigation path="Add Rate Cards" />

        <Box className="section-top">
          <h1>{customContent.title}</h1>

          {customContent?.weeklyCost && (
            <section className="weekly-cost">
              <span>Weekly Cost: </span>
              <span>
                <strong>£18,920.20</strong>
              </span>
            </section>
          )}
        </Box>

        <Grid container spacing={3}>
          {customContent.filters.map((filter, i) => {
            return (
              <Grid key={i} item xl={3} lg={4} md={5} sm={8} xs={12}>
                <Typography variant="h6">{filter?.name}</Typography>
                <TextField
                  id="outlined-required"
                  placeholder="Rate Card name"
                  value={inputVal}
                  onChange={(e) => {
                    setInputVal(e.target.value);
                  }}
                  fullWidth
                />
              </Grid>
            );
          })}
        </Grid>

        {customContent?.showTable && (
          <section className="section-table">
            <DataTable newCols={cols} newData={tableData} />
          </section>
        )}

        <Box spacing={2} flexDirection="row-reverse" display="flex">
          <ProButton
            text="Save"
            color="#cb272f"
            handleClick={saveRecordFunction}
            variant="contained"
          />
          <ProButton
            text="+ Add Job Role"
            color="#cb272f"
            handleClick={addJobRole}
            variant="contained"
          />
        </Box>
      </div>
      <AddModalJobRate
        open={addPopup}
        handleClose={() => setAddPopup(false)}
        submitHandle={submitModal}
        keyVal={customContent.key}
      />
    </div>
  );
}

export default AddPage;

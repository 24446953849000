import {Box, } from '@mui/material';
import React, {useState} from 'react';
import {Outlet} from 'react-router-dom';

import './Layout.css';
import Sidebar from '../components/sidebar/Sidebar';
import {useSelector} from 'react-redux';
import ProBackdrop from '../components/common-comp/Backdrop';
import DrawerAppBar from '../components/DrawerAppBar';

function Layout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  let user = JSON.parse(localStorage.getItem('user'));
  const role = user?.user?.role;

  const {loading: loading1} = useSelector((state) => state.rateCards);
  const {loading: loading2} = useSelector((state) => state.sites);
  const {loading: loading3} = useSelector((state) => state.operatives);
  const {loading: loading4} = useSelector((state) => state.timesheets);
  const {loading: loading5} = useSelector((state) => state.auth);

  const handleSidebarToggle = () =>{
    setSidebarOpen(!sidebarOpen);
  }

  const sidebarNavlinks = [];

  if (role === 'Admin') {
    sidebarNavlinks.push(
      {
        linkName: 'Dashboard',
        path: '/',
        src: 'dashboard',
        id: 1,
      },
      {
        id: 2,
        linkName: 'Operatives',
        path: '/operatives',
        src: 'employees',
      },
      {
        linkName: 'Timesheets',
        path: '/timesheets',
        src: 'timesheets',
        id: 3,
      },
      {
        linkName: 'Sites',
        path: '/sites',
        src: 'sites',
        id: 4,
      },
      {
        linkName: 'Rate Cards',
        path: '/rate-cards',
        src: 'rateCards',
        id: 5,
      },
    );
  } else if (role === 'Site Manager') {
    sidebarNavlinks.push(
      {
        linkName: 'Dashboard',
        path: '/',
        src: 'dashboard',
        id: 1,
      },
      {
        id: 2,
        linkName: 'Operatives',
        path: '/operatives',
        src: 'employees',
      },
      {
        linkName: 'Timesheets',
        path: '/timesheets',
        src: 'timesheets',
        id: 3,
      },
      {
        id: 6,
        linkName: 'My Details',
        path: '/detail',
        src: 'details',
      },
    );
  } else if (role?.includes('Operative')) {
    sidebarNavlinks.push(
      {
        linkName: 'My Timesheets',
        path: '/my-timesheets',
        src: 'timesheets',
        id: 3,
      },
      {
        id: 6,
        linkName: 'My Details',
        path: '/detail',
        src: 'details',
      },
    );
  }

  return (
    <>
      <DrawerAppBar handleClick={handleSidebarToggle} />
      <Box className="layout">
        <ProBackdrop
          loading={
            loading1 || loading2 || loading3 || loading4 || loading5
          }
        />
        <Box>
          <Sidebar sidebarNavlinks={sidebarNavlinks} isOpen={sidebarOpen} />
        </Box>

        <Box className={ sidebarOpen ? "main-box main-exp" : "main-box main-coll"} >
          <Outlet />
        </Box>
      </Box>
      {/* <Box className="mobile-content">
        <Typography variant="h3" fontWeight="bold" textAlign="center">
          Application is currently not available for mobile devices.
        </Typography>
      </Box> */}
    </>
  );
}

export default Layout;

import React, {useEffect, useState} from 'react';
import ReactSelect from 'react-select';
import {MdOutlineEdit, MdOutlineDelete} from 'react-icons/md';
import {useDispatch, useSelector} from 'react-redux';
import {Box, IconButton, Typography} from '@mui/material';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';

import {
  updateRateCard,
  getSingleRateCard,
  deleteJobRate,
  updateJobRate,
  createJobRate,
  rateCardActions,
} from '../../../data/reducers/app/rateCards.reducer';
import {getAll} from '../../../data/reducers/app/dropdown.reducer';
import {convertToPounds} from '../../../utils/currency';

import AddModalJobRate from './AddModalJobRate';
import DataTable from '../../../components/dataTable/DataTable';
import Navigation from '../../../components/navigation/Navigation';
import ProButton from '../../../components/common-comp/ProButton';
import ConfirmationDialog from '../../../components/ConfirmationDialog';

import '../../../components/common-comp/CommonComp.css';
import AddRateCard from './AddRateCard';

function EditRateCard() {
  const dispatch = useDispatch();
  const {id} = useParams();

  const [addPopup, setAddPopup] = useState(false);
  const [ratePopup, setRatePopup] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [editRow, setEditRow] = useState('');
  const [refresh, setRefresh] = useState(0);
  const [deleteId, setDeleteId] = useState('');

  const {lstActiveJobRoles} = useSelector(
    (state) => state.dropdown.dropdown,
  );

  const {singleRateCard, error, message} = useSelector(
    (state) => state.rateCards,
  );

  useEffect(() => {
    setTableData(singleRateCard?.rateDetailsResponse);
  }, [singleRateCard]);

  useEffect(() => {
    handleFetch();
  }, [id, refresh]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    } else if (message) {
      toast.success(message);
    }
  }, [error, message]);

  useEffect(() => {
    getAll();
    setAddPopup(false);
    setConfirmPopup(false);
  }, []);

  const handleFetch = async () => {
    await dispatch(
      getSingleRateCard({endpoint: `RateCards/get-ratecard/${id}`}),
    );
  };

  const customStyles = {
    container: (provided) => ({
      ...provided,
      fontSize: '1.3rem',
    }),
    option: (provided, {isFocused}) => ({
      ...provided,
      fontSize: '1.3rem',
      backgroundColor: isFocused ? '#329BEF' : 'white',
      color: isFocused ? 'white' : 'black',
      zIndex: 400,
    }),
  };

  const addJobRole = () => {
    setAddPopup(true);
    setEditRow('');
  };

  const deleteJobRole = async () => {
    console.log("delete id,", deleteId)
    setRefresh(refresh + 1);
    await dispatch(
      deleteJobRate({
        endpoint: `RateCards/delete-jobrate/${deleteId}`,
      }),
    );
    if (!error) {
      handleFetch();
    }
  };

  const addRatecard = async (data,updated) => {
    
    if(updated){
      await dispatch(
        updateRateCard({
          endpoint: `RateCards/update/${id}`,
          data,
        }),
      );
    }

    if(!error){
      dispatch(rateCardActions.rateCardReset());

      setRatePopup(false);
      setRefresh(refresh + 1);
    }
  };

  const submitModal = async (saveData, updated) => {
    if (updated) {
      const {jobRoleId, regularRate, overtimeRate, nightShiftRate} =
        saveData;
      const data = {
        jobRoleId,
        regularRate,
        overtimeRate,
        nightShiftRate,
      };
      await dispatch(
        updateJobRate({
          endpoint: `RateCards/update-jobrate/${editRow?.id}`,
          data,
        }),
      );
      
    } else {
      const {jobRoleId, regularRate, overtimeRate, nightShiftRate} =
        saveData;

      let checkIndex = false;
      tableData.map((row) => {
        if (row?.jobRoleId == jobRoleId) {
          checkIndex = true;
        }
      });

      if (!checkIndex) {
        const data = {
          rateCardId: id,
          jobRoleId,
          regularRate,
          overtimeRate,
          nightShiftRate,
        };
        await dispatch(
          createJobRate({
            endpoint: `RateCards/create-jobrate`,
            data,
          }),
        );
      } else {
        toast.error('Job Role already exists');
      }
    }

    if(!error){
      dispatch(rateCardActions.rateCardReset());

      setAddPopup(false);
      setRefresh(refresh + 1);
    }
  };

  const cols = [
    {
      name: 'jobRole',
      label: 'Job Role',
      options: {
        customBodyRender: (_, tableMeta) => {
          return (
            <ReactSelect
              value={
                tableData
                  ? lstActiveJobRoles?.filter(
                      (val) =>
                        val.id ===
                        tableData[tableMeta.rowIndex]?.jobRoleId,
                    )[0]
                  : ''
              }
              defaultValue=""
              onChange={(e) => {
                let temp = [];
                temp[tableMeta.rowIndex] = e.id;
              }}
              isDisabled
              options={lstActiveJobRoles}
              getOptionLabel={(option) => option?.jobRole}
              styles={customStyles}
              menuPortalTarget={document.body}
            />
          );
        },
      },
    },
    {
      name: 'nightShiftRate',
      label: 'Night Shift Rate',
      options: {
        customBodyRender: (value) => {
          return <div>{convertToPounds(value)}</div>;
        },
      },
    },
    {
      name: 'overtimeRate',
      label: 'Overtime Rate',
      options: {
        customBodyRender: (value) => {
          return <div>{convertToPounds(value)}</div>;
        },
      },
    },
    {
      name: 'regularRate',
      label: 'Regular Rate',
      options: {
        customBodyRender: (value) => {
          return <div>{convertToPounds(value)}</div>;
        },
      },
    },
  ];

  cols?.push({
    name: 'Actions',
    options: {
      customBodyRender: (_, tableMeta) => {
        return (
          <span
            style={{
              padding: '.5rem',
              width: 'max-content',
            }}>
            <button
              onClick={() => {
                setAddPopup(true);
                setEditRow(tableData[tableMeta?.rowIndex]);
              }}
              className="table-buttons">
              <MdOutlineEdit size="1.8rem" />
            </button>
            <button
              onClick={() => {
                setDeleteId(tableData[tableMeta?.rowIndex]?.id);
                setConfirmPopup(true)
                //deleteJobRole(tableData[tableMeta?.rowIndex]);
              }}
              className="table-buttons">
              <MdOutlineDelete size="1.8rem" />
            </button>
          </span>
        );
      },
    },
  });

  const navigation = [
    {
      name: ' Rate Cards / ',
      path: '/rate-cards'
    },
    {
      name: 'Details '
    }
  ]

  return (
    <div>
      <div className="page-container">
        <Navigation path={navigation} />

        <section className="section-top">
          <Box display='flex'>
            <Typography variant='h1' fontWeight="bold">{singleRateCard?.rateCardName}</Typography>
            <IconButton onClick={() => setRatePopup(true)}>
              <MdOutlineEdit size="2rem" />
            </IconButton>
          </Box>
        </section>

        <Box className="section-table">
          <DataTable newCols={cols} newData={tableData} />
        </Box>

        <Box spacing={2} flexDirection="row-reverse" display="flex">
          {/* <ProButton
            text="Save"
            color="#cb272f"
            handleClick={saveRecord}
            variant="contained"
          /> */}
          <ProButton
            text="+ Add Job Role"
            color="#cb272f"
            handleClick={addJobRole}
            variant="contained"
          />
        </Box>
      </div>
      <AddModalJobRate
        open={addPopup}
        handleClose={() => setAddPopup(false)}
        submitHandle={submitModal}
        selectedRecord={editRow}
      />
      <AddRateCard
        open={ratePopup}
        handleClose={() => setRatePopup(false)}
        submitHandle={addRatecard}
        selectedRecord={{rateCardName: singleRateCard?.rateCardName}}
      />
      <ConfirmationDialog
        open={confirmPopup}
        text="Are you sure you want to delete this job rate?"
        handleClose={() => setConfirmPopup(false)}
        submitHandle={() => {
          deleteJobRole();
          setConfirmPopup(false);
        }}
      />
    </div>
  );
}

export default EditRateCard;

import React from 'react';
import {NavLink} from 'react-router-dom';
import {GiCargoCrane} from 'react-icons/gi';
import {BiSpreadsheet} from 'react-icons/bi';
import {
  MdPeopleOutline,
  MdOutlineDashboard,
  MdInfoOutline,
  MdOutlineLibraryBooks,
} from 'react-icons/md';
import {Box} from '@mui/material';

import './sidebar.css';

function Sidebar({sidebarNavlinks, isOpen}) {
  const handleIcons = (linkname) => {
    if (linkname === 'rateCards') {
      return <BiSpreadsheet size="2.5rem" />;
    } else if (linkname === 'sites') {
      return <GiCargoCrane size="2.5rem" />;
    } else if (linkname === 'timesheets') {
      return <MdOutlineLibraryBooks size="2.5rem" />;
    } else if (linkname === 'employees') {
      return <MdPeopleOutline size="2.5rem" />;
    } else if (linkname === 'dashboard') {
      return <MdOutlineDashboard size="2.5rem" />;
    } else if (linkname === 'details') {
      return <MdInfoOutline size="2.5rem" />;
    }
  };

  return (
    <Box className={isOpen ? "sidebar side-exp" : "sidebar side-coll"} paddingLeft={1}>
      {sidebarNavlinks.map((link) => {
        if(isOpen){
          return (
            <NavLink
              className="nav-link "
              to={link.path}
              style={({isActive}) => ({
                background: isActive ? '#CB272F' : '',
                color: isActive ? '#fff' : '#000',
              })}
              key={link?.id}>
              {handleIcons(link.src)}
              <span className="link-name">{link.linkName}</span>
            </NavLink>
          );
        }else{
          return (
            <NavLink
              className="nav-link nav-coll"
              to={link.path}
              style={({isActive}) => ({
                background: isActive ? '#CB272F' : '',
                color: isActive ? '#fff' : '#000',
              })}
              key={link?.id}>
              {handleIcons(link.src)}
            </NavLink>
          );
        }
      })}
    </Box>
  );
}

export default Sidebar;

import React, { useEffect } from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { getAll } from '../data/reducers/app/dropdown.reducer';

import RequireAuth from '../components/RequireAuth';
import Admin from '../screens/App/admin/Admin';
import Layout from '../containers/Layout';
import Employees from '../screens/App/operatives/Employees';
import Timesheets from '../screens/App/timesheets/Timesheets';
import RateCards from '../screens/App/rate-cards/RateCards';
import OperativesDetails from '../screens/App/operatives/OperativesDetails';
import EditPage from '../components/common-details/EditPage';
import AddPage from '../screens/App/rate-cards/AddPage';
import Details from '../screens/App/Details';
import DashDetail from '../screens/App/timesheets/DashDetail';
import TimesheetDetails from '../screens/App/timesheets/TimesheetDetails';
import RateCardsDetails from '../screens/App/rate-cards/RateCardsDetails';
import EditRateCard from '../screens/App/rate-cards/EditRateCard';

import Sites from '../screens/App/sites/Sites';
import SiteDetails from '../screens/App/sites/SiteDetails';
import MyTimesheet from '../screens/App/timesheets/MyTimesheet';

const AppRoutes = () => {
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(getAll())
  },[]);

  return (
    <Routes>
      {/* Public Routes */}

      <Route path="/" element={<Layout />}>
        {/* Public Routes */}
        <Route path="/" element={<Admin />} />
        <Route path="/edit" element={<EditPage />} />

        {/* Protected routes */}
        <Route
          element={
            <RequireAuth allowedRoles={['Admin', 'Site Manager']} />
          }>
          <Route path="operatives" element={<Employees />} />
          <Route
            path="operatives/detail"
            element={<OperativesDetails />}
          />
        </Route>

        <Route
          element={
            <RequireAuth
              allowedRoles={['Admin', 'Site Manager']}
            />
          }>
          <Route path="timesheets" element={<Timesheets />} />
          <Route
            path="timesheets/detail/:id"
            element={<TimesheetDetails editable={false} />}
          />
          <Route
            path="timesheets/edit/:id"
            exact
            element={<TimesheetDetails editable={true} />}
          />
        </Route>

        <Route element={<RequireAuth allowedRoles={['Admin']} />}>
          {/* Rate Cards */}
          <Route path="rate-cards" element={<RateCards />} />
          <Route path="rate-cards/add" element={<AddPage />} />
          <Route
            path="rate-cards/details/:id"
            element={<RateCardsDetails />}
          />
          <Route path="rate-cards/edit/:id" element={<EditRateCard />} />

          {/* Sites */}
          <Route path="/sites" element={<Sites />} />
          <Route path="sites/details/:id" element={<SiteDetails />} />

          {/* Timesheet */}
          <Route path="timesheets/edit-operative-timesheet/:id" element={<DashDetail />} />
        </Route>

        <Route
          element={
            <RequireAuth
              allowedRoles={['Site Manager', 'Site Operative','Site Operative as Manager']}
            />
          }>
          <Route path="/detail" element={<Details />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={['Site Operative','Site Operative as Manager']} />}>
          <Route path="my-timesheets" element={<MyTimesheet />} />
          <Route
            path="my-timesheets/detail/:id"
            element={<TimesheetDetails editable={false} />}
          />
          <Route path="my-timesheets/edit/:id" element={<DashDetail />} />
        </Route>
      </Route>

      {/* Catch All */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AppRoutes;

import {sitesService} from '../../services/app/sites.service';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

export const getSites = createAsyncThunk(
  'sites/getSites',
  async (payload, {rejectWithValue}) => {
    const response = await sitesService.getAll(payload);

    if (response.status === 'Success') {
      return response;
    } else {
      return rejectWithValue(response.error);
    }
  },
);

export const deleteSite = createAsyncThunk(
  'sites/deleteSite',
  async (payload, thunkAPI) => {
    const response = await sitesService.deleteSite(payload);

    if (response.success) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response.error);
    }
  },
);

export const createSite = createAsyncThunk(
  'sites/createSite',
  async (payload, thunkAPI) => {
    const response = await sitesService.createSite(payload);

    if (response.success) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response.error);
    }
  },
);

export const updateSite = createAsyncThunk(
  'sites/updateSite',
  async (payload, {rejectWithValue}) => {
    const response = await sitesService.updateSite(payload);

    if (response.success) {
      return response;
    } else {
      return rejectWithValue(response.error);
    }
  },
);

export const markSites = createAsyncThunk(
  'sites/markSites',
  async (payload, thunkAPI) => {
    const response = await sitesService.markSites(
      payload,
    );
    if (response.success) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response.error);
    }
  },
);

export const getSingleSite = createAsyncThunk(
  'sites/getSingleSite',
  async (payload, thunkAPI) => {
    const response = await sitesService.getSingleSite(payload);

    if (response.success) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response.error);
    }
  },
);

export const createOperativeSiteLink = createAsyncThunk(
  'sites/createOperativeSiteLink',
  async (payload, thunkAPI) => {
    const response = await sitesService.createOperativeSiteLink(payload);

    if (response.success) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response.error);
    }
  },
);

export const deleteOperativeSiteLink = createAsyncThunk(
  'sites/deleteOperativeSiteLink',
  async (payload, thunkAPI) => {
    const response = await sitesService.deleteOperativeSiteLink(payload);

    if (response.success) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response.error);
    }
  },
);

export const updateOperativeSiteLink = createAsyncThunk(
  'sites/updateOperativeSiteLink',
  async (payload, {rejectWithValue}) => {
    const response = await sitesService.updateOperativeSiteLink(payload);

    if (response.success) {
      return response;
    } else {
      return rejectWithValue(response.error);
    }
  },
);

const sitesSlice = createSlice({
  name: 'sites',
  initialState: {
    sites: [],
    paging: {},
    siteDetails: {},
    loading: false,
    error: '',
    message: '',
  },
  reducers: {
    siteReset: (state) => {
      state.error = '';
      state.message = '';
      state.loading = false;
    },
  },
  extraReducers: {
    // Getting Sites
    [getSites.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [getSites.fulfilled]: (state, action) => {
      state.sites = action.payload.data;
      state.paging = action.payload.paging;
      state.loading = false;
      state.error = '';
    },
    [getSites.rejected]: (state, action) => {
      state.error = action?.payload;
      state.loading = false;
      state.message = '';
    },

    // Deleting Site
    [deleteSite.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [deleteSite.fulfilled]: (state, action) => {
      state.message = action.payload.message;
      state.loading = false;
      state.error = '';
    },
    [deleteSite.rejected]: (state, action) => {
      state.error = action?.payload;
      state.loading = false;
      state.message = '';
    },

    // Create Site
    [createSite.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [createSite.fulfilled]: (state, action) => {
      state.message = action?.payload?.message;
      state.loading = false;
      state.error = '';
    },
    [createSite.rejected]: (state, action) => {
      console.log(action);
      state.error = action?.payload;
      state.loading = false;
      state.message = '';
    },

    // Update Site
    [updateSite.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [updateSite.fulfilled]: (state, action) => {
      state.message = action?.payload?.message;
      state.loading = false;
      state.error = '';
    },
    [updateSite.rejected]: (state, action) => {
      state.error = action?.payload;
      state.loading = false;
      state.message = '';
    },

    // mark Sites
    [markSites.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [markSites.fulfilled]: (state, action) => {
      state.message = action?.payload?.message;
      state.loading = false;
      state.error = '';
    },
    [markSites.rejected]: (state, action) => {
      state.error = action?.payload;
      state.loading = false;
      state.message = '';
    },

    // Get Single Site
    [getSingleSite.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [getSingleSite.fulfilled]: (state, action) => {
      state.siteDetails = action.payload.data;
      state.loading = false;
      state.error = '';
    },
    [getSingleSite.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.message = '';
    },

    // Create Operative
    [createOperativeSiteLink.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [createOperativeSiteLink.fulfilled]: (state, action) => {
      state.message = action.payload.message;
      state.loading = false;
      state.error = '';
    },
    [createOperativeSiteLink.rejected]: (state, action) => {
      state.error = action?.payload;
      state.loading = false;
      state.message = '';
    },

    // Delete Operative
    [deleteOperativeSiteLink.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [deleteOperativeSiteLink.fulfilled]: (state, action) => {
      state.message = action.payload.message;
      state.loading = false;
      state.error = '';
    },
    [deleteOperativeSiteLink.rejected]: (state, action) => {
      state.error = action?.payload;
      state.loading = false;
      state.message = '';
    },

    // Update Operative
    [updateOperativeSiteLink.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [updateOperativeSiteLink.fulfilled]: (state, action) => {
      state.message = action.payload.message;
      state.loading = false;
      state.error = '';
    },
    [updateOperativeSiteLink.rejected]: (state, action) => {
      state.error = action?.payload;
      state.loading = false;
      state.message = '';
    },
  },
});

export const sitesActions = sitesSlice.actions;
export default sitesSlice.reducer;

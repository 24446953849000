import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {MdOutlineClose} from 'react-icons/md';
import {
  Modal,
  IconButton,
  TextField,
  Box,
  Typography,
} from '@mui/material';
import DatePicker from 'react-datepicker';
import ReactSelect from 'react-select';
import {toast} from 'react-toastify';
import { addDays, startOfWeek, endOfWeek } from 'date-fns';
import {useForm, Controller} from 'react-hook-form';

//import AddModalJobRole from './AddModalJobRate';
import {getOperativeSiteLink, getAll} from '../../../data/reducers/app/dropdown.reducer';

import '../../../components/addModal.css';
import ProButton from '../../../components/common-comp/ProButton';

function AddTimesheet({
  open,
  handleClose,
  submitHandle,
  selectedRecord,
}) {
  const dispatch = useDispatch();

  const [dropVal, setDropVal] = useState({
    operativeSiteLinkId: '',
    operativeId: '',
  });
  const [dateVal, setDateVal] = useState(null)
  const [firstDay, setfirstDay] = useState(null)
  const [lastDay, setlastDay] = useState(null)

  const {operativeSiteLink} = useSelector(
    (state) => state.dropdown,
  );
  const {dropdown} = useSelector((state) => state.dropdown);
  const {lstActiveOperativesList} = dropdown;

  const role = JSON.parse(localStorage.getItem('user'))?.user?.role;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 400,
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2.5,
    borderRadius: 1,
  };

  const { handleSubmit, control, formState, reset, setValue} =
    useForm({shouldUnregister: true});

  const handleClick = (submitData) => {
    if (selectedRecord) {
      submitHandle(submitData, true);
    } else {
      var startDate = new Date(firstDay);
      startDate = new Date(
        Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())
      );
      
      //if(Date.parse(lastDay) >)
      var endDate = new Date(lastDay);
      endDate = new Date(
        Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())
      );

      const data = {
        startDate: startDate,
        endDate: endDate,
        operativeSiteLinkId : dropVal?.operativeSiteLinkId?.operativeSiteLinkId
      }
      if(data?.operativeSiteLinkId){
        console.log(data?.operativeSiteLinkId)
        submitHandle(data, false);
      }else{
        toast.error('Fill all fields!')
      }
    }
  };

  const closeModal = () => {
    setDropVal({
      operativeSiteLinkId: '',
      operativeId: '',
    });
    handleClose();
  };

  const customStyles = {
    container: (provided) => ({
      ...provided,
      fontSize: '1.3rem',
      marginTop: '.6rem',
    }),
    option: (provided, {isFocused}) => ({
      ...provided,
      fontSize: '1.3rem',
      backgroundColor: isFocused ? '#329BEF' : 'white',
      color: isFocused ? 'white' : 'black',
      zIndex: 400,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 900,
    }),
    control: (styles) => ({...styles, padding: '0.3rem'}),
  };

  const handleInitialRender = () =>{
    if (selectedRecord) {
      for (const [key, value] of Object.entries(selectedRecord)) {
        setValue(key, value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
      setDropVal({
        ...dropVal,
        jobRole: operativeSiteLink?.filter(
          (option) => option.operativeSiteLinkId == selectedRecord?.operativeSiteLinkId,
        )[0],
      });
    }else {
      reset();
      setDropVal({
        ...dropVal,
        operativeSiteLinkId: '',
        operativeId:'',
      });
      setfirstDay(startOfWeek(new Date(), {weekStartsOn: 6}));
      setlastDay(endOfWeek(new Date(), {weekStartsOn: 6}));
    }
    {role?.includes('Operative') && dispatch(getOperativeSiteLink())}
    {role === 'Admin' && dispatch(getAll())}
  }

  const handleFirstAndLastDate = () =>{
    setfirstDay(startOfWeek(dateVal, {weekStartsOn: 6}));
    setlastDay(endOfWeek(dateVal, {weekStartsOn: 6}));
  }

  useEffect(() => {
    if(open) handleInitialRender()
  }, [open]);


  // useEffect(() => {
  //   reset();
  //   setDropVal({
  //     ...dropVal,
  //     jobRole: '',
  //   });
  //   {role === 'Site Operative' && dispatch(getOperativeSiteLink())}
  //   {role === 'Admin' && dispatch(getAll())}
  // }, []);

  useEffect(()=>{
    handleFirstAndLastDate();
  },[dateVal]);

  useEffect(()=>{
    if(dropVal?.operativeId){
      {role==='Admin' && dispatch(getOperativeSiteLink(`Values/get-operative-sites?operativeId=${dropVal?.operativeId?.id}`))}
    }
  },[dropVal?.operativeId]);

  return (
    <>
      <Modal open={open} onClose={closeModal}>
        <Box sx={style}>
          <Box display="flex" justifyContent="space-between" mb={2}>
            {/* {!selectedRecord ? (
              <Typography variant="h3">Create Timesheet</Typography>
            ) : (
              <Typography variant="h3">Edit Timesheet</Typography>
            )} */}
            <IconButton onClick={closeModal} sx={{marginLeft: 'auto'}} >
              <MdOutlineClose size="2rem" />
            </IconButton>
          </Box>

          <Box mt={3} mb={2}>
            <form onSubmit={handleSubmit(handleClick)} className="add-form">
              <Box className="rate-card-modal" >
                <Typography variant="h5">Select a week</Typography> 
                <Controller
                  name="date"
                  control={control}
                  defaultValue=""
                  //rules={{required: true}}
                  error={formState.errors?.tag ? true : false}
                  render={({field}) => (
                    <DatePicker
                      {...field}
                      name="date"
                      //selectsRange={true}
                      selected={dateVal}
                      startDate={firstDay}
                      endDate={lastDay}
                      onChange={(update) => {
                        setDateVal(update);
                      }}
                      placeholderText="Select week"
                      customInput={
                        <TextField
                          sx={{width: '20rem'}}
                          placeholder="Select week"
                        />
                      }
                      calendarClassName='datepicker-cal'
                      maxDate={addDays(new Date(), 1)}
                      showDisabledMonthNavigation
                      inline
                    />
                  )}
                />
              </Box>
              {role==='Admin' && (<Box>
                <Typography variant="h5">Select Operative</Typography>
                <Controller
                  name="operativeId"
                  control={control}
                  defaultValue=""
                  rules={{required: true}}
                  error={formState.errors?.tag ? true : false}
                  render={({field}) => (
                    <ReactSelect
                      name="operativeId"
                      field={field}
                      required
                      styles={customStyles}
                      options={lstActiveOperativesList}
                      placeholder="Select Operative"
                      getOptionLabel={(option) => { 
                        return <div>{`${option?.operativeName} , ${option?.email}`}</div>
                      }}
                      value={dropVal?.operativeId || ''}
                      defaultValue=""
                      onChange={(e) => {
                        field.onChange(e?.id);
                        setDropVal({...dropVal, operativeId: e});
                      }}
                    />
                  )}
                />
                <span className="err-form">
                  {formState.errors?.operativeId ? 
                    'Operative is required' :
                    ''
                  }
                </span>
              </Box>)}
              <Box>
                <Typography variant="h5">Select a Site</Typography>
                <Controller
                  name="operativeSiteLinkId"
                  control={control}
                  defaultValue=""
                  rules={{required: true}}
                  error={formState.errors?.tag ? true : false}
                  render={({field}) => (
                    <ReactSelect
                      name="operativeSiteLinkId"
                      field={field}
                      required
                      styles={customStyles}
                      options={operativeSiteLink}
                      placeholder="Select Site"
                      getOptionLabel={(option) => option?.siteNameCode}
                      value={dropVal?.operativeSiteLinkId || ''}
                      defaultValue=""
                      onChange={(e) => {
                        field.onChange(e?.operativeSiteLinkId);
                        setDropVal({...dropVal, operativeSiteLinkId: e});
                      }}
                    />
                  )}
                />
                <span className="err-form">
                  {formState.errors?.operativeSiteLinkId ? 
                    'Site is required' :
                    ''
                  }
                </span>
              </Box>
              <ProButton 
              text="Create Timesheet"
              color="#cb272f"
              handleClick={handleSubmit(handleClick)}
              variant="contained"
              mx0
              />
            </form>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default AddTimesheet;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/apiHandler/axiosInstance";

export const getAll = createAsyncThunk(
    'dropdown/getAll',
    async (payload, {rejectWithValue}) => {
      const response = await axiosInstance.get("Values/get-dropdowns");
      if (response.data.success) {
        return response.data.data;
      } else {
        return rejectWithValue('error message');
      }
    },
);
export const getOperativeSiteLink = createAsyncThunk(
    'dropdown/getOperativeSiteLink',
    async (payload, {rejectWithValue}) => {
      let response;
      if(payload){
        response = await axiosInstance.get(payload);
      }else{
        response = await axiosInstance.get("Values/get-operative-sites");
      }
      
      if (response.data.success) {
        return response.data.data;
      } else {
        return rejectWithValue('error message');
      }
    },
);

const dropdownSlice = createSlice({
    name: 'dropdown',
    initialState: {
        dropdown: [],
        operativeSiteLink: []
    },
    reducers: {},
    extraReducers: {
      [getAll.fulfilled]: (state, action) => {
        state.dropdown = action.payload;
      },
      [getOperativeSiteLink.fulfilled]: (state, action) => {
        state.operativeSiteLink = action.payload;
      },
    },
  });
  
  export default dropdownSlice.reducer;
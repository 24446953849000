import React from 'react';


function EditPage() {

  //return <CommonComponent customContent={customEdit} />;
  return <></>
}

export default EditPage;

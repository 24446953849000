import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {MdOutlineEdit, MdOutlineClose} from 'react-icons/md';
import {toast} from 'react-toastify';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import ReactSelect from 'react-select';
import DatePicker from 'react-datepicker';
import { addDays, startOfWeek, endOfWeek } from 'date-fns';

import {tableData} from '../../../utils/tableData';
import Navigation from '../../../components/navigation/Navigation';
import StatusChip from './components/StatusChip';

import {details} from '../../../utils/details';
import {
  //getAllTimesheet,
  getAllRecords,
  createTimesheet,
  timesheetsActions
} from '../../../data/reducers/app/timesheets.reducer';
import {getAll, } from '../../../data/reducers/app/dropdown.reducer';
import {getDashboard} from '../../../data/reducers/app/app.reducer';

import '../../../components/dataTable/datatable.css';
import '../../../components/common-comp/CommonComp.css';
import DataTable from '../../../components/dataTable/DataTable';
import ProButton from '../../../components/common-comp/ProButton';
import { convertToPounds } from '../../../utils/currency';
import AddTimesheet from './AddTimesheet';

export default function MyTimesheet() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [responseData, setResponseData] = useState(null);
  const [addPopup, setAddPopup] = useState(false);
  const [clearable, setClearable] = useState(false);
  const [dateVal, setDateVal] = useState('')
  const [firstDay, setfirstDay] = useState('')
  const [lastDay, setlastDay] = useState('')
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [filters, setFilters] = useState({
    PageNumber: 1,
    SortDir: 0,
    SortField: '',
    PageSize: 10,
    siteId: '',
    jobRoleId: '',
    approvalStatus: '',
    operativeName: '',
  });

  const { timesheets, error, message, paging} =
    useSelector((state) => state.timesheets);
  const {lstAllSites} = useSelector(
    (state) => state.dropdown,
  ).dropdown;
  const {dashboard} = useSelector((_) => _.app);

  let user = JSON.parse(localStorage.getItem('user'));
  const role = user?.user?.role;

  const lstApprovalStatus = [
    {value: 'Admin Approved'},
    {value: 'Admin Disapproved'},
    {value: 'Manager Approved'},
    {value: 'Manager Disapproved'},
    {value: 'Not Submitted'},
    {value: 'Approval Pending'},
  ];

  useEffect(() => {
    if (error) {
      toast.error(error);
    } else if (message) {
      toast.success(message);
    }
  }, [error, message]);

  useEffect(() => {
    //<-- every time the component renders, it calls handleFetch() to fetch all the records.
    handleFetch();
  }, [filters]);

  useEffect(() => {
    setResponseData(timesheets ? timesheets : []);
  }, [timesheets]);

  useEffect(()=>{
    handleFetch()
    if(startDate!=='' || endDate!=='')setClearable(true)
    
  },[startDate,endDate]);

  useEffect(()=>{
    
    if(dateVal !== '' && dateVal !== null){
      setfirstDay(startOfWeek(dateVal, {weekStartsOn: 6}));
      setlastDay(endOfWeek(dateVal, {weekStartsOn: 6}));
    }else{
      setfirstDay('')
      setlastDay('')
    }
    
  },[dateVal]);

  useEffect(()=>{
    
    if(firstDay!== '' || lastDay!==''){
      console.log('first', firstDay)
      var start = new Date(firstDay);
      setStartDate(yearMonthDate(start));
    
      var end = new Date(lastDay); 
      setEndDate(yearMonthDate(end));
    }else{
      setStartDate('');
      setEndDate('');
    }
    
  },[firstDay, lastDay]);

  const yearMonthDate = (dateVal) => {
    var date = new Date(dateVal);
    var print =
    
      date.getFullYear() +
      '-' +
      (date.getMonth() > 8
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)) +
      '-' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) ;

    return `${print}`;
  };

  const handleFetch = async () => {
    dispatch(timesheetsActions.timesheetsReset());
    setResponseData(null);
    setClearable(false);
    
    await dispatch(
      getAllRecords({
        endpoint: `Timesheets/get-all?SortField=${
          filters?.SortField
        }&SortDir=${filters?.SortDir}&PageNumber=${
          filters?.PageNumber
        }&PageSize=${filters?.PageSize}&OperativeName=${
          filters?.operativeName
        }&StartDate=${startDate}&EndDate=${
          endDate
        }&JobRoleId=${
          filters?.jobRoleId ? filters?.jobRoleId?.id : ''
        }&SiteId=${
          filters?.siteId ? filters?.siteId?.id : ''
        }&ApprovalStatus=${filters?.approvalStatus ? filters?.approvalStatus.value : ''}`,
      }),
    );
    await dispatch(getAll());
    await dispatch(getDashboard());
  };

  const handleEdit = (tableMeta) => {
    // <--- For editing a record
    console.log('edit');
    let temp = responseData[tableMeta?.rowIndex];
    if(role?.includes('Operative')){
      if (
        temp.approvalStatus === 'Admin Disapproved' ||
        temp.approvalStatus === 'Manager Disapproved' ||
        temp.approvalStatus === 'Not Submitted'
      ) {
        navigate(`edit/${responseData[tableMeta.rowIndex]?.id}`);
      }
    }else {
      navigate('edit', {
        state: {content: details.timesheets, operativeData: temp},
      });
    }
  };

  const handleDate = (dateVal) => {
    var date = new Date(dateVal);
    var print =
      (date.getMonth() > 8
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)) +
      '-' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
      '-' +
      date.getFullYear();
    return `${print}`;
  };

  const createTimesheetFn = async(data, updated) =>{
    if(!updated){
      dispatch(createTimesheet({
        endpoint:`Timesheets/create-timesheet`,
        data
      }))?.then((response)=>{
        if(response?.type === 'timesheets/createTimesheet/fulfilled'){
          const {payload} = response;
          dispatch(timesheetsActions.timesheetsReset());
          setAddPopup(false);
          navigate(`edit/${payload?.data?.id}`);
        }
      })
    }
  }

  const tableDataCol =
    role?.includes('Operative')
      ? tableData.timesheetsOperative
      : tableData.timesheets;
  const tableCols = tableDataCol?.columns.map((column) => {
    // <--- Creating table columns dynamically
    if (column?.link) {
      return {
        name: column.name,
        label: column.label,
        options: {
          customBodyRender: (value, tableMeta) => {
            return (
              <div
                onClick={() => {
                  let temp = responseData[tableMeta?.rowIndex];
                  navigate(`detail/${temp?.id}`, {
                    state: {
                      content: details.timesheets,
                      operativeData: temp,
                    },
                  });
                }}
                style={{cursor: 'pointer', textDecoration: 'underline'}}>
                {value}
              </div>
            );
          },
        },
      };
    } else if (column?.status) {
      return {
        name: column.name,
        label: column.label,
        options: {
          customBodyRender: (value) => {
            return <StatusChip text={value} />;
          },
        },
      };
    }else if (column?.week) {
      return {
        name: column.name,
        label: column.label,
        options: {
          customBodyRender: (value, tableMeta) => {
            return <div>{`${handleDate(value)} - ${handleDate(responseData[tableMeta.rowIndex]?.endDate)}`}</div>;
          },
        },
      };
    } else {
      return {
        name: column.name,
        label: column.label,
      };
    }
  });

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: '100%',
      fontSize: '1.3rem',
      marginTop: '.6rem',
    }),
    option: (provided, {isFocused}) => ({
      ...provided,
      fontSize: '1.3rem',
      backgroundColor: isFocused ? '#329BEF' : 'white',
      color: isFocused ? 'white' : 'black',
      zIndex: 400,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 900,
    }),
    control: (styles) => ({...styles, padding: '0.2rem'}),
  };

  const buttonStyle = (index) => {
    let status = responseData[index]?.approvalStatus;
    if(role?.includes('Operative')){
      return {
        border: 'none',
        marginRight: '.5rem',
        cursor:
          status === 'Admin Disapproved' ||
          status === 'Manager Disapproved' ||
          status === 'Not Submitted'
            ? 'pointer'
            : 'unset',
        background: 'transparent',
        color:
          status === 'Admin Disapproved' ||
          status === 'Manager Disapproved' ||
          status === 'Not Submitted'
            ? '#000'
            : 'gray',
      };
    }else{
      return {
        border: 'none',
        marginRight: '.5rem',
        cursor: 'pointer',
        background: 'transparent',
        color:'#000',
      };
    }
    
  };

  {role === 'Admin' && (
    tableCols?.push({
      name: 'totalCost',
      label: 'Cost',
      options:{
        customBodyRender: (value) => {
          return <div>{convertToPounds(value)}</div>
        }
      }
    })
  )}

  {
    role !== 'Site Manager' &&
      tableCols?.push({
        name: 'Actions',
        options: {
          customBodyRender: (_, tableMeta) => {
            return (
              <span
                style={{
                  padding: '.5rem',
                  width: 'max-content',
                }}>
                <button
                  onClick={() => handleEdit(tableMeta)}
                  style={buttonStyle(tableMeta.rowIndex)}>
                  <MdOutlineEdit size="1.8rem" />
                </button>
              </span>
            );
          },
        },
      });
  }

  const navigation = [
    {
      name: ' My Timesheets ',
    },
  ]

  const render = () => {
    return (
      <div className="page-container">
        <Navigation path={navigation} />
        <Box
        display='flex' 
        flexDirection='column' 
        gap='2rem'>
            <Box className="section-top">
                <Typography variant="h3" fontWeight='bold'>Time Entries</Typography>
            </Box>
            <Grid container spacing={4} justifyContent="space-between">
              <Grid item xl={5} sm={6} xs={12}>
                <Box className="weekly-cost">
                  <Typography
                    variant="h3"
                    textAlign="center"
                    fontWeight="bold"
                    mb={1}>{`${dashboard?.operativeTotalHoursWorked?.thisWeekWorkedHours || '0 hrs 0 min'}`}</Typography>
                    <Typography variant="h6" textAlign="center">
                    This Week Work
                  </Typography>
                </Box>
              </Grid>
              <Grid item xl={5} sm={6} xs={12}>
                <Box className="weekly-cost">
                  <Typography
                    variant="h3"
                    textAlign="center"
                    fontWeight="bold"
                    mb={1}>{`${dashboard?.operativeTotalHoursWorked?.totalWorkedHours || '0 hrs 0 min'}`}</Typography>
                    <Typography variant="h6" textAlign="center">
                    Total Approved Work Hours
                  </Typography>
                </Box>
              </Grid>
          </Grid>
          <Divider />
          <Box className='cta'>
            <ProButton 
            text="Create Timesheet"
            color="#cb272f"
            handleClick={() => setAddPopup(true)}
            variant="contained"
            mx0
            />
          </Box>
        </Box>
        <Box 
        display='flex' 
        flexDirection='column' 
        gap='2rem' 
        sx={{background:'var(--gray-bg)', p:'1rem'}}>
            <Box className="section-top">
                <Typography variant="h3" fontWeight='bold'>My Timesheets</Typography>
            </Box>
            
            <Grid
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            alignContent="center">

            {role !== "Site Manager" && (
                <Grid item md={role === 'Admin' ? 2 : 3} sm={6} xs={12}>
                <Typography variant="h6">Site</Typography>
                <ReactSelect
                name="site"
                required
                styles={customStyles}
                options={lstAllSites}
                getOptionLabel={(option) => option?.siteName}
                value={filters?.siteId || ''}
                defaultValue=""
                isClearable
                placeholder="Select Site"
                onChange={(e) => {
                    setFilters({...filters, siteId: e});
                }}
                />
                </Grid>
            )} 
            
            <Grid item md={role === 'Admin' ? 2 : 3} sm={6} xs={12}>
                <Typography variant="h6">Approval Status</Typography>
                <ReactSelect
                name="status"
                required
                styles={customStyles}
                options={lstApprovalStatus}
                getOptionLabel={(option) => option?.value}
                value={filters?.approvalStatus || ''}
                defaultValue=""
                isClearable
                placeholder="Select Status"
                onChange={(e) => {
                    setFilters({...filters, approvalStatus: e});
                }}
                />
            </Grid>
            <Grid item md={role === 'Admin' ? 2 : 3} sm={6} xs={12}>
              <Typography variant="h6">Week</Typography>
              <DatePicker
                //selectsRange={true}
                selected={dateVal}
                startDate={firstDay}
                endDate={lastDay}
                onChange={(update) => {
                  setDateVal(update);
                }}
                placeholderText="Select week"
                className='dateInput'
                customInput={
                  <TextField
                    sx={{width:'100%', bgcolor:'#ffffff'}}
                    value={`${startDate} - ${endDate}`}
                    placeholder="Select week"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          {clearable && (
                            <IconButton
                              onClick={() => {
                                setDateVal('');
                                setStartDate('');
                                setEndDate('');
                              }}>
                              <MdOutlineClose size="2rem" />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                }
                //calendarClassName='datepicker-cal'
                maxDate={addDays(new Date(), 1)}
                showDisabledMonthNavigation
              />
            </Grid>
            </Grid>
            <section className="section-table">
              {role?.includes('Operative') && (
                <Box className="section-table">
                <DataTable
                    newCols={tableCols}
                    newData={responseData ? responseData : []}
                    pagination={true}
                    filter={filters}
                    setFilter={setFilters}
                    totalCount={paging?.totalItemsCount}
                />
                </Box>
              )}
            </section>
            
        </Box>
      </div>
    );
  };

  return (
    <>
      <div className="container">
        {render()}
        <AddTimesheet
          open={addPopup}
          handleClose={() => setAddPopup(false)}
          submitHandle={createTimesheetFn}
          //selectedRecord={editRow}
        />
      </div>
    </>
  );
}

import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useLocation, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Box, Grid, TextField, Typography} from '@mui/material';
import DatePicker from 'react-datepicker';
import {useDispatch, useSelector} from 'react-redux';
import ReactSelect from 'react-select';

import {
  getTimesheet,
  updateTimesheet,
  submitTimesheet,
  timesheetsActions,
} from '../../../data/reducers/app/timesheets.reducer';
import {edit} from '../../../utils/details';

import ConfirmationDialog from '../../../components/ConfirmationDialog';
import DataTable from '../../../components/dataTable/DataTable';
import ProButton from '../../../components/common-comp/ProButton';
import Navigation from '../../../components/navigation/Navigation';
import StatusChip from './components/StatusChip';
import {removeSeconds} from '../../../utils/time';
import { convertToPounds } from '../../../utils/currency';

import '../../../components/common-comp/CommonComp.css';
import 'react-datepicker/dist/react-datepicker.css';

function TimesheetDetails({editable}) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {id} = useParams();

  const customContent = location.state.content;
  const operativeData = location.state.operativeData;

  const [dateRange, setDateRange] = useState([
    operativeData ? new Date(operativeData?.startDate) : '',
    operativeData ? new Date(operativeData?.endDate) : '',
  ]);
  const [updatedData, setUpdatedData] = useState([]);
  const [responseData, setResponseData] = useState(null);
  const [tempData, setTempData] = useState(null);
  const [dropVal, setDropVal] = useState([]);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [action, setAction] = useState(null);
  const [bankdropVal, setBankDropVal] = useState([]);
  const [startDate, endDate] = dateRange;

  const {timesheetDetail, error, message} = useSelector(
    (state) => state.timesheets,
  );

  const role = JSON.parse(localStorage.getItem('user'))?.user?.role;

  let nightShiftOptions = [
    {label: 'Yes', value: true},
    {label: 'No', value: false},
  ];

  useEffect(() => {
    setConfirmPopup(false);
    if (error) {
      toast.error(error);
    } else if (message) {
      toast.success(message);
    }
  }, [error, message]);

  useEffect(() => {
    if(timesheetDetail){
      setConfirmPopup(false);
      setUpdatedData([]);
      setResponseData(timesheetDetail?.timeSheetList);
      let temp = [];
      let bank = [];
      timesheetDetail?.timeSheetList?.map((row, index) => {
        temp[index] = row?.nightShift?.toString();
        bank[index] = row?.bankHoliday?.toString();
      });
      setDropVal([...temp]);
      setBankDropVal([...bank]);
      
    }
  }, [timesheetDetail]);

  useEffect(()=>{
    if(responseData){
      setTempData(responseData);
    }
  },[responseData]);


  useEffect(() => {
    //<-- every time the component renders, it calls handleFetch() to fetch all the records.
    //handleFetch();
  }, [operativeData]);

  useEffect(()=>{
    handleFetch();
  },[id]);

  const handleFetch = async () => {
    dispatch(timesheetsActions.timesheetsReset());
    setConfirmPopup(false);
    setAction(null);
    dispatch(
      getTimesheet({
        endpoint: `Timesheets/get-timesheet/${id}`,
      }),
    );
  };

  const handleDate = (dateVal) => {
    var date = new Date(dateVal);
    var print =
    (date.getMonth() > 8
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)) +
      '-' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate())+
      '-' +
      date.getFullYear();
    return `${print}`;
  };

  const handleEdit = () => {
    navigate('/edit', {state: {customEdit: edit?.timesheets}});
  };

  const handleSubmit = () => {
    addRecord(updatedData);
  };

  const validateHhMm = (inputField) =>{
    let hrs=null; let mins=null;
    if(inputField){
      var sHours = inputField.split(':')[0];
      var sMinutes = inputField.split(':')[1];
      
      if(sHours == '' || isNaN(sHours) || (parseInt(sHours) > 23 )) {
        console.log('inside validate if hours')
        hrs = false;
      }else{
        hrs = true;
      }

      if(sMinutes == '' || isNaN(sMinutes) || parseInt(sMinutes) > 59) {
        console.log('inside validate if mins')
        mins = false;
      }else{
        mins = true
      }
    }
    
    console.log(`hrs: ${hrs} , mins: ${mins}`);
    
    if(hrs && mins){
      return true;
    }else{
      return false;
    }
  }

  const addRecord = async (data) => {
    if (editable) {
      if (updatedData?.length > 0) {
        let invalidTime;
        let checkStartTime;
        let checkBreak;
        let checkFinishTime;

        data?.map((obj) => {
          if(!obj?.nightShift){
            if (
              Date.parse('1/1/1999 ' + obj?.startTime) >
              Date.parse('1/1/1999 ' + obj?.finishTime)
            ) {
              invalidTime=true;
            }
          }
          checkBreak = validateHhMm(obj?.break)
          checkStartTime = validateHhMm(obj?.startTime);
          checkFinishTime = validateHhMm(obj?.finishTime);
        });
        
        if(invalidTime) {
          toast.error('Start time cannot be greater than Finish time');
        }else if(!checkStartTime || !checkFinishTime || !checkBreak){
          toast.error('Please enter proper time values.');
        }else {
          const dataToSend = {
            timeSheetList: data,
          };

          let end = `/TimeSheets/update-timesheet`;
          dispatch(
            updateTimesheet({
              endpoint: end,
              data: dataToSend,
            }),
          )?.then((res)=>{
            if(res?.type === 'timesheets/updateTimesheet/fulfilled'){
              dispatch(timesheetsActions.timesheetsReset());
              handleFetch();
              navigate(`/timesheets/detail/${id}`, {
              state: {content: customContent, },
              })
            }
          });
        }
      }else{
        toast.error('Please fill the timesheet before saving')
      }
    }
  };

  const updateRow = (changes, field, index) => {
    let temp = JSON.parse(JSON.stringify(tempData));
    temp[index][field] = changes;
    setTempData([...temp]);

    let dataIndex = updatedData?.findIndex((d) => {
      d.id === responseData[index].id;
    });

    if (dataIndex === -1) {
      if(tempData[index][field] !== changes) {
        let checkIndex = null;
        let existingIndex = null;
        updatedData?.map((obj, i) => {
          if (obj.date == temp[index].date) {
            checkIndex = true;
            existingIndex = i;
          }
        });
        if(!checkIndex) {
          setUpdatedData([...updatedData, temp[index]]);
        } else {
          updatedData[existingIndex] = temp[index];
        }
      }
    }else {
      updatedData[dataIndex] = temp[index];
    }
    setTempData([...temp]);
  };

  const handleDisapproval = async () => {
    // let id = [];
    // id = responseData.map((record) => {
    //   return record.id;
    // });
    dispatch(
      submitTimesheet({
        endpoint: `/TimeSheets/submit-timesheet/${id}?action=disapprove`,
      }),
    )?.then((res)=>{
      if(res?.type === 'timesheets/submitTimesheet/fulfilled'){
        dispatch(timesheetsActions.timesheetsReset());
        //handleFetch();  
        navigate('/timesheets');
      }
    })
  };

  const handleApproval = async () => {
    dispatch(
      submitTimesheet({
        endpoint: `/TimeSheets/submit-timesheet/${id}?action=approve`,
      }),
    )?.then((res)=>{
      if(res?.type === 'timesheets/submitTimesheet/fulfilled'){
        dispatch(timesheetsActions.timesheetsReset());
        //handleFetch();  
        navigate('/timesheets');
      }
    })
  };

  const customStyles = {
    container: (provided) => ({
      ...provided,
      minWidth: 90,
      fontSize: '1.3rem',
    }),
    option: (provided, {isFocused}) => ({
      ...provided,
      fontSize: '1.3rem',
      backgroundColor: isFocused ? '#329BEF' : 'white',
      color: isFocused ? 'white' : 'black',
      zIndex: 400,
    }),
  };

  const checkDate = (index) =>{
    if(Date.parse(new Date(tempData[index]?.date)) > Date.parse(new Date())){
      return true
    }else{
      return false
    }
  }

  const tableCols = [
    {
      name: 'date',
      label: 'Date',
      options: {
        customBodyRender: (value, tableMeta) => {
          return <div>{`${handleDate(value)}, ${tempData[tableMeta.rowIndex].day}`}</div>;
        },
      },
    },
    {
      name: 'startTime',
      label: 'Start Time',
      options: {
        customBodyRender: (value, tableMeta) => {
          console.log(value)
          if (editable) {
            return (
              <TextField
                onChange={(e) => {
                  console.log('inside')
                  let temp = e.target.value;
                  let hours = temp.split(':')[0];
                  let mins = temp.split(':')[1];
                  if(parseInt(hours) < 10) { temp = `0${parseInt(hours)}:${mins}` }
                  updateRow(
                    temp,
                    'startTime',
                    tableMeta.rowIndex,
                  );
                }}
                disabled = {checkDate(tableMeta.rowIndex) ? true : false}
                sx={{minWidth:'7rem', maxWidth:'10rem'}}
                defaultValue={value}
              />
            );
          } else {
            return <div>{value}</div>;
          }
        },
      },
    },
    {
      name: 'finishTime',
      label: 'Finish Time',
      options: {
        customBodyRender: (value, tableMeta) => {
          if (editable) {
            return (
              <TextField
                onChange={(e) => {
                  let temp = e.target.value;
                  let hours = temp.split(':')[0];
                  let mins = temp.split(':')[1];
                  if(parseInt(hours) < 10) { temp = `0${parseInt(hours)}:${mins}` }
                  updateRow(
                    temp,
                    'finishTime',
                    tableMeta.rowIndex,
                  );
                }}
                disabled = {checkDate(tableMeta.rowIndex) ? true : false}
                sx={{minWidth:'7rem', maxWidth:'10rem'}}
                defaultValue={value}
              />
            );
          } else {
            return <div>{value}</div>;
          }
        },
      },
    },
    {
      name: 'break',
      label: 'Breaks',
      options: {
        customBodyRender: (value, tableMeta) => {
          if (editable) {
            return (
              <TextField
                onChange={(e) => {
                  updateRow(e.target.value, 'break', tableMeta.rowIndex);
                }}
                defaultValue={removeSeconds(value)}
                sx={{minWidth:'7rem', maxWidth:'10rem'}}
                disabled = {tempData[tableMeta.rowIndex]?.startTime === '00:00' || tempData[tableMeta.rowIndex]?.finishTime === '00:00'? true : false}
              />
            );
          } else {
            return <div>{removeSeconds(value)}</div>;
          }
        },
      },
    },
    {
      name: 'nightShift',
      label: 'Night Shift',
      options: {
        customBodyRender: (_, tableMeta) => {
          return (
            <ReactSelect
              name="nightShift"
              styles={customStyles}
              options={nightShiftOptions}
              value={nightShiftOptions?.filter(
                (val) =>
                  val.value === tempData[tableMeta.rowIndex]?.nightShift,
              )}
              hideSelectedOptions={false}
              isDisabled={editable !== true || checkDate(tableMeta.rowIndex)}
              getOptionLabel={(option) => option?.label}
              onChange={(e) => {
                let temp = [...dropVal];
                temp[tableMeta.rowIndex] = e.value;
                setDropVal([...temp]);
                updateRow(e.value, 'nightShift', tableMeta.rowIndex);
              }}
              menuPortalTarget={document.body}
            />
          );
        },
      },
    },
    {
      name: 'bankHoliday',
      label: 'Bank Holiday',
      options: {
        customBodyRender: (_, tableMeta) => {
          return (
            <ReactSelect
              name="bankHoliday"
              styles={customStyles}
              options={nightShiftOptions}
              value={nightShiftOptions?.filter(
                (val) =>
                  val.value == tempData[tableMeta.rowIndex]?.bankHoliday,
              )}
              hideSelectedOptions={false}
              isDisabled={editable !== true || checkDate(tableMeta.rowIndex)}
              getOptionLabel={(option) => option?.label}
              onChange={(e) => {
                let bank = [...bankdropVal];
                bank[tableMeta.rowIndex] = e.value;
                setBankDropVal([...bank]);
                updateRow(e.value, 'bankHoliday', tableMeta.rowIndex);
              }}
              menuPortalTarget={document.body}
            />
          );
        },
      },
    },
    {
      name: 'totalHoursWorked',
      label: 'Hours Worked',
      options: {
        customBodyRender: (value) =>{
          return <div>{removeSeconds(value)}</div>;
        }
      }
    },
    
  ];

  {role === 'Admin' && (
    tableCols?.push({
      name: 'bonus',
      label: 'Bonus',
      options:{
        customBodyRender: (value, tableMeta) => {
          if (editable) {
            return (
              <TextField
                onChange={(e) => {
                  updateRow(e.target.value, 'bonus', tableMeta.rowIndex);
                }}
                defaultValue={value}
                sx={{minWidth:'5rem', maxWidth:'10rem'}}
              />
            );
          } else {
            return <div>{convertToPounds(value)}</div>;
          }
        }
      }
    })
  )}
  {role === 'Admin' && (
    tableCols?.push({
      name: 'cost',
      label: 'Cost',
      options:{
        customBodyRender: (value) => {
          return <div>{convertToPounds(value)}</div>
        }
      }
    })
  )}

  const navigation = [
    {
      name: ' Timesheets /',
      path: '/timesheets'
    },
    {
      name: ' details ',
    },
  ]

  return (
    <>
      <div className="page-container">
        <Navigation path={navigation} />
        <Box className="section-top">
          <h1>{`${timesheetDetail?.operativeName || ''} - Timesheet`}</h1>
          <StatusChip text={timesheetDetail?.approvalStatus} />

          {customContent?.topRightButton && (
            <ProButton
              text="Edit"
              color="#cb272f"
              handleClick={handleEdit}
              variant="contained"
              mx0
            />
          )}
        </Box>

        <Box className="section-info" padding={2} borderRadius={1}>
          <Grid container columnSpacing={12} rowSpacing={1} mt={2}>
            <Grid item  sm={6}>
              <Grid container columnSpacing={2}>
                <Grid item md={6}>
                  <Typography variant="h6" fontWeight="bold">
                    Site Manager Name
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography
                    variant="h6"
                    //fontWeight="light"
                    textAlign="right">
                    {timesheetDetail?.siteManagerName || '_'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item  sm={6}>
              <Grid container columnSpacing={2}>
                <Grid item md={6}>
                  <Typography variant="h6" fontWeight="bold">
                    Site Code
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography
                    variant="h6"
                    //fontWeight="light"
                    textAlign="right">
                    {timesheetDetail?.siteCode}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item  sm={6}>
              <Grid container columnSpacing={2}>
                <Grid item md={6}>
                  <Typography variant="h6" fontWeight="bold">
                    Site Name
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography
                    variant="h6"
                    //fontWeight="light"
                    textAlign="right">
                    {timesheetDetail?.siteName}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item  sm={6}>
              <Grid container columnSpacing={2}>
                <Grid item md={6}>
                  <Typography variant="h6" fontWeight="bold">
                    Site Address
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography
                    variant="h6"
                    //fontWeight="light"
                    textAlign="right">
                    {timesheetDetail?.siteAddress}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item  sm={6}>
              <Grid container columnSpacing={2}>
                <Grid item md={6}>
                  <Typography variant="h6" fontWeight="bold">
                    Job Role
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography
                    variant="h6"
                    //fontWeight="light"
                    textAlign="right">
                    {timesheetDetail?.jobRole}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item  sm={6}>
              <Grid container columnSpacing={2}>
                <Grid item md={6}>
                  <Typography variant="h6" fontWeight="bold">
                    Permitted Hours
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography
                    variant="h6"
                    //fontWeight="light"
                    textAlign="right">
                    {removeSeconds(timesheetDetail?.permittedHours)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item  sm={6}>
              <Grid container columnSpacing={2}>
                <Grid item md={6}>
                  <Typography variant="h6" fontWeight="bold">
                    Rate Card
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography
                    variant="h6"
                    //fontWeight="light"
                    textAlign="right">
                    {timesheetDetail?.rateCardName}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {role==='Admin' && (
              <>
                <Grid item  sm={6}>
                  <Grid container columnSpacing={2}>
                    <Grid item md={6}>
                      <Typography variant="h6" fontWeight="bold">
                        Overtime Rate
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography
                        variant="h6"
                        //fontWeight="light"
                        textAlign="right">
                        {convertToPounds(timesheetDetail?.overtimeRate)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item  sm={6}>
                  <Grid container columnSpacing={2}>
                    <Grid item md={6}>
                      <Typography variant="h6" fontWeight="bold">
                        Regular Rate
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography
                        variant="h6"
                        //fontWeight="light"
                        textAlign="right">
                        {convertToPounds(timesheetDetail?.regularRate)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item  sm={6}>
                  <Grid container columnSpacing={2}>
                    <Grid item md={6}>
                      <Typography variant="h6" fontWeight="bold">
                        Night Shift Rate
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography
                        variant="h6"
                        //fontWeight="light"
                        textAlign="right">
                        {convertToPounds(timesheetDetail?.nightShiftRate)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Box>

        <Box className="section-filters">
          <Box className="filter">
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              disabled
              onChange={(update) => {
                setDateRange(update);
              }}
              placeholderText="Select week"
              customInput={
                <TextField
                  sx={{width: '20rem'}}
                  placeholder="Select week"
                />
              }
            />
          </Box>
          <Box className='cta'>
            {role==='Admin' && !editable && (
              <ProButton
              text="Edit"
              color="#cb272f"
              handleClick={() => {navigate(`/timesheets/edit/${id}`, {
                state: {content: customContent,
                  operativeData: operativeData
                },
              })}}
              variant="outlined"
            />
            )}
            {role !== 'Site Manager' && editable &&(
              <>
                <ProButton
                  text="Back"
                  color="#cb272f"
                  handleClick={() => navigate(-1)}
                  variant="outlined"
                />
              </>
            )}

            {!role?.includes('Operative') && !editable &&(
              <>
                <ProButton
                  text="Disapprove"
                  color="#cb272f"
                  handleClick={()=>{
                    setAction('disapprove')
                    setConfirmPopup(true);
                  }}
                  variant="outlined"
                />
                <ProButton
                  text="Approve"
                  color="#cb272f"
                  handleClick={()=>{setAction('approve')
                    setConfirmPopup(true);
                  }}
                  variant="contained"
                />
              </>
            )}
            {editable && role!=='Admin' && (
              <ProButton
                text={`${
                  timesheetDetail?.approvalStatus === 'Not Submitted'
                    ? 'Submit for approval'
                    : 'Submit for re-approval'
                }`}
                color="#6eb066"
                handleClick={handleSubmit}
                variant="contained"
              />
            )}
            {editable && role ==='Admin' && (
              <ProButton
                text={'Save'}
                color="#6eb066"
                handleClick={handleSubmit}
                variant="contained"
              />
            )}
          </Box>
        </Box>
        
        {/* <Box className="bottom-buttons">
          
        </Box> */}
        
        <Box className="section-table">
          <DataTable
            newCols={tableCols}
            newData={tempData ? tempData : []}
            pagination={false}
          />
        </Box>
      </div>
      <ConfirmationDialog
        open={confirmPopup}
        text={
          action==='approve' ? 
          'Are you sure you want to approve this timesheet?' :
          'Are you sure you want to disapprove this timesheet?'
        }
        handleClose={() => setConfirmPopup(false)}
        submitHandle={() => {
          if(action === 'approve'){
            handleApproval();
          }else{ 
            handleDisapproval();
          }
          setConfirmPopup(false);
        }}
      />
    </>
  );
}

export default TimesheetDetails;

import React from 'react'

const Title = ({ name, sortField, sortDir, title, sortHandle }) => {

    const styles = {
        backgroundColor: 'rgb(241, 241, 241)',
        cursor: 'pointer',
        padding: '1.3rem 0.5rem',
        fontSize: '1.4rem',
        fontWeight: 500,
        textAlign: 'left',
    }

  return (
    <th style={{backgroundColor: 'rgb(241, 241, 241)'}} className='tss-gm6zfk-MUIDataTableHeadCell-fixedHeader'>
        <div onClick={()=>sortHandle(name)} className="cursor-pointer" style={styles}>{title}{sortField === name ? (sortDir === 0 ? " ↑" : " ↓") : ""}</div>
    </th>
  )
}

export default Title;
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  Box,
} from '@mui/material';
import {useForm, Controller} from 'react-hook-form';
import {toast} from 'react-toastify';
import {authReset, login} from '../../data/reducers/auth/auth.reducer';

import ProBackdrop from '../../components/common-comp/Backdrop';

import './Auth.css';
import DrawerAppBar from '../../components/DrawerAppBar';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errState, setErrState] = useState(false);
  const {handleSubmit, control, formState} = useForm();

  const {error, isLoggedIn, loading} = useSelector((_) => _.auth);

  const validateSubmit = async (data) => {
    setErrState(false);
    await dispatch(login(data));
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (isLoggedIn) {
      navigate('/');
    }
    dispatch(authReset());
  }, [error, navigate]);

  return (
    <>
      <DrawerAppBar/>
      <div className="auth">
        <ProBackdrop loading={loading} />
        <Card elevation={4}>
          <CardContent>
            <Typography
              variant="h3"
              component="h3"
              align="center"
              fontWeight={'bold'}>
              Login
            </Typography>
            <form
              className="auth-form"
              onSubmit={handleSubmit(validateSubmit)}>
              <Box my={2}>
                <label className="input-label">Username or Email Id</label>
                <Controller
                  name="email"
                  control={control}
                  rules={{required: true}}
                  error={formState.errors?.tag ? true : false}
                  render={({field}) => (
                    <TextField
                      {...field}
                      error={errState}
                      helperText={errState ? 'Invalid email.' : ''}
                      required
                      id="outlined-required"
                      placeholder="Username or Email Id"
                      fullWidth
                      autoComplete="off"
                    />
                  )}
                />
              </Box>
              <Box my={2}>
                <label className="input-label">Password</label>
                <Controller
                  name="password"
                  control={control}
                  error={formState.errors?.tag ? true : false}
                  rules={{required: true}}
                  render={({field}) => (
                    <TextField
                      {...field}
                      required
                      id="outlined-required"
                      placeholder="Password"
                      type="password"
                      fullWidth
                    />
                  )}
                />
              </Box>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  background: '#CB272F',
                }}
                type="submit">
                Login
              </Button>
            </form>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default Login;

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import FadeMenu from './FadeMenu';
import DrawerResponsive from './sidebar/Drawer';
import proliftingLogo from '../assets/images/prolifting-logo.png';
import './header/header.css';

const drawerWidth = 240;

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [show, setShow] = React.useState(false);

  let user = JSON.parse(localStorage.getItem('user'));
  let role = user?.user?.role;

  React.useEffect(() => {
    user ? setShow(true) : setShow(false);
  }, [user]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSideBarToggle = () =>{
    props.handleClick();
  }

  const sidebarNavlinks = [];

  if (role === 'Admin') {
    sidebarNavlinks.push(
      {
        linkName: 'Dashboard',
        path: '/',
        src: 'dashboard',
        id: 1,
      },
      {
        id: 2,
        linkName: 'Operatives',
        path: '/operatives',
        src: 'employees',
      },
      {
        linkName: 'Timesheets',
        path: '/timesheets',
        src: 'timesheets',
        id: 3,
      },
      {
        linkName: 'Sites',
        path: '/sites',
        src: 'sites',
        id: 4,
      },
      {
        linkName: 'Rate Cards',
        path: '/rate-cards',
        src: 'rateCards',
        id: 5,
      },
    );
  } else if (role === 'Site Manager') {
    sidebarNavlinks.push(
      {
        linkName: 'Dashboard',
        path: '/',
        src: 'dashboard',
        id: 1,
      },
      {
        id: 2,
        linkName: 'Operatives',
        path: '/operatives',
        src: 'employees',
      },
      {
        linkName: 'Timesheets',
        path: '/timesheets',
        src: 'timesheets',
        id: 3,
      },
      {
        id: 6,
        linkName: 'My Details',
        path: '/detail',
        src: 'details',
      },
    );
  } else if (role?.includes('Operative')) {
    sidebarNavlinks.push(
      {
        linkName: 'My Timesheets',
        path: '/my-timesheets',
        src: 'timesheets',
        id: 3,
      },
      {
        id: 6,
        linkName: 'My Details',
        path: '/detail',
        src: 'details',
      },
    );
  }

  console.log('role', sidebarNavlinks)

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }} >
      <AppBar component="nav" className='headerDrawer' >
        <Toolbar className='header-toolbar'>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon sx={{color: '#000', fontSize: '2rem'}} />
          </IconButton>
          <Box display='flex' alignItems='center' sx={{ flexGrow: 1}}>
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleSideBarToggle}
              sx={{display: { xs: 'none', md: 'block' }, marginRight:'4rem' }}
            >
              <MenuIcon sx={{color: '#000', fontSize: '2rem'}} />
            </IconButton>
            <Box sx={{display: { xs: 'none', sm: 'block' } }}>
              <img src={proliftingLogo}></img>
            </Box>
          </Box>
          <Box>
            {show && <FadeMenu />}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          <DrawerResponsive clickFn={handleDrawerToggle} sidebarNavlinks={sidebarNavlinks} />
        </Drawer>
      </Box>
    </Box>
  );
}


export default DrawerAppBar;

import axios from 'axios';
import { constants } from '../../constants/config';

let BASE_URL = constants.BASE_URL;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    if (!config.headers['Authorization']) {
      config.headers['Authorization'] = `Bearer ${JSON.parse(
        localStorage.getItem('token'),
      )}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const status = error?.status || error?.response?.status;
    if (status === 403 || status === 401) {
      localStorage.removeItem('user');
      console.log('logout');
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;

import {combineReducers} from 'redux';
import authReducer from './auth/auth.reducer';
import appReducer from './app/app.reducer';
import operativesReducer from './app/operatives.reducer';
import timesheetsReducer from './app/timesheets.reducer';
import rateCardsReducer from './app/rateCards.reducer';
import sitesReducer from './app/sites.reducer';
import dropdownReducer from './app/dropdown.reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  app: appReducer,
  operatives: operativesReducer,
  timesheets: timesheetsReducer,
  rateCards: rateCardsReducer,
  sites: sitesReducer,
  dropdown: dropdownReducer,
});

export default rootReducer;

import {useLocation, Navigate, Outlet} from 'react-router-dom';
import React from 'react';

const RequireAuth = ({allowedRoles}) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const location = useLocation();

  return allowedRoles?.includes(user?.user?.role) ? (
    <Outlet />
  ) : user?.user ? (
    <Navigate to="/unauthorized" state={{from: location}} />
  ) : (
    <Navigate to="/login" state={{from: location}} />
  );
};

export default RequireAuth;

import React, {useEffect, useState} from 'react';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom';

import DataTable from '../../../components/dataTable/DataTable';
import Navigation from '../../../components/navigation/Navigation';
import ProButton from '../../../components/common-comp/ProButton';
import ReactSelect from 'react-select';
import {useDispatch, useSelector} from 'react-redux';
import {MdOutlineClose, MdOutlineEdit} from 'react-icons/md';
import {exportOperativeTimesheet, getAllTimesheet} from '../../../data/reducers/app/timesheets.reducer';
import StatusChip from '../timesheets/components/StatusChip';
import {tableData} from '../../../utils/tableData';
import {updateOperative } from '../../../data/reducers/app/operatives.reducer';
import { getAll } from '../../../data/reducers/app/dropdown.reducer';
import AddModalOperative from './AddModalOperative';
import {toast} from 'react-toastify';

function OperativeDetails() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const content = location.state.content;

  const {lstAllSites, lstAllJobRoles} = useSelector(
    (state) => state.dropdown,
  ).dropdown;
  const {operativeTimesheet} = useSelector((_) => _.timesheets);
  const {error, message} = useSelector((_) => _.operatives);
  const timesheetDetail = operativeTimesheet;
  const [addPopup, setAddPopup] = useState(false);

  const [filters, setFilters] = useState({
    PageNumber: 1,
    SortDir: 0,
    SortField: '',
    PageSize: 10,
    siteId: '',
    operativeName: '',
    jobRoleId:'',
    approvalStatus: '',
  });
  const [showCross, setShowCross] = useState(false);
  const [details, setDetails] = useState({});
  const [responseData, setResponseData] = useState(null);

  const role = JSON.parse(localStorage.getItem('user'))?.user?.role;

  let lstApprovalStatus = [];
  {role === 'Admin' && (
    lstApprovalStatus = [
      {value: 'Admin Approved'},
      {value: 'Admin Disapproved'},
      {value: 'Manager Approved'},
      {value: 'Saved'},
    ]
  )}
  {role === 'Site Manager' && (
    lstApprovalStatus = [
      {value: 'Admin Approved'},
      {value: 'Admin Disapproved'},
      {value: 'Manager Approved'},
      {value: 'Manager Disapproved'},
      {value: 'Approval Pending'},
    ]
  )}

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: '100%',
      fontSize: '1.3rem',
      marginTop: '.6rem',
    }),
    option: (provided, {isFocused}) => ({
      ...provided,
      fontSize: '1.3rem',
      backgroundColor: isFocused ? '#329BEF' : 'white',
      color: isFocused ? 'white' : 'black',
      zIndex: 400,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 900,
    }),
    control: (styles) => ({...styles, padding: '0.2rem'}),
  };

  const tableCols = tableData.timesheets?.columns.map((column) => {
    if (column?.link) {
      return {
        name: column.name,
        label: column.label,
        options: {
          customBodyRender: (value, tableMeta) => {
            return (
              <div
                onClick={() => {
                  let temp = responseData[tableMeta?.rowIndex];
                  navigate(`/timesheets/detail/${temp?.id}`, {
                    state: {
                      content: details?.timesheets,
                      operativeData: temp,
                    },
                  });
                }}
                style={{cursor: 'pointer', textDecoration: 'underline'}}>
                {value}
              </div>
            );
          },
        },
      };
    } else if (column?.status) {
      return {
        name: column.name,
        label: column.label,
        options: {
          customBodyRender: (value) => {
            return <StatusChip text={value} />;
          },
        },
      };
    } else if (column?.week) {
      return {
        name: column.name,
        label: column.label,
        options: {
          customBodyRender: (value, tableMeta) => {
            return <div>{`${handleDate(value)} - ${handleDate(responseData[tableMeta.rowIndex]?.endDate)}`}</div>;
          },
        },
      };
    }else {
      return {
        name: column.name,
        label: column.label,
      };
    }
  });

  {role === 'Admin' && (
    tableCols?.push({
    name: 'totalCost',
    label: 'Cost',
    options: {
      customBodyRender: (value) => {
        return <span>£{value}</span>;
      },
    },
  }))}

  {role === 'Admin' && (
    tableCols?.push({
      name: 'Actions',
      options: {
        customBodyRender: (_, tableMeta) => {
          return (
            <span
              style={{
                padding: '.5rem',
                width: 'max-content',
              }}>
              <button
                onClick={() => handleEdit(tableMeta)}
                style={{
                  border: 'none',
                  marginRight: '.5rem',
                  cursor: 'pointer',
                  background: 'transparent',
                }}>
                <MdOutlineEdit size="1.8rem" />
              </button>
            </span>
          );
        },
      },
    })
  )}

  const handleDate = (dateVal) => {
    var date = new Date(dateVal);
    var print =
      (date.getMonth() > 8
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)) +
      '-' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
      '-' +
      date.getFullYear();
    return `${print}`;
  };

  const handleEdit = (tableMeta) => {
    //setShowCross(true);
    let temp = responseData[tableMeta?.rowIndex];
    navigate( `/timesheets/edit/${temp?.id}`, {
      state: {
        content: details?.timesheets,
        operativeData: temp,
      },
    })
  };

  const handleOnClick = (key) => {
    if (key === 'employees') console.log('emp');
    else if (key === 'timesheets') console.log('timesheets');
    else if (key === 'sites') console.log('sites');
    else if (key === 'rateCards') console.log('rateCards');
  };

  const exportOperativesFn = async() =>{
    {role === 'Admin' && (
      await (dispatch(exportOperativeTimesheet({
        endpoint: `Timesheets/export-operative-timesheets/${content?.id}?operativeName=${
          filters?.operativeName
        }&jobType=${
          filters?.operativeType ? filters?.operativeType?.value : ''
        }`,
      })))
    )}

    {role === 'Site Manager' && (
      await (dispatch(exportOperativeTimesheet({
        endpoint: `Timesheets/export-operative-timesheets/${content?.id}?OperativeName=${
          filters?.operativeName
        }&JobRoleId=${
          filters?.jobRoleId ? filters?.jobRoleId?.id : ''
        }&SiteId=${
          filters?.siteId ? filters?.siteId?.id : ''
        }&ApprovalStatus=${filters?.approvalStatus ? filters?.approvalStatus.value : ''}`,
      })))
    )}
    
  }

  const editOperative = async(saveData) =>{
    const data = {
      fullName: saveData?.fullName,
      email: saveData?.email,
      phoneNumber: saveData?.phoneNumber,
      userName: saveData?.userName,
      role: null,
      password: saveData?.password,
    }
    await dispatch(
      updateOperative({endpoint: `Employee/update/${content?.id}`, data}),
    );
    setAddPopup(false);
    handleFetch();
  }

  useEffect(() => {
    if(filters?.operativeName){setShowCross(true)}
    handleFetch();
  }, [filters, content]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    } else if (message) {
      toast.success(message);
    }
  }, [error, message]);

  const handleFetch = async() =>{
    setAddPopup(false);
    dispatch(
      getAllTimesheet({
        endpoint: `Timesheets/get-all/${content?.id}?SortField=${
          filters?.SortField
        }&SortDir=${filters?.SortDir}&PageNumber=${
          filters?.PageNumber
        }&PageSize=${filters?.PageSize}&OperativeName=${
          filters?.operativeName
        }&JobRoleId=${
          filters?.jobRoleId ? filters?.jobRoleId?.id : ''
        }&SiteId=${
          filters?.siteId ? filters?.siteId?.id : ''
        }&ApprovalStatus=${filters?.approvalStatus ? filters?.approvalStatus.value : ''}`,
      }),
    );

    dispatch(getAll());
    
  }

  useEffect(() => {
    setResponseData(
      (timesheetDetail && timesheetDetail[0]?.timeSheetsResponses) || [],
    );
    setDetails(timesheetDetail && timesheetDetail[0]);
  }, [timesheetDetail]);

  const navigation = [
    {
      name: ' Operatives / ',
      path: '/operatives'
    },
    {
      name: 'Details',
      path:''
    },
  ]

  const render = () => {
    return (
      <Box className="page-container">
        <Navigation path={navigation} />
        <Box className="section-top">
          <h1>{content?.fullName}</h1>
          {role === 'Admin' && (
            <Box className="weekly-cost" display="flex">
              <Typography variant="h4" fontWeight="light">
                Total Cost: &nbsp;
              </Typography>
              <Typography variant="h4" fontWeight="bold">
                £{details?.totalCostofOperative || 0}
              </Typography>
            </Box>
          )}
        </Box>
        <Box className="section-info" borderRadius={1} px={2}>
          {role==='Admin' && (<Box display="flex" justifyContent="flex-end">
            <IconButton onClick={() => setAddPopup(true)}>
              <MdOutlineEdit size="2rem" />
            </IconButton>
          </Box>)}
          <Grid container columnSpacing={12} rowSpacing={1} mt={2}>
            <Grid item  sm={6}>
              <Grid container columnSpacing={2}>
                <Grid item md={6}>
                  <Typography variant="h6" fontWeight="bold">
                    Operative Name
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography
                    variant="h6"
                    //fontWeight="light"
                    textAlign="right">
                    {details?.operativeName}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item  sm={6}>
              <Grid container columnSpacing={2}>
                <Grid item md={6}>
                  <Typography variant="h6" fontWeight="bold">
                    Operative Email
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography
                    variant="h6"
                    //fontWeight="light"
                    textAlign="right">
                    {details?.email}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item  sm={6}>
              <Grid container columnSpacing={2}>
                <Grid item md={6}>
                  <Typography variant="h6" fontWeight="bold">
                    Operative Phone Number
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography
                    variant="h6"
                    //fontWeight="light"
                    textAlign="right">
                    {details?.phoneNumber}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item  sm={6}>
              <Grid container columnSpacing={2}>
                <Grid item md={6}>
                  <Typography variant="h6" fontWeight="bold">
                    Operative Type
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography
                    variant="h6"
                    //fontWeight="light"
                    textAlign="right">
                    {details?.operativeRole}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item  sm={6}>
              <Grid container columnSpacing={2}>
                <Grid item md={6}>
                  <Typography variant="h6" fontWeight="bold">
                    Operative Username
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography
                    variant="h6"
                    //fontWeight="light"
                    textAlign="right">
                    {details?.userName}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>   
        </Box>

        <Box display="flex" justifyContent="space-between">
          {role === 'Admin' && (
            <Box className="weekly-cost" display="flex">
              <Typography variant="h4" fontWeight="bold">
                This Week: &nbsp;
              </Typography>
              <Typography variant="h4" fontWeight="bold">
                £{details?.thisWeekCost || 0}
              </Typography>
            </Box>
          )}
          <Box display="flex" alignItems="center" marginLeft='auto'>
            <Box mr={2}>
              <ProButton
                text="Send E-Mail"
                color="#cb272f"
                handleClick={handleOnClick}
                variant="outlined"
                mx0
              />
            </Box>
            <Box>
              <ProButton
                text="Export Data"
                color="#cb272f"
                handleClick={exportOperativesFn}
                variant="contained"
                mx0
              />
            </Box>
          </Box>
        </Box>

        <Grid container spacing={2}>
          <Grid item md={3} sm={6} xs={12}>
            <Typography variant="h6">Filter by Site</Typography>
            <ReactSelect
              name="site"
              required
              styles={customStyles}
              options={lstAllSites}
              getOptionLabel={(option) => option?.siteName}
              value={filters?.siteId || ''}
              defaultValue=""
              isClearable
              placeholder="Select Site"
              onChange={(e) => {
                setFilters({...filters, siteId: e});
              }}
            />
          </Grid>
          
          <Grid item md={3} sm={6} xs={12}>
            <Typography variant="h6">Filter by Job Role</Typography>
            <ReactSelect
              name="role"
              required
              styles={customStyles}
              options={lstAllJobRoles}
              getOptionLabel={(option) => option?.jobRole}
              value={filters?.jobRoleId || ''}
              defaultValue=""
              isClearable
              placeholder="Select Job Role"
              onChange={(e) => {
                setFilters({...filters, jobRoleId: e});
              }}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Typography variant="h6">Filter by Approval Status</Typography>
            <ReactSelect
              name="status"
              required
              styles={customStyles}
              options={lstApprovalStatus}
              getOptionLabel={(option) => option?.value}
              value={filters?.approvalStatus || ''}
              defaultValue=""
              isClearable
              placeholder="Select Status"
              onChange={(e) => {
                setFilters({...filters, approvalStatus: e});
              }}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Typography variant="h6">Filter by Operative Name</Typography>
            <TextField
              id="outlined-required"
              placeholder="Search..."
              fullWidth
              value={filters?.operativeName}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  operativeName: e.target.value,
                  PageNumber: 1,
                });
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {showCross && (
                      <IconButton
                        onClick={() => {
                          setFilters({...filters, operativeName: ''});
                        }}>
                        <MdOutlineClose size="2rem" />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Box className="section-table">
          <DataTable newCols={tableCols} newData={responseData} />
        </Box>
      </Box>
    );
  };

  return (
    <div
      style={{
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        overflowX: 'hidden',
      }}>
      {render()}
      <AddModalOperative
        open={addPopup}
        handleClose={() => setAddPopup(false)}
        submitHandle={editOperative}
        selectedRecord={{
          fullName: details?.operativeName,
          email: details?.email,
          phoneNumber: details?.phoneNumber,
          userName: details?.userName,
        }}
        detail={true}
      />
    </div>
  );
}

export default OperativeDetails;

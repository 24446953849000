import React, {useState} from 'react';
import MUIDataTable from 'mui-datatables';
//import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import './datatable.css';

const DataTable = ({
  newCols,
  newData,
  filter,
  setFilter,
  pagination,
  totalCount,
  sorting,
  //short,
  onRowSelectionChange,
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const getMuiTheme = () => createTheme({
    components: {
      MuiTableRow: {
        styleOverrides:{
          root: {
            '@media (max-width: 900px)':{
              borderBottom: "2px solid var(--gray-bg)",
              marginBottom: '.3rem'
            }
          }
        }
      }
    }
  })

  

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        data={newData ? newData : []}
        columns={newCols ? newCols : []}
        options={{
          sort: sorting == true ? true : false,
          elevation: 2,
          responsive: 'standard',
          serverSide: true,
          tableBodyMaxHeight: '50vh',
          //tableBodyHeight: short ? '250px' : '400px',
          count: totalCount,
          textLabels: {
            body: {
              noMatch: 'No records',
            },
          },
          page: page,
          rowsPerPage: rowsPerPage,
          pagination: pagination == true ? true : false,
          rowsPerPageOptions: [5, 10, 15, 20],
          onTableChange: (action, tableState) => {
            if (action === 'changePage') {
              setFilter({
                ...filter,
                PageNumber: tableState.page + 1,
              });
              setPage(tableState.page);
            } else if (action === 'changeRowsPerPage') {
              setFilter({
                ...filter,
                PageSize: tableState.rowsPerPage,
              });
              setRowsPerPage(tableState.rowsPerPage);
            }
          },
          onRowSelectionChange: onRowSelectionChange,
          selectableRows: onRowSelectionChange ? 'multiple' : 'none',
          customToolbarSelect: () => {}}}
      />
    </ThemeProvider>
  );
};

export default DataTable;

import * as React from 'react';
import {Typography} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import ProButton from './common-comp/ProButton';

function ConfirmationDialog({open, text, handleClose, submitHandle}) {
  const handleCancel = () => {
    handleClose();
  };

  const handleOk = () => {
    submitHandle();
  };

  return (
    <Dialog maxWidth="sm" open={open}>
      <DialogContent dividers>
        <Typography variant="h5" pr={10}>
          {text}
        </Typography>
      </DialogContent>
      <DialogActions>
        <ProButton
          text="Cancel"
          color="#cb272f"
          handleClick={handleCancel}
          variant="outlined"
        />
        <ProButton
          text="Yes"
          color="#cb272f"
          handleClick={handleOk}
          variant="contained"
        />
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;

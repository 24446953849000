import React, {useState, useEffect} from 'react';
import {Box, Grid, IconButton, Typography} from '@mui/material';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux/es/exports';
import {toast} from 'react-toastify';
import {MdOutlineEdit, MdOutlineDelete} from 'react-icons/md';

import {
  getSingleSite,
  updateSite,
  updateOperativeSiteLink,
  deleteOperativeSiteLink,
  createOperativeSiteLink,
} from '../../../data/reducers/app/sites.reducer';
import {convertToPounds} from '../../../utils/currency';
import {removeSeconds} from '../../../utils/time';

import AddOperativeModal from './AddOperativeModal';
import Navigation from '../../../components/navigation/Navigation';
import AddModalSites from './AddModalSites';
import DataTable from '../../../components/dataTable/DataTable';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import ProButton from '../../../components/common-comp/ProButton';

import '../../../components/common-comp/CommonComp.css';

const SiteDetails = () => {
  const {id} = useParams();
  const dispatch = useDispatch();

  const {siteDetails, error, message} = useSelector(
    (state) => state.sites,
  );

  const [addPopup, setAddPopup] = useState(false);
  const [sitePopup, setSitePopup] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [editOperativeRow, setEditOperativeRow] = useState('');

  useEffect(() => {
    handleFetch();
  }, [id]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    } else if (message) {
      toast.success(message);
    }
  }, [error, message]);

  useEffect(() => {
    setTableData(siteDetails?.siteDetails);
  }, [siteDetails]);

  const handleFetch = async () => {
    setEditOperativeRow('');
    setSitePopup(false);
    setAddPopup(false);
    setConfirmPopup(false);

    await dispatch(getSingleSite({endpoint: `Site/get-site/${id}`}));
  };

  const addOperative = () => {
    setAddPopup(true);
    setEditOperativeRow('');
  };

  const submitModal = async (saveData, updated) => {
    if (updated) {
      const {operativeId, rateCardId, jobRoleId} = saveData;
      const data = {
        operativeId,
        rateCardId,
        jobRoleId,
      };
      await dispatch(
        updateOperativeSiteLink({
          endpoint: `Site/update-operativesitelink/${deleteId}`,
          data,
        }),
      );
    } else {
      saveData.siteId = siteDetails?.siteId;
      await dispatch(
        createOperativeSiteLink({
          endpoint: `/Site/create-operativesitelink`,
          data: saveData,
        }),
      );
    }
    if (!error) {
      setAddPopup(false);
      handleFetch();
    }
  };

  const editSite = async (editData) => {
    const {
      siteName,
      siteCode,
      siteAddress,
      siteManagerId,
      permittedHours,
    } = editData;
    const data = {
      siteName,
      siteCode,
      siteAddress,
      siteManagerId,
      permittedHours,
    };
    await dispatch(
      updateSite({endpoint: `Site/update/${siteDetails?.siteId}`, data}),
    );
    if (!error) {
      setAddPopup(false);
      handleFetch();
    }
  };

  const deleteOperative = async () => {
    if (deleteId !== '') {
      await dispatch(
        deleteOperativeSiteLink({
          endpoint: `Site/delete-operativesitelink/${deleteId}`,
        }),
      );
      handleFetch();
    }
  };

  const cols = [
    {
      name: 'operativeName',
      label: 'Operative Name',
    },
    {
      name: 'rateCardName',
      label: 'Rate Card Name',
    },
    {
      name: 'jobRoleName',
      label: 'Job Role Name',
    },
    {
      name: 'nightShiftRate',
      label: 'Night Shift Rate',
      options: {
        customBodyRender: (value) => {
          return <div>{convertToPounds(value)}</div>;
        },
      },
    },
    {
      name: 'overtimeRate',
      label: 'Overtime Rate',
      options: {
        customBodyRender: (value) => {
          return <div>{convertToPounds(value)}</div>;
        },
      },
    },
    {
      name: 'regularRate',
      label: 'Regular Rate',
      options: {
        customBodyRender: (value) => {
          return <div>{convertToPounds(value)}</div>;
        },
      },
    },
  ];

  cols?.push({
    name: 'Actions',
    options: {
      customBodyRender: (_, tableMeta) => {
        return (
          <span
            style={{
              padding: '.5rem',
              width: 'max-content',
            }}>
            <button
              onClick={() => {
                console.log(tableMeta);
                let temp = siteDetails?.siteDetails;
                setAddPopup(true);
                setEditOperativeRow(temp[tableMeta.rowIndex]);
                setDeleteId(tableData[tableMeta.rowIndex]?.id);
              }}
              className="table-buttons">
              <MdOutlineEdit size="1.8rem" />
            </button>
            <button
              className="table-buttons"
              onClick={() => {
                setConfirmPopup(true);
                setDeleteId(tableData[tableMeta.rowIndex]?.id);
              }}>
              <MdOutlineDelete size="1.8rem" />
            </button>
          </span>
        );
      },
    },
  });

  const navigation = [
    {
      name: ' Sites /',
      path: '/sites'
    },
    {
      name: ' details ',
    },
  ]

  return (
    <div>
      <div className="page-container">
        <Navigation path={navigation} />

        <Box className="section-top">
          <h1>{siteDetails?.siteName}</h1>
        </Box>

        <Box className="section-info" padding={2} borderRadius={2}>
          <Grid display="flex" justifyContent="space-between">
            <Box>
              
            </Box>
            <IconButton onClick={() => setSitePopup(true)}>
              <MdOutlineEdit size="2rem" />
            </IconButton>
            {/* 
            <ProButton
              text="Edit"
              color="#cb272f"
              variant="contained"
              handleClick={() => setSitePopup(true)}
              mx0
            /> */}
          </Grid>
          <Box className="details-container" pt={0}>
            <section className="w-50">
              <Box className="sp-between my-half">
                <Typography
                  variant="h6"
                  display="flex"
                  fontWeight="bold"
                  justifyContent="end">
                  Site Manager Name
                </Typography>

                <Typography
                  variant="h6"
                  display="flex"
                  justifyContent="end">
                  {siteDetails?.siteManagerName ||'_'}
                </Typography>
              </Box>
              <Box className="sp-between my-half">
                <Typography
                  variant="h6"
                  display="flex"
                  fontWeight="bold"
                  justifyContent="end">
                  Site Name
                </Typography>

                <Typography
                  variant="h6"
                  display="flex"
                  justifyContent="end">
                  {siteDetails?.siteName}
                </Typography>
              </Box>
              <Box className="sp-between my-half">
                <Typography
                  variant="h6"
                  display="flex"
                  fontWeight="bold"
                  justifyContent="end">
                  Site Code
                </Typography>

                <Typography
                  variant="h6"
                  display="flex"
                  justifyContent="end">
                  {siteDetails?.siteCode}
                </Typography>
              </Box>
            </section>

            <section className="w-50">
              <Box className="sp-between my-half">
                <Typography
                  variant="h6"
                  display="flex"
                  fontWeight="bold"
                  justifyContent="end">
                  Permitted Hours
                </Typography>

                <Typography
                  variant="h6"
                  display="flex"
                  justifyContent="end">
                  {removeSeconds(siteDetails?.permittedHours)}
                </Typography>
              </Box>
              <Box className="sp-between my-half">
                <Typography
                  variant="h6"
                  display="flex"
                  fontWeight="bold"
                  justifyContent="end">
                  Site Address
                </Typography>

                <Typography
                  variant="h6"
                  display="flex"
                  justifyContent="end">
                  {siteDetails?.siteAddress}
                </Typography>
              </Box>
            </section>
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <ProButton
            text="+ Add Operative"
            color="#6eb066"
            variant="contained"
            handleClick={addOperative}
            mx0
          />
        </Box>
        <section className="section-table">
          <DataTable newCols={cols} newData={tableData} />
        </section>
      </div>

      <AddOperativeModal
        open={addPopup}
        handleClose={() => setAddPopup(false)}
        submitHandle={submitModal}
        selectedRecord={editOperativeRow}
      />
      <AddModalSites
        open={sitePopup}
        handleClose={() => setSitePopup(false)}
        submitHandle={editSite}
        selectedRecord={{
          siteManagerId: siteDetails?.siteManagerId,
          siteName: siteDetails?.siteName,
          siteCode: siteDetails?.siteCode,
          siteAddress: siteDetails?.siteAddress,
          permittedHours: siteDetails?.permittedHours,
        }}
      />
      <ConfirmationDialog
        open={confirmPopup}
        text="Are you sure you want to remove this operative?"
        handleClose={() => setConfirmPopup(false)}
        submitHandle={() => {
          deleteOperative();
          setConfirmPopup(false);
        }}
      />
    </div>
  );
};

export default SiteDetails;

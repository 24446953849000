import React from 'react';
import {Provider, useSelector} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {configureStore} from '@reduxjs/toolkit';
import {ToastContainer} from 'react-toastify';

import AppRoutes from './routes/AppRoutes';
import rootReducer from './data/reducers';
import AuthRoutes from './routes/AuthRoutes';
//import Header from './components/header/Header';
//import DrawerAppBar from './components/DrawerAppBar';

import 'react-toastify/dist/ReactToastify.css';

const store = configureStore({
  reducer: rootReducer,
});

const App = () => {
  const {isLoggedin} = useSelector((state) => state.auth);
  return (
    <BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        rtl={false}
        closeOnClick
        draggable
        pauseOnHover
        limit={1}
      />
      {/* <Header /> */}
      {/* <DrawerAppBar/> */}
      {isLoggedin ? <AppRoutes /> : <AuthRoutes />}
    </BrowserRouter>
  );
};

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

export default AppWrapper;

import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {
  Card,
  CardContent,
  TextField,
  Button,
  Divider,
  Typography,
  Box,
} from '@mui/material';
import {useForm, Controller} from 'react-hook-form';
import {useDispatch} from 'react-redux';

import './Auth.css';
import {register} from '../../data/reducers/auth/auth.reducer';

const Login = () => {
  const dispatch = useDispatch();
  const [errState, setErrState] = useState(false);
  const {handleSubmit, control, formState} = useForm();

  const validateSubmit = async (data) => {
    let re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!re.test(data.email)) {
      setErrState(true);
    } else {
      setErrState(false);
      dispatch(register(data));
    }
  };

  return (
    <div className="auth">
      <Card elevation={4}>
        <CardContent>
          <Typography
            variant="h3"
            component="h3"
            align="center"
            fontWeight={'bold'}>
            Login
          </Typography>
          <form
            className="auth-form"
            onSubmit={handleSubmit(validateSubmit)}>
            <Box my={2}>
              <label className="input-label">Name</label>
              <Controller
                name="name"
                control={control}
                rules={{required: true}}
                error={formState.errors?.tag ? true : false}
                render={({field}) => (
                  <TextField
                    {...field}
                    required
                    id="outlined-required"
                    placeholder="Name"
                    fullWidth
                  />
                )}
              />
            </Box>
            <Box my={2}>
              <label className="input-label">E-mail ID</label>
              <Controller
                name="email"
                control={control}
                rules={{required: true}}
                error={formState.errors?.tag ? true : false}
                render={({field}) => (
                  <TextField
                    {...field}
                    error={errState}
                    helperText={errState ? 'Invalid e-mail.' : ''}
                    required
                    id="outlined-required"
                    placeholder="E-mail"
                    fullWidth
                  />
                )}
              />
            </Box>
            <Box my={2}>
              <label className="input-label">Password</label>
              <Controller
                name="password"
                control={control}
                error={formState.errors?.tag ? true : false}
                rules={{required: true}}
                render={({field}) => (
                  <TextField
                    {...field}
                    required
                    id="outlined-required"
                    placeholder="Password"
                    type="password"
                    fullWidth
                  />
                )}
              />
            </Box>
            <Button
              variant="contained"
              fullWidth
              sx={{
                background: '#CB272F',
              }}
              type="submit">
              Sign Up
            </Button>
          </form>
          <Divider />
          <span className="footer-text">
            Already have an account? <Link to={'/login'}>Log In</Link>.
          </span>
        </CardContent>
      </Card>
    </div>
  );
};

export default Login;

import React, {useEffect, useState} from 'react';
import ReactSelect, {components} from 'react-select';
import {useSelector, useDispatch} from 'react-redux';
import {MdOutlineClose, MdAdd, 
  MdOutlineEdit,
  MdOutlineDelete
  } from 'react-icons/md';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import {
  Modal,
  IconButton,
  TextField,
  Box,
  Typography,
  Divider,
  InputAdornment,
} from '@mui/material';
import {useForm, Controller} from 'react-hook-form';

//import AddModalJobRole from './AddModalJobRate';
import {getAll} from '../../../data/reducers/app/dropdown.reducer';
import {
  deleteRateCard,
  saveRateCard, updateRateCard,
} from '../../../data/reducers/app/rateCards.reducer';
import AddJobRole from './AddJobRole';

import '../../../components/addModal.css';
import './RateCard.css';
import ConfirmationDialog from '../../../components/ConfirmationDialog';

function AddModalJobRate({
  open,
  handleClose,
  submitHandle,
  selectedRecord,
}) {
  const dispatch = useDispatch();

  const [dropVal, setDropVal] = useState({
    jobRole: '',
  });

  const [addPopup, setAddPopup] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [editRole, setEditRole] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  const {lstActiveJobRoles} = useSelector(
    (state) => state.dropdown.dropdown,
  );

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 400,
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2.5,
    borderRadius: 1,
  };

  const {handleSubmit, control, register, formState, reset, setValue} =
    useForm({shouldUnregister: true});

  const handleClick = (data) => {
    console.log('handleClick');
    if (selectedRecord) {
      submitHandle(data, true);
    } else {
      submitHandle(data, false);
    }
  };

  const closeModal = () => {
    setDropVal({
      jobRole: '',
    });
    handleClose();
  };

  useEffect(() => {
    console.log('modal open');
    if (selectedRecord) {
      for (const [key, value] of Object.entries(selectedRecord)) {
        setValue(key, value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
      setDropVal({
        ...dropVal,
        jobRole: lstActiveJobRoles?.filter(
          (option) => option.id == selectedRecord?.jobRoleId,
        )[0],
      });
      setEditRole(null)
    }else {
      reset();
      setEditRole(null)
      setDropVal({
        ...dropVal,
        jobRole: '',
      });
    }
    dispatch(getAll());
  }, [open]);

  useEffect(() => {
    // <---for Edit Operative Modal
    console.log('edit');
    if (selectedRecord) {
      for (const [key, value] of Object.entries(selectedRecord)) {
        setValue(key, value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    }

    setDropVal({
      ...dropVal,
      jobRole: lstActiveJobRoles?.filter(
        (option) => option.id == selectedRecord?.jobRoleId,
      )[0],
    });
  }, [selectedRecord]);

  useEffect(() => {
    reset();
    setDropVal({
      ...dropVal,
      jobRole: '',
    });
    dispatch(getAll());
    console.log('useEffect');
  }, []);

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: 200,
      fontSize: '1.3rem',
      marginTop: '.6rem',
    }),
    option: (provided, {isFocused}) => ({
      ...provided,
      fontSize: '1.3rem',
      backgroundColor: isFocused ? '#329BEF' : 'white',
      color: isFocused ? 'white' : 'black',
      zIndex: 400,
    }),
    menuPortal: (base) => ({...base, zIndex: 9999}),
    control: (styles) => ({...styles, padding: '0.3rem'}),
  };

  const addJobRole = () =>{
    console.log('job')
    setAddPopup(true);
  }

  const handleJobRole = async(data, updated) =>{
    if(!updated){
      console.log(data);
      await dispatch(saveRateCard({
        endpoint:`JobRoles/create`,
        data
      }))
      await dispatch(getAll())
      setAddPopup(false);
    }else{
      await dispatch(updateRateCard({
        endpoint:`JobRoles/update/${editRole?.id}`,
        data
      }))
      await dispatch(getAll())
      setAddPopup(false);
    }
    setDropVal({
      ...dropVal,
      jobRole: '',
    });
  }

  const deleteJobRole = async() =>{
    await dispatch(deleteRateCard({
      endpoint:`JobRoles/delete/${deleteId}`,
    }))
    await dispatch(getAll())
    setConfirmPopup(false);
    setDropVal({
      ...dropVal,
      jobRole: '',
    });
  }

  const SelectMenuButton = ({children, ...rest}) => {
    return (
        <components.Option {...rest} className='sp-between'>  
          <div className='sp-between'>
            {children}
            <span
            style={{
              padding: '.5rem',
              width: 'max-content',
            }}>
              <button
                onClick={() => {
                  setEditRole(rest?.data);
                  setAddPopup(true);
                }}
                className="table-buttons">
                <MdOutlineEdit size="1.8rem" />
              </button>
              <button
                className="table-buttons"
                onClick={() => {
                  setDeleteId(rest?.data?.id);
                  setConfirmPopup(true);
                }}
              >
                <MdOutlineDelete size="1.8rem" />
              </button>
            </span>
          </div>
        </components.Option >
    ) }

  return (
    <>
      <Modal open={open} onClose={closeModal}>
        <Box sx={style}>
          <Box display="flex" justifyContent="space-between" mb={2}>
            {!selectedRecord ? (
              <Typography variant="h3">Add Job Role</Typography>
            ) : (
              <Typography variant="h3">Edit Job Role</Typography>
            )}
            <IconButton onClick={closeModal}>
              <MdOutlineClose size="2rem" />
            </IconButton>
          </Box>
          <Divider />

          <Box mt={3} mb={2}>
            <form onSubmit={handleSubmit(handleClick)} className="add-form">
                <Box className="rate-card-modal" >
                  <Typography variant="h6">Job Role</Typography>
                  <Box display="flex" justifyContent="space-between" gap="0.3rem">  
                    <Controller
                      name="jobRoleId"
                      control={control}
                      defaultValue=""
                      rules={{required: true}}
                      error={formState.errors?.tag ? true : false}
                      render={({field}) => (
                        <ReactSelect
                          name="jobRoleId"
                          field={field}
                          required
                          styles={customStyles}
                          options={lstActiveJobRoles}
                          components={{Option: SelectMenuButton}}
                          getOptionLabel={(option) => option?.jobRole}
                          value={dropVal?.jobRole || ''}
                          placeholder="Select Job Role"
                          defaultValue=""
                          onChange={(e) => {
                            field.onChange(e?.id);
                            setDropVal({...dropVal, jobRole: e});
                          }}
                          menuPortalTarget={document.getElementById('modal')}
                        />
                      )}
                    />
                  
                    <IconButton onClick={addJobRole}>
                      <MdAdd size="2.3rem" />
                    </IconButton>
                  </Box>
                  <span className="err-form">
                    {formState.errors?.jobRoleId
                      ? `Job Role is required`
                      : ''}
                  </span>
                </Box>
                
              <Box className="rate-card-modal">
                <Typography variant="h6">Night Shift Rate</Typography>
                <TextField
                  {...register(`nightShiftRate`, {
                    required: true,
                    validate: {
                      type: (value) => isNaN(parseInt(value)) === false,
                    },
                  })}
                  required
                  id="outlined-required"
                  placeholder="Night Shift Rate"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <CurrencyPoundIcon /> / hour
                      </InputAdornment>
                    ),
                  }}
                />
                <span className="err-form">
                  {formState.errors?.nightShiftRate ? 
                    (formState.errors?.nightShiftRate?.type === 'type'
                    ? `Invalid Rate`
                    : 'Night Shift Rate is required.') :
                    ''
                  }
                </span>
              </Box>
              <Box className="rate-card-modal">
                <Typography variant="h6">Regular Rate</Typography>
                <TextField
                  {...register(`regularRate`, {
                    required: true,
                    validate: {
                      type: (value) => isNaN(parseInt(value)) === false,
                    },
                  })}
                  required
                  id="outlined-required"
                  placeholder="Regular Rate"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <CurrencyPoundIcon /> / hour
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
                <span className="err-form">
                  {formState.errors?.regularRate ? 
                    (formState.errors?.regularRate?.type === 'type'
                    ? `Invalid Rate`
                    : 'Regular Rate is required.') :
                    ''
                  }
                </span>
              </Box>
              <Box className="rate-card-modal">
                <Typography variant="h6">Overtime Rate</Typography>
                <TextField
                  {...register(`overtimeRate`, {
                    required: true,
                    validate: {
                      type: (value) => isNaN(parseInt(value)) === false,
                    },
                  })}
                  required
                  id="outlined-required"
                  placeholder="Overtime Rate"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <CurrencyPoundIcon /> / hour
                      </InputAdornment>
                    ),
                  }}
                />
                <span className="err-form">
                  {formState.errors?.overtimeRate ? 
                    (formState.errors?.overtimeRate?.type === 'type'
                    ? `Invalid Rate`
                    : 'Overtime Rate is required.') :
                    ''
                  }
                </span>
              </Box>
              <button
                className="form-button"
                onClick={handleSubmit(handleClick)}>
                Save
              </button>
            </form>
          </Box>
          <AddJobRole
            open={addPopup}
            handleClose={() => setAddPopup(false)}
            submitHandle={handleJobRole}
            selectedRecord={editRole}
          />
          <ConfirmationDialog
            open={confirmPopup}
            text="Are you sure you want to delete this job role?"
            handleClose={() => setConfirmPopup(false)}
            submitHandle={() => {
              deleteJobRole();
              setConfirmPopup(false);
            }}
          />
        </Box>
      </Modal>
    </>
  );
}

export default AddModalJobRate;

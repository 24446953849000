import React, {useEffect, useState} from 'react';
import {
  Modal,
  TextField,
  IconButton,
  Typography,
  Divider,
  Box,
} from '@mui/material';
import {useForm, Controller} from 'react-hook-form';
import {MdOutlineClose} from 'react-icons/md';
//import { TextInput} from '../../../components/Input';
import ReactSelect from 'react-select';
import '../../../components/addModal.css';
import { useSelector } from 'react-redux';

const AddModalOperative = ({
    open,
    handleClose,
    submitHandle,
    selectedRecord,
    detail,
}) => {
    const [helperText, setHelperText] = useState('');
    const [dropVal, setDropVal] = useState({
        operativeType: '',
    });
    const {lstAllUserRoles} = useSelector(
      (state) => state.dropdown,
    ).dropdown;

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: 400,
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2.5,
        borderRadius: 1,
    };

    // const employeeType = [
    //     {value: 'Site Manager'},
    //     {value: 'Site Operative'},
    // ];

    const {handleSubmit, control, register, formState, reset, setValue} = useForm({shouldUnregister: true});

    useEffect(() => {
        console.log('modal open');
        if (selectedRecord) {
          for (const [key, value] of Object.entries(selectedRecord)) {
            setValue(key, value, {
              shouldValidate: true,
              shouldDirty: true,
            });
          }
          setDropVal({
            ...dropVal,
            operativeType: lstAllUserRoles?.filter(
              (option) => option?.name == selectedRecord?.role,
            )[0],
          });
        } else {
          reset();
          setDropVal({
            ...dropVal,
            operativeType: '',
          });
        }
    }, [open]);

    const handleClick = (data) => {
        if (selectedRecord) {
            data.userName = camelize(data?.userName);
            submitHandle(data, true);
        } else {
            data.userName = camelize(data?.userName);
            submitHandle(data, false);
        }
    };

    const validatePass = (password) => {
        
        let re = {
          digit: /[0-9]/,
          character: /[!@#$%^&*]/,
          full: /^[A-Za-z0-9]/,
          uppercase: /(?=.*[A-Z])/,
          lowercase: /(?=.*[a-z])/,
        };
    
        if (!re.digit.test(password)) {
          setHelperText('Must contain a number');
          return false;
        } else if (!re.character.test(password)) {
          setHelperText('Must contain a special character');
          return false;
        } else if (!re.uppercase.test(password)) {
          setHelperText('Must contain an Upper Case letter');
          return false;
        } else if (!re.lowercase.test(password)) {
          setHelperText('Must contain a lower case letter');
          return false;
        }else if(password?.length < 8){
          setHelperText('Must contain more than 8 characters');
          return false;
        }else if(password.indexOf('/') > -1){
          setHelperText('Should not contain / or #');
          return false;
        }else if(password.indexOf('#') > -1){
          setHelperText('Should not contain / or #');
          return false;
        }else {
          setHelperText('');
          return true;
        }
    };

    const validateEditPass = (password) => {
        if(password !== ''){
            let re = {
                digit: /[0-9]/,
                character: /[!@#$%^&*]/,
                full: /^[A-Za-z0-9]/,
                uppercase: /(?=.*[A-Z])/,
                lowercase: /(?=.*[a-z])/,
            };
          
              if (!re.digit.test(password)) {
                setHelperText('Must contain a number');
                return false;
              } else if (!re.character.test(password)) {
                setHelperText('Must contain a special character');
                return false;
              } else if (!re.uppercase.test(password)) {
                setHelperText('Must contain an Upper Case letter');
                return false;
              } else if (!re.lowercase.test(password)) {
                setHelperText('Must contain a lower case letter');
                return false;
              }else if(password?.length < 8){
                setHelperText('Must contain more than 8 characters');
                return false;
              }else if(password.indexOf('/') > -1){
                setHelperText('Should not contain / or #');
                return false;
              }else if(password.indexOf('#') > -1){
                setHelperText('Should not contain / or #');
                return false;
              }else {
                setHelperText('');
                return true;
              }
        }else{
            return true;
        }
        
    };
    
      const validateEmail = (value) => {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(value);
      };
    
      const camelize = (str) => {
        if(str){
          return str
          .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
          })
          .replace(/\s+/g, '');
        }else{
          return ''
        }
      };
    
    const closeModal = () => {
        setDropVal({
          operativeType: '',
        });
        handleClose();
    };

    const customStyles = {
        container: (provided) => ({
          ...provided,
          width: 200,
          fontSize: '1.3rem',
          marginTop: '.6rem',
        }),
        option: (provided, {isFocused}) => ({
          ...provided,
          fontSize: '1.3rem',
          backgroundColor: isFocused ? '#329BEF' : 'white',
          color: isFocused ? 'white' : 'black',
          zIndex: 400,
        }),
        menuPortal: (base) => ({...base, zIndex: 9999}),
        control: (styles) => ({...styles, padding: '0.3rem'}),
    };

    return (
        <Modal open={open} onClose={closeModal}>
          <Box sx={style}>
            <Box display="flex" justifyContent="space-between" mb={2}>
              {!selectedRecord ? (
                <Typography variant="h3">Add Operative</Typography>
              ) : (
                <Typography variant="h3">Edit Operative</Typography>
              )}
              <IconButton onClick={closeModal}>
                <MdOutlineClose size="2rem" />
              </IconButton>
            </Box>
            <Divider />
    
            <Box mt={3} mb={2}>
              <form onSubmit={handleSubmit(handleClick)} className="add-form">
                {!detail && (
                  <Box>
                    <Typography variant="h6">Operative Type</Typography>
                    <Controller
                      name="role"
                      control={control}
                      defaultValue=""
                      rules={{required: true}}
                      error={formState.errors?.tag ? true : false}
                      render={({field}) => (
                        <ReactSelect
                          name="role"
                          field={field}
                          required
                          styles={customStyles}
                          options={lstAllUserRoles}
                          getOptionLabel={(option) => option?.name}
                          value={dropVal?.operativeType || ''}
                          placeholder="Select Operative type"
                          defaultValue=""
                          onChange={(e) => {
                            field.onChange(e?.name);
                            setDropVal({...dropVal, operativeType: e});
                          }}
                          //menuPortalTarget={document.getElementById('modal')}
                        />
                      )}
                    />
                    <span className="err-form">
                      {formState.errors?.role
                        ? 'Operative Type is required'
                        : ''}
                    </span>
                  </Box>
                )}
                <Box>
                  <Typography variant="h6">Operative Name</Typography>
                  <TextField
                    {...register(`fullName`, {required: true})}
                    required
                    id="outlined-required"
                    placeholder="Operative Name"
                    fullWidth
                  />
                  <span className="err-form">
                    {formState.errors?.fullName
                      ? 'Operative Name is required'
                      : ''}
                  </span>
                </Box>
                <Box>
                  <Typography variant="h6">Operative Username</Typography>
                  <TextField
                    {...register(`userName`,{
                      required: true,
                      validate: {
                        char: (value) => value.indexOf('/') === -1 || value.indexOf('#') === -1,
                      },
                    })}
                    required
                    id="outlined-required"
                    placeholder="Operative Username"
                    fullWidth
                  />
                  <span className="err-form">
                    {formState.errors?.userName ? 
                      (formState.errors?.userName?.type === 'char'
                      ? `Should not contain / or #`
                      : 'Username is required') :
                      ''
                    }
                  </span>
                </Box>
                {!selectedRecord && (
                    <Box>
                    <Typography variant="h6">Password</Typography>
                    <TextField
                      {...register(`password`, {
                        required: true,
                        validate: {
                          pass: (value) => validatePass(value),
                        },
                      })}
                      required
                      id="outlined-required"
                      placeholder="Password"
                      fullWidth
                    />
                    <span className="err-form">
                    {formState.errors?.password ? 
                        (formState.errors?.password?.type === 'pass'
                        ? `${helperText}`
                        : 'Password is required') :
                        ''
                      }
                    </span>
                  </Box>
                )}
                {selectedRecord && (
                    <Box>
                    <Typography variant="h6">Password</Typography>
                    <TextField
                      {...register(`password`, {
                        required: false,
                        validate: {
                          pass: (value) => validateEditPass(value),
                        },
                      })}
                      id="outlined-required"
                      placeholder="Password"
                      fullWidth
                    />
                    <span className="err-form">
                      {formState.errors?.password ? 
                        (formState.errors?.password?.type === 'pass'
                        ? `${helperText}`
                        : 'Password is required') :
                        ''
                      }
                      {/* {formState.errors?.password?.type === 'pass'
                        ? `${helperText}`
                        : ''} */}
                    </span>
                  </Box>
                )}
                <Box>
                  <Typography variant="h6">Email Id</Typography>
                  <TextField
                    {...register(`email`, {
                      required: true,
                      validate: {
                        mail: (value) => validateEmail(value),
                      },
                    })}
                    required
                    id="outlined-required"
                    placeholder="Email Id"
                    fullWidth
                  />
                  <span className="err-form">
                    {formState.errors?.email ? 
                      (formState.errors?.email?.type === 'mail'
                      ? `Enter valid Email`
                      : 'Email Id is required') :
                      ''
                    }
                    {/* {formState.errors?.email?.type === 'mail'
                      ? `Enter valid Email`
                      : ''} */}
                  </span>
                </Box>
                <Box >
                  <Typography variant="h6">Phone Number</Typography>
                  <TextField
                    {...register(`phoneNumber`, {
                      required: true,
                      validate: {
                        length: (value) => value.length === 10,
                        dataType: (value) => isNaN(parseInt(value)) === false
                      },
                    })}
                    required
                    id="outlined-required"
                    placeholder="Phone Number"
                    fullWidth
                  />
                  <span className="err-form">
                    {formState.errors?.phoneNumber ? 
                      (formState.errors?.phoneNumber?.type === 'length'
                        ? 'Must be 10 digits'
                        : (formState.errors?.phoneNumber?.type === 'dataType' ? 
                            'Must be a number' :
                            'Phone Number is required'
                          )
                      ) :
                      ''
                    }
                  </span>
                </Box>
                <button
                  className="form-button"
                  onClick={handleSubmit(handleClick)}>
                  Save
                </button>
              </form>
            </Box>
          </Box>
        </Modal>
    );
}

export default AddModalOperative;
import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import {Card, CardContent, Grid} from '@mui/material';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export default function Graphs({graph, label, color}) {
  let state = {
    labels: [],
    datasets: [
      {
        label: label,
        backgroundColor: color,
        borderColor: color,
        data: [],
      },
    ],
  };

  const [graphData, setGraphData] = useState(state);


  useEffect(()=>{
    if(graph){
      graph?.map((data)=>{
        state?.labels.push(data?.name );
        state?.datasets[0]?.data.push(data?.cost);
      });
      setGraphData(state);
    }
  }, [graph]);

  return (
    <Grid item xs={12} md={6} sx={{height: '100%'}}>
      <Card
        sx={{
          boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.08);',
          borderRadius: '.4rem',
          border: '1px solid #E0E0E0',
          //minWidth: '60rem',
        }}
      >
        <CardContent sx={{padding: '1rem'}}>
          <div></div>
          <Bar
            data={graphData}
            options={{
              title: {
                display: true,
                text: 'Total Cost per Site',
                fontSize: 20,
              },
              legend: {
                display: true,
                position: 'right',
              },
            }}
          />
        </CardContent>
      </Card>
    </Grid>
  );
}

import React, {useEffect} from 'react';
import {MdOutlineClose} from 'react-icons/md';
import {
    Typography, 
    IconButton,
    TextField,
    Box,
    Dialog,
    DialogContent,
    Divider,
} from '@mui/material';
import {useForm} from 'react-hook-form';
import ProButton from '../../../components/common-comp/ProButton';



function AddJobRole({
    open, 
    handleClose, 
    submitHandle,
    selectedRecord,
}) {

    const {handleSubmit, register, formState, reset, setValue} =
    useForm({shouldUnregister: true});

  const handleClick = (data) => {
    console.log('handleClick');
    if (selectedRecord) {
      submitHandle(data, true);
    } else {
      submitHandle(data, false);
    }
  };

  const closeModal = () => {
    handleClose();
  };

  useEffect(() => {
    console.log('modal job role open');
    if (selectedRecord) {
      for (const [key, value] of Object.entries(selectedRecord)) {
        setValue(key, value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    } else {
      reset();
    }
  }, [open]);

  useEffect(() => {
    // <---for Edit Operative Modal
    console.log('');
    if (selectedRecord) {
      for (const [key, value] of Object.entries(selectedRecord)) {
        setValue(key, value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    }

  }, [selectedRecord]);

  useEffect(() => {
    reset();
  }, []);


  return (
    <Dialog maxWidth="sm" open={open}>
      <DialogContent dividers>
      <Box sx={{width:'300px'}}>
          <Box display="flex" justifyContent="space-between" mb={2}>
            {/* {!selectedRecord ? (
              <Typography variant="h4">Create Job Role</Typography>
            ) : (
              <Typography variant="h4">Edit Job Role</Typography>
            )} */}
            <IconButton onClick={closeModal} sx={{marginLeft:'auto'}}>
              <MdOutlineClose size="2rem" />
            </IconButton>
          </Box>

          <Box mt={3} mb={2}>
            <form onSubmit={handleSubmit(handleClick)} className="add-form">
              
              <Box className="rate-card-modal">
                <Typography variant="h6">Job Role Name</Typography>
                <TextField
                  {...register(`jobRole`,{required: true})}
                  required
                  id="outlined-required"
                  placeholder="Job Role Name"
                  fullWidth
                />
                <span className="err-form">
                  {formState.errors?.jobRole ? 
                    'Job Role Name is required' :
                    ''
                  }
                </span>
              </Box>
              <Divider />
              {/* <button
                className="form-button"
                onClick={handleSubmit(handleClick)}>
                Save
              </button> */}
              <ProButton
                text={selectedRecord ? 'Edit Job Role' : 'Create Job Role'}
                color="#cb272f"
                handleClick={handleSubmit(handleClick)}
                variant="contained"
              />
            </form>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default AddJobRole;

import React, {useEffect, useState, useRef} from 'react';
import ReactSelect from 'react-select';
import { useParams, useNavigate } from 'react-router-dom';
import {Box, Grid, TextField, Typography} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';

import {
  getTimesheet,
  updateTimesheet,
  submitTimesheet,
  timesheetsActions,
} from '../../../data/reducers/app/timesheets.reducer';

import ConfirmationDialog from '../../../components/ConfirmationDialog';

import '../../../components/common-comp/CommonComp.css';
import 'react-datepicker/dist/react-datepicker.css';
import { removeSeconds } from '../../../utils/time';
import './DashDetails.css';
import DataTable from '../../../components/dataTable/DataTable';
import ProButton from '../../../components/common-comp/ProButton';
import Navigation from '../../../components/navigation/Navigation';
import { convertToPounds } from '../../../utils/currency';
//import DatePicker from 'react-datepicker';

function DashDetail() {
  const editRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {id} = useParams();

  const [responseData, setResponseData] = useState(null);
  const [editData, setEditData] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [updatedData, setUpdatedData] = useState([]);
  const [tempData, setTempData] = useState(null);
  const [dropVal, setDropVal] = useState([]);
  const [bankdropVal, setBankDropVal] = useState([]);

  const {timesheetDetail, error, message} = useSelector(
    (state) => state.timesheets,
  );

  let nightShiftOptions = [
    {label: 'Yes', value: true},
    {label: 'No', value: false},
  ];

  const role = JSON.parse(localStorage.getItem('user'))?.user?.role;

  useEffect(() => {
    setConfirmPopup(false);
    if (error) {
      toast.error(error);
    } else if (message) {
      toast.success(message);
    }
  }, [error, message]);

  useEffect(() => {
    setConfirmPopup(false);
    setResponseData(timesheetDetail?.timeSheetList);
    setTempData(timesheetDetail?.timeSheetList);
    let temp = [];
    let bank = [];
    timesheetDetail?.timeSheetList?.map((row, index) => {
      temp[index] = row?.nightShift?.toString();
      bank[index] = row?.bankHoliday?.toString();
    });
    setDropVal([...temp]);
    setBankDropVal([...bank]);
  }, [timesheetDetail]);

  useEffect(()=>{
    handleFetch();
  },[id]);


  const handleFetch = async () => {
    //setEditData(false);
    dispatch(timesheetsActions.timesheetsReset());
    setTempData(null)
    setConfirmPopup(false);
    setUpdatedData([]);

    {role === 'Admin' ? setEditData(true) : setEditData(false)}

    dispatch(
      getTimesheet({
        endpoint: `Timesheets/get-timesheet/${id}`,
      }),
    );
  };

  const handleDate = (dateVal) => {
    var date = new Date(dateVal);
    var print =
      
      (date.getMonth() > 8
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)) +
      '-' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate())+
      '-' +
      date.getFullYear();
    return `${print}`;
  };

  const handleEdit = () => {
    setEditData(true);
  };

  const handleSubmit = () => {
    if (editData) {
      addRecord(updatedData);
    } else {
      setConfirmPopup(true);
    }
  };

  const validateHhMm = (inputField) =>{
    let hrs=null; let mins=null;
    if(inputField){
      var sHours = inputField.split(':')[0];
      var sMinutes = inputField.split(':')[1];
      
      if(sHours == '' || isNaN(sHours) || (parseInt(sHours) > 23 )) {
        console.log('inside validate if hours')
        hrs = false;
      }else{
        hrs = true;
      }

      if(sMinutes == '' || isNaN(sMinutes) || parseInt(sMinutes) > 59) {
        console.log('inside validate if mins')
        mins = false;
      }else{
        mins = true
      }
    }
    
    if(hrs && mins){
      return true;
    }else{
      return false;
    }
  }

  const addRecord = async (data) => {
    if (editData) {
      if (updatedData?.length > 0) {
        let invalidTime;
        let checkStartTime;
        let checkFinishTime;
        let checkBreak;

        data?.map((obj) => {
          if(!obj?.nightShift){
            if (
              Date.parse('1/1/1999 ' + obj?.startTime) >
              Date.parse('1/1/1999 ' + obj?.finishTime)
            ) {
              invalidTime=true;
            }
          }
          checkStartTime = validateHhMm(obj?.startTime);
          checkFinishTime = validateHhMm(obj?.finishTime);
          checkBreak = validateHhMm(obj?.break);
        });

        if (invalidTime) {
          toast.error('Start time cannot be greater than Finish time');
        }else if(!checkStartTime || !checkFinishTime || !checkBreak){
          toast.error('Please enter proper time values.');
        }else {

          const dataToSend = {
            timeSheetList: data,
          };
          dispatch(
            updateTimesheet({
              endpoint: `/TimeSheets/update-timesheet`,
              data: dataToSend,
            }),
          )?.then((res)=>{
            {res?.type === 'timesheets/updateTimesheet/fulfilled' && 
              dispatch(timesheetsActions.timesheetsReset());
              if(role==='Admin'){
                handleFetch()
              }
              {role?.includes('Operative') && handleFetch()}
            }
          })
        }
      }else{
        toast.error('Please fill the timesheet before saving')
      }
    } else {
      const action = role?.includes('Operative') ? 'submit' : 'approve';

      dispatch(
        submitTimesheet({
          endpoint: `/TimeSheets/submit-timesheet/${id}?action=${action}`,
        }),
      )?.then((res)=>{
        {res?.type === 'timesheets/submitTimesheet/fulfilled' && 
          dispatch(timesheetsActions.timesheetsReset());
          navigate('my-timesheets');
        }
      })
    }
  };

  const updateRow = (changes, field, index) => {
    let temp = JSON.parse(JSON.stringify(tempData));
    //let previousData = temp;
    temp[index][field] = changes;

    setTempData([...temp]);
    // eslint-disable-next-line no-debugger
    //debugger;

    let dataIndex = updatedData?.findIndex((d) => {
      d.id === tempData[index]?.id;
    });

    if (dataIndex === -1) {
      if(tempData[index][field] !== changes) {
        let checkIndex = null;
        let existingIndex = null;
        updatedData?.map((obj, i) => {
          if (obj.date == temp[index].date) {
            checkIndex = true;
            existingIndex = i;
          }
        });
        if(!checkIndex) {
          setUpdatedData([...updatedData, temp[index]]);
        } else {
          updatedData[existingIndex] = temp[index];
        }
      }
    } else {
      updatedData[dataIndex] = temp[index];
    }
    setTempData([...temp]);
  };

  const customStyles = {
    container: (provided) => ({
      ...provided,
      minWidth: 90,
      fontSize: '1.3rem',
    }),
    option: (provided, {isFocused}) => ({
      ...provided,
      fontSize: '1.3rem',
      backgroundColor: isFocused ? '#329BEF' : 'white',
      color: isFocused ? 'white' : 'black',
      zIndex: 400,
    }),
  };

  const checkDate = (index) =>{
    if(Date.parse(new Date(tempData[index]?.date)) > Date.parse(new Date())){
      return true
    }else{
      return false
    }
  }

  const tableCols = [
    {
      name: 'date',
      label: 'Date',
      options: {
        customBodyRender: (value, tableMeta) => {
          return <div>{`${handleDate(value)}, ${tempData[tableMeta.rowIndex].day}`}</div>;
        },
      },
    },
    {
      name: 'startTime',
      label: 'Start Time',
      options: {
        customBodyRender: (value, tableMeta) => {
          //var sHours = value.split(':')[0]
          //var sMinutes = value.split(':')[1];
          //var invalid = parseInt(sHours) > 23 || parseInt(sHours) < 1 ? true : false;

          if (editData) {
            return (
              <TextField
                onChange={(e) => {
                  let temp = e.target.value;
                  let hours = temp.split(':')[0];
                  let mins = temp.split(':')[1];
                  if(parseInt(hours) < 10) { temp = `0${parseInt(hours)}:${mins}` }
                  updateRow(
                    temp,
                    'startTime',
                    tableMeta.rowIndex,
                  );
                }}
                defaultValue={value}
                sx={{minWidth:'7rem'}}
                disabled = {checkDate(tableMeta.rowIndex) ? true : false}
              />
            );
          } else {
            return <div>{value}</div>;
          }
        },
      },
    },
    {
      name: 'finishTime',
      label: 'Finish Time',
      options: {
        customBodyRender: (value, tableMeta) => {
          if (editData) {

            return (
              <TextField
                onChange={(e) => {
                  let temp = e.target.value;
                  let hours = temp.split(':')[0];
                  let mins = temp.split(':')[1];
                  if(parseInt(hours) < 10) { temp = `0${parseInt(hours)}:${mins}` }
                  updateRow(
                    temp,
                    'finishTime',
                    tableMeta.rowIndex,
                  );
                }}
                sx={{minWidth:'7rem'}}
                defaultValue={value}
                disabled = {checkDate(tableMeta.rowIndex) ? true : false}
              />
            );
          } else {
            return <div>{value}</div>;
          }
        },
      },
    },
    {
      name: 'break',
      label: 'Breaks',
      options: {
        customBodyRender: (value, tableMeta) => {
          if (editData) {
            return (
              <TextField
                onChange={(e) => {
                  updateRow(e.target.value, 'break', tableMeta.rowIndex);
                }}
                sx={{minWidth:'7rem'}}
                defaultValue={removeSeconds(value)}
                disabled = {checkDate(tableMeta.rowIndex) || tempData[tableMeta.rowIndex]?.startTime === '00:00' || tempData[tableMeta.rowIndex]?.finishTime === '00:00'? true : false}
              />
            );
          } else {
            return <div>{removeSeconds(value)}</div>;
          }
        },
      },
    },
    {
      name: 'nightShift',
      label: 'Night Shift',
      options: {
        customBodyRender: (_, tableMeta) => {
          return (
            <ReactSelect
              name="nightShift"
              styles={customStyles}
              options={nightShiftOptions}
              value={nightShiftOptions?.filter(
                (val) =>
                  val.value === tempData[tableMeta.rowIndex]?.nightShift,
              )}
              hideSelectedOptions={false}
              isDisabled={editData !== true || checkDate(tableMeta.rowIndex)}
              getOptionLabel={(option) => option?.label}
              onChange={(e) => {
                let temp = [...dropVal];
                temp[tableMeta.rowIndex] = e.value;
                setDropVal([...temp]);
                updateRow(e.value, 'nightShift', tableMeta.rowIndex);
              }}
              menuPortalTarget={document.body}
            />
          );
        },
      },
    },
    {
      name: 'bankHoliday',
      label: 'Bank Holiday',
      options: {
        customBodyRender: (_, tableMeta) => {
          return (
            <ReactSelect
              name="bankHoliday"
              styles={customStyles}
              options={nightShiftOptions}
              value={nightShiftOptions?.filter(
                (val) =>
                  val.value == tempData[tableMeta.rowIndex]?.bankHoliday,
              )}
              hideSelectedOptions={false}
              isDisabled={editData !== true || checkDate(tableMeta.rowIndex)}
              getOptionLabel={(option) => option?.label}
              onChange={(e) => {
                let bank = [...bankdropVal];
                bank[tableMeta.rowIndex] = e.value;
                setBankDropVal([...bank]);
                updateRow(e.value, 'bankHoliday', tableMeta.rowIndex);
              }}
              menuPortalTarget={document.body}
            />
          );
        },
      },
    },
    {
      name: 'totalHoursWorked',
      label: 'Hours Worked',
      options: {
        customBodyRender: (value) =>{
          return <div>{removeSeconds(value)}</div>;
        }
      }
    },
  ];

  const handleSaveSubmit = async() =>{
    const data = updatedData;
    if (editData) {
      if (updatedData?.length > 0) {
        let invalidTime;
        let checkStartTime;
        let checkFinishTime;

        data?.map((obj) => {
          if(!obj?.nightShift){
            if (
              Date.parse('1/1/1999 ' + obj?.startTime) >
              Date.parse('1/1/1999 ' + obj?.finishTime)
            ) {
              invalidTime=true;
            }
          }
          checkStartTime = validateHhMm(obj?.startTime);
          checkFinishTime = validateHhMm(obj?.finishTime);
          
        });

        if (invalidTime) {
          toast.error('Start time cannot be greater than Finish time');
        }else if(!checkStartTime || !checkFinishTime){
          toast.error('Please enter proper time values.');
        }else {

          const dataToSend = {
            timeSheetList: data,
          };
          await dispatch(
            updateTimesheet({
              endpoint: `/TimeSheets/update-timesheet`,
              data: dataToSend,
            }),
          );
          
          await dispatch(
            submitTimesheet({
              endpoint: `/TimeSheets/submit-timesheet/${id}?action=approve`,
            }),
          );

          if(!error){
            dispatch(timesheetsActions.timesheetsReset());
            {role==='Admin' && navigate('/timesheets')}
            {role==='Site Operative' && await handleFetch()}
          }
          
        }
      }else{
        toast.error('Please fill the timesheet before saving')
      }
    }
  }

  {role === 'Admin' && (
    tableCols?.push({
      name: 'bonus',
      label: 'Bonus',
      options:{
        customBodyRender: (value, tableMeta) => {
          if (editData) {
            return (
              <TextField
                onChange={(e) => {
                  updateRow(e.target.value, 'bonus', tableMeta.rowIndex);
                }}
                defaultValue={value}
                sx={{minWidth:'5rem', maxWidth:'10rem'}}
              />
            );
          } else {
            return <div>{convertToPounds(value)}</div>;
          }
        }
      }
    })
  )}
  {role === 'Admin' && (
    tableCols?.push({
      name: 'cost',
      label: 'Cost',
      options:{
        customBodyRender: (value) => {
          return <div>{convertToPounds(value)}</div>
        }
      }
    })
  )}

  let navigation = [];
  {role !== 'Admin' && (
    navigation = [
      {
        name: ' My Timesheets /',
        path: '/my-timesheets'
      },
      {
        name: ' Edit ',
      },
    ]
  )}
  {role === 'Admin' && (
    navigation = [
      {
        name: ' Timesheets /',
        path: '/timesheets'
      },
      {
        name: ' Edit Operative Timesheet ',
      },
    ]
  )}

  return (
    <div className='admin'>
      <Box className="page-container" mt={1}>
        {!error && role==='Site Operative' && timesheetDetail && timesheetDetail?.approvalStatus === 'Not Submitted' && (
          <Box className="banner banner-error">
            You haven&apos;t submitted this week&apos;s timesheet.
          </Box>
        )}
        {!error && role==='Site Operative' && timesheetDetail  && timesheetDetail?.approvalStatus !== 'Not Submitted' && (
          <Box className="banner banner-success">
            Timesheets for this week are submitted.
          </Box>
        )}
        
        <Navigation 
          path={navigation} 
        />
         <Box className="section-top">
          <Grid container spacing={4} justifyContent="space-between">
            <Grid item xl={5} sm={6} xs={12}>
              <Box className="elevated-white" >
                <Typography fontSize='1.8rem' fontWeight='600' textAlign="center" width='40%' >
                  Selected Week :
                </Typography>
                <Box component="span" sx={{ p: 1, border: '1px solid #E0E0E0', fontSize: '1.3rem' }} >
                  {`${timesheetDetail?.startDate ? handleDate(timesheetDetail?.startDate) : handleDate(new Date())}
                    -
                    ${timesheetDetail?.endDate ? handleDate(timesheetDetail?.endDate) : handleDate(new Date())} 
                  `}
                </Box>
              </Box>
            </Grid>
            <Grid item xl={5} sm={6} xs={12}>
              <Box className="elevated-white">
                <Typography fontSize='1.8rem' fontWeight='600' textAlign="center" width='40%' >
                  Assigned Site Code :
                </Typography>
                <Box component="span" sx={{ p: 1 , border: '1px solid #E0E0E0', fontSize: '1.3rem' }} >
                  {`${timesheetDetail?.siteCode}`}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box> 
        
        <Box className="section-info" padding={2} borderRadius={1}>
          <Grid container columnSpacing={12} rowSpacing={1} mt={2}>
            <Grid item sm={6}>
              <Grid container columnSpacing={2}>
                <Grid item md={6}>
                  <Typography variant="h6" fontWeight="bold">
                    Site Manager Name
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography
                    variant="h6"
                    fontWeight="light"
                    textAlign="right">
                    {timesheetDetail?.siteManagerName || '-'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6}>
              <Grid container columnSpacing={2}>
                <Grid item md={6}>
                  <Typography variant="h6" fontWeight="bold">
                    Site Code
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography
                    variant="h6"
                    fontWeight="light"
                    textAlign="right">
                    {timesheetDetail?.siteCode}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6}>
              <Grid container columnSpacing={2}>
                <Grid item md={6}>
                  <Typography variant="h6" fontWeight="bold">
                    Site Name
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography
                    variant="h6"
                    fontWeight="light"
                    textAlign="right">
                    {timesheetDetail?.siteName}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6}>
              <Grid container columnSpacing={2}>
                <Grid item md={6}>
                  <Typography variant="h6" fontWeight="bold">
                    Job Role
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography
                    variant="h6"
                    fontWeight="light"
                    textAlign="right">
                    {timesheetDetail?.jobRole}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6}>
              <Grid container columnSpacing={2}>
                <Grid item md={6}>
                  <Typography variant="h6" fontWeight="bold">
                    Site Address
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography
                    variant="h6"
                    fontWeight="light"
                    textAlign="right">
                    {timesheetDetail?.siteAddress}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6}>
              <Grid container columnSpacing={2}>
                <Grid item md={6}>
                  <Typography variant="h6" fontWeight="bold">
                    Permitted Hours
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography
                    variant="h6"
                    fontWeight="light"
                    textAlign="right">
                    {removeSeconds(timesheetDetail?.permittedHours)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6}>
              <Grid container columnSpacing={2}>
                <Grid item md={6}>
                  <Typography variant="h6" fontWeight="bold">
                    Rate Card
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography
                    variant="h6"
                    fontWeight="light"
                    textAlign="right">
                    {timesheetDetail?.rateCardName}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          {role==='Admin' && (
              <>
                <Grid item  sm={6}>
                  <Grid container columnSpacing={2}>
                    <Grid item md={6}>
                      <Typography variant="h6" fontWeight="bold">
                        Overtime Rate
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography
                        variant="h6"
                        fontWeight="light"
                        textAlign="right">
                        {convertToPounds(timesheetDetail?.overtimeRate)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item  sm={6}>
                  <Grid container columnSpacing={2}>
                    <Grid item md={6}>
                      <Typography variant="h6" fontWeight="bold">
                        Regular Rate
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography
                        variant="h6"
                        fontWeight="light"
                        textAlign="right">
                        {convertToPounds(timesheetDetail?.regularRate)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item  sm={6}>
                  <Grid container columnSpacing={2}>
                    <Grid item md={6}>
                      <Typography variant="h6" fontWeight="bold">
                        Night Shift Rate
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography
                        variant="h6"
                        fontWeight="light"
                        textAlign="right">
                        {convertToPounds(timesheetDetail?.nightShiftRate)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Box>

        <Box className="bottom-buttons">
          {editData && (
            <Box>
              <ProButton
                text="Cancel"
                color="#cb272f"
                handleClick={() => role==='Admin' ? navigate('/timesheets') : handleFetch()}
                variant="outlined"
              />
            </Box>
          )}
            {editData && role?.includes('Operative') && (
              <ProButton
                text="Save"
                color="#6eb066"
                handleClick={handleSubmit}
                variant="contained"
              />
            )}
            
            {editData && role==='Admin' && tempData && (
              <>
                <ProButton
                  text="Save"
                  color="#cb272f"
                  handleClick={handleSubmit}
                  variant="contained"
                  
                />
                <ProButton
                  text='Save and Approve'
                  color="#6eb066"
                  handleClick={handleSaveSubmit}
                  variant="contained"
                  
                />
              </>
            )}
              <button
                className="sec-outlined"
                ref={editRef}
                style={{display: editData ? 'none' : 'block'}}
                onClick={handleEdit}>
                Edit
              </button>
              <button
                className="contained"
                style={{display: editData ? 'none' : 'block',}}
                onClick={handleSubmit}>
                {`${
                timesheetDetail?.approvalStatus === 'Not Submitted'
                  ? 'Submit for approval'
                  : 'Submit for re-approval'
                }`}
              </button>
        </Box>

        <Box className="section-table">
          <DataTable
            newCols={tableCols}
            newData={tempData ? tempData : []}
            pagination={false}
          />
        </Box>
      </Box>
      <ConfirmationDialog
        open={confirmPopup}
        text="Once submitted you cannot edit your timesheet. Are you sure you want to submit?"
        handleClose={() => setConfirmPopup(false)}
        submitHandle={() => {
          addRecord(responseData);
          setConfirmPopup(false);
        }}
      />
    </div>
  );
}

export default DashDetail;

export const tableData = {
  operatives: {
    columns: [
      {
        name: 'fullName',
        label: 'Operative Name',
        link: true,
      },
      {
        name: 'role',
        label: 'Operative Type',
        noSort: true,
      },
      {
        name: 'email',
        label: 'Email Id',
      },
      {
        name: 'phoneNumber',
        label: 'Phone Number',
      },
    ],
  },
  rateCards: {
    columns: [
      // {
      //   name: 'id',
      //   label: 'Sr. No.',
      // },
      {
        name: 'name',
        label: 'Name',
        link: true,
      },
      {
        name:'jobRoleCount',
        label: 'Job Role Count',
        noSort: true,
      },
      {
        name: 'createdOn',
        label: 'Date Created',
        date: true,
      },
    ],
  },
  operativeDash: {
    columns: [
      {
        name: 'date',
        label: 'Date',
        date: true,
      },
      {
        name: 'startTime',
        label: 'Start Time',
      },
      {
        name: 'finishTime',
        label: 'Finish Time',
      },
      {
        name: 'break',
        label: 'Breaks',
      },
      {
        name: 'nightShift',
        label: 'Night Shift',
        dropdown: true,
      },
      {
        name: 'bankHoliday',
        label: 'Bank Holiday',
        dropdown: true,
      },
      {
        name: 'totalHoursWorked',
        label: 'Hours Worked',
      },
    ],
  },
  timesheetsOperative: {
    columns: [
      {
        name:'startDate',
        label:'Week',
        week:true,
      },
      {
        name: 'siteName',
        label: 'Site Name',
        link: true,
      },
      {
        name: 'jobRole',
        label: 'Job Role',
      },
      {
        name: 'totalHoursWorked',
        label: 'Hours Worked',
      },
      {
        name: 'approvalStatus',
        label: 'Approval Status',
        status: true,
      },
    ],
  },
  timesheets: {
    columns: [
      {
          name:'startDate',
          label:'Week',
          week:true,
      },
      {
        name: 'operativeName',
        label: 'Operative Name',
        link: true,
      },
      {
        name: 'siteName',
        label: 'Site Name',
      },
      {
        name: 'siteCode',
        label: 'Site Code',
      },
      {
        name: 'siteAddress',
        label: 'Site Address',
      },
      {
        name: 'jobRole',
        label: 'Job Role',
      },
      {
        name: 'totalHoursWorked',
        label: 'Hours Worked',
      },
      {
        name: 'approvalStatus',
        label: 'Approval Status',
        status: true,
      },
    ],
  },
  sites: {
    columns: [
      {
        name: 'siteName',
        label: 'Site Name',
        link: true,
      },
      {
        name: 'siteCode',
        label: 'Site Code',
      },
      {
        name: 'siteAddress',
        label: 'Site Address',
        long: true,
      },
      {
        name: 'permittedHours',
        label: 'Permitted Hours',
        time: true,
      },
      {
        name: 'operativeCount',
        label: 'Operative Count',
        noSort: true,
      },
    ],
  },
};

import React from 'react';
import {useNavigate} from 'react-router-dom';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Box} from '@mui/material';

import './navigation.css';

function Navigation({path}) {
  const navigate = useNavigate();

  return (
    <Box className="flex">
      <IconButton
        onClick={() => {
          navigate(-1);
        }}>
        <ArrowBackIcon color="error" fontSize="large" />
      </IconButton>
      <Box className="path">
        <Link to='/' className="dark">Home /</Link>
        {/* <span className="light"> &nbsp;{path}</span> */}
        {path?.map((link, i)=>{
          if(link?.path){
            return(
              <Link to={link?.path} className='light link' key={i}>{link?.name}</Link>
            )
          }else{
            return(
              <span className='light' key={i}>{link?.name}</span>
            )
          }
        })}
      </Box>
    </Box>
  );
}

export default Navigation;

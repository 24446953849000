import {Button} from '@mui/material';
import React from 'react';

import './ProButton.css';

const ProButton = ({handleClick, color, variant, text, mx0}) => {
  return (
    <Button
      className={`pro-button ${mx0 ? 'pro-button-mx-0' : ''}`}
      variant={variant}
      onClick={handleClick}
      style={{
        color: variant === 'outlined' ? color : 'white',
        borderColor: variant === 'outlined' ? color : 'white',
        backgroundColor: variant !== 'outlined' ? color : 'white',
      }}
      disableElevation
      //disableTouchRipple
      >
      {text}
    </Button>
  );
};

export default ProButton;

import axios from 'axios';
import {constants} from '../../../constants/config';
import axiosInstance from '../../../utils/apiHandler/axiosInstance';

let url = constants.BASE_URL;

function authHeader() {
  let user = JSON.parse(localStorage.getItem('user'));
  if (user && user.accessToken) {
    return {Authorization: 'Bearer ' + user.accessToken};
  } else {
    return {};
  }
}

const login = async (data) => {
  try {
    const response = await axiosInstance.post(
      'Authentication/login',
      data,
    );

    return response.data;
  } catch (error) {
    return {error: error.response.data.message};
  }
};

const register = async (data) => {
  try {
    const response = await axios.post(
      url + 'Authentication/register-operative',
      data,
      {headers: authHeader()},
    );

    return response.data;
  } catch (error) {
    return {error: error.response.data.message};
  }
};

export const authServices = {
  login,
  register,
};

import axiosInstance from '../../../utils/apiHandler/axiosInstance';

const getAll = async (payload) => {
  try {
    const response = await axiosInstance.get(payload.endpoint);
    return response.data;
  } catch (error) {
    console.log(error);
    return {error: error.response.data.message};
  }
};

const deleteRateCard = async (payload) => {
  try {
    const response = await axiosInstance.delete(payload?.endpoint);
    return response.data;
  } catch (error) {
    return {error: error.response.data.message};
  }
};

const getSingleRateCard = async (payload) => {
  try {
    const response = await axiosInstance.get(payload?.endpoint);
    return response.data;
  } catch (error) {
    return {error: error.response.data.message};
  }
};

const updateRateCard = async (payload) => {
  try {
    const response = await axiosInstance.put(
      payload.endpoint,
      payload.data,
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return {error: error.response.data.message};
  }
};

const saveRateCard = async (payload) => {
  try {
    const response = await axiosInstance.post(
      payload.endpoint,
      payload.data,
    );
    return response.data;
  } catch (error) {
    return {error: error.response.data.message};
  }
};

//---Job Rate---
const deleteJobRate = async (payload) => {
  try {
    const response = await axiosInstance.delete(payload?.endpoint);
    return response.data;
  } catch (error) {
    return {error: error.response.data.message};
  }
};

const createJobRate = async (payload) => {
  try {
    const response = await axiosInstance.post(
      payload.endpoint,
      payload.data,
    );
    return response.data;
  } catch (error) {
    return {error: error.response.data.message};
  }
};
const updateJobRate = async (payload) => {
  try {
    const response = await axiosInstance.put(
      payload.endpoint,
      payload.data,
    );
    return response.data;
  } catch (error) {
    return {error: error.response.data.message};
  }
};

//---Job Role---

const deleteJobRole = async (payload) => {
  try {
    const response = await axiosInstance.delete(payload?.endpoint);
    return response.data;
  } catch (error) {
    return {error: error.response.data.message};
  }
};

const updateJobRole = async (payload) => {
  try {
    const response = await axiosInstance.put(
      payload.endpoint,
      payload.data,
    );
    return response.data;
  } catch (error) {
    return {error: error.response.data.message};
  }
};

const createJobRole = async (payload) => {
  try {
    const response = await axiosInstance.post(
      payload.endpoint,
      payload.data,
    );
    return response.data;
  } catch (error) {
    return {error: error.response.data.message};
  }
};


export const rateCardsService = {
  getAll,
  updateRateCard,
  saveRateCard,
  deleteRateCard,
  getSingleRateCard,
  deleteJobRate,
  createJobRate,
  updateJobRate,
  createJobRole,
  updateJobRole,
  deleteJobRole,
};

import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Modal, IconButton, Box, Typography, Divider} from '@mui/material';
import {useForm, Controller} from 'react-hook-form';
import ReactSelect from 'react-select';
import {MdOutlineClose} from 'react-icons/md';

import {getAll} from '../../../data/reducers/app/dropdown.reducer';

import ProButton from '../../../components/common-comp/ProButton';

import '../../../components/addModal.css';

function AddOperativeModal({
  open,
  handleClose,
  submitHandle,
  selectedRecord,
}) {
  const [jobRolesOptions, setJobRolesOptions] = useState([]);
  const dispatch = useDispatch();
  const [clearDrop, setClearDrop] = useState(false);
  const [dropVal, setDropVal] = useState({
    jobRole: '',
    rateCard: '',
    operative: '',
  });

  const {dropdown} = useSelector((state) => state.dropdown);
  const {
    lstActiveOperativesList,
    lstActiveRateCardJobRoles,
    lstActiveRateCards,
  } = dropdown;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 400,
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2.5,
    borderRadius: 1,
  };

  const {handleSubmit, control, formState, reset, setValue} = useForm({
    shouldUnregister: true,
  });

  const validateSubmit = (data) => {
    submitHandle(data, false);
  };

  const updateOperative = (data) => {
    submitHandle(data, true);
  };

  useEffect(() => {
    if (selectedRecord) {
      for (const [key, value] of Object.entries(selectedRecord)) {
        setValue(key, value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
      setClearDrop(false);
      setDropVal({
        ...dropVal,
        operative: lstActiveOperativesList?.filter(
          (option) => option.id == selectedRecord?.operativeId,
        )[0],
        rateCard: lstActiveRateCards?.filter(
          (option) => option.rateCardId == selectedRecord?.rateCardId,
        )[0],
        jobRole: lstActiveRateCardJobRoles?.filter(
          (option) => option.id == selectedRecord?.jobRoleId,
        )[0],
      });
    } else {
      reset();
      setDropVal({
        ...dropVal,
        jobRole: '',
        rateCard: '',
        operative: '',
      });
      handleFetch();
    }
  }, [open]);

  const handleFetch = async () => {
    await dispatch(getAll());
    setClearDrop(false);
  };

  useEffect(() => {
    // <---for Edit Operative Modal
    if (selectedRecord) {
      for (const [key, value] of Object.entries(selectedRecord)) {
        setValue(key, value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    }

    setDropVal({
      ...dropVal,
      operative: lstActiveOperativesList?.filter(
        (option) => option.id == selectedRecord?.operativeId,
      )[0],
      rateCard: lstActiveRateCards?.filter(
        (option) => option.rateCardId == selectedRecord?.rateCardId,
      )[0],
      jobRole: lstActiveRateCardJobRoles?.filter(
        (option) => option.id == selectedRecord?.jobRoleId,
      )[0],
    });
  }, [selectedRecord]);

  useEffect(() => {
    reset();
    handleFetch();
  }, []);

  useEffect(() => {
    let options = lstActiveRateCardJobRoles?.filter(
      (option) => option?.rateCardId == dropVal?.rateCard?.rateCardId,
    );
    setJobRolesOptions(options);
    if (clearDrop) {
      setDropVal({...dropVal, jobRole: ''});
      setClearDrop(false);
    }
  }, [dropVal?.rateCard]);

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: 200,
      fontSize: '1.3rem',
      marginTop: '.6rem',
    }),
    option: (provided, {isFocused}) => ({
      ...provided,
      fontSize: '1.3rem',
      backgroundColor: isFocused ? '#329BEF' : 'white',
      color: isFocused ? 'white' : 'black',
      zIndex: 400,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 900,
    }),
    control: (styles) => ({ ...styles, padding: '0.3rem' }),
    menuList: base => ({
      ...base,
      maxHeight: "200px" // your desired height
    }),
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box id="modal">
        <Box sx={style}>
          <Box display="flex" justifyContent="space-between" mb={2}>
            {!selectedRecord ? (
              <Typography variant="h3">Add Operative</Typography>
            ) : (
              <Typography variant="h3">Edit Operative</Typography>
            )}
            <IconButton onClick={handleClose}>
              <MdOutlineClose size="2rem" />
            </IconButton>
          </Box>
          <Divider />

          <Box mt={3} mb={2}>
            {!selectedRecord && (
              <form
                onSubmit={handleSubmit(validateSubmit)}
                id="form-form"
                className="add-form">
                <Box>
                  <Typography variant="h6">Operative Name</Typography>
                  <Controller
                    name="operativeId"
                    control={control}
                    defaultValue=""
                    rules={{required: true}}
                    error={formState.errors?.tag ? true : false}
                    render={({field}) => (
                      <ReactSelect
                        name="operativeId"
                        field={field}
                        required
                        styles={customStyles}
                        placeholder="Select Operative Name"
                        options={lstActiveOperativesList}
                        getOptionLabel={(option) => option?.operativeName}
                        value={dropVal?.operative || ''}
                        menuPlacement="bottom"
                        defaultValue=""
                        onChange={(e) => {
                          field.onChange(e?.id);
                          setDropVal({...dropVal, operative: e});
                        }}
                        //menuPortalTarget={document.getElementById('modal')}
                      />
                    )}
                  />
                  <span className="err-form">
                    {formState.errors?.operativeId ? 
                      'Operative Name is required' :
                      ''
                    }
                  </span>
                </Box>

                <Box>
                  <Typography variant="h6">Rate Card</Typography>
                  <Controller
                    name="rateCardId"
                    control={control}
                    defaultValue=""
                    rules={{required: true}}
                    error={formState.errors?.tag ? true : false}
                    render={({field}) => (
                      <ReactSelect
                        name="rateCardId"
                        field={field}
                        required
                        styles={customStyles}
                        menuPlacement="bottom"
                        placeholder="Select Rate Card"
                        options={lstActiveRateCards}
                        getOptionLabel={(option) => option?.rateCardName}
                        value={dropVal?.rateCard || ''}
                        defaultValue=""
                        onChange={(e) => {
                          field.onChange(e?.rateCardId);
                          setClearDrop(true);
                          setDropVal({...dropVal, rateCard: e});
                        }}
                        //menuPortalTarget={document.getElementById('modal')}
                      />
                    )}
                  />
                  <span className="err-form">
                    {formState.errors?.rateCardId ? 
                      'Rate Card is required' :
                      ''
                    }
                  </span>
                </Box>

                <Box>
                  <Typography variant="h6">Job Role</Typography>
                  <Controller
                    name="jobRoleId"
                    control={control}
                    defaultValue=""
                    rules={{required: true}}
                    error={formState.errors?.tag ? true : false}
                    render={({field}) => (
                      <ReactSelect
                        name="jobRoleId"
                        field={field}
                        required
                        options={jobRolesOptions}
                        getOptionLabel={(option) => option?.jobRoleName}
                        value={dropVal?.jobRole || ''}
                        defaultValue=""
                        menuPlacement="bottom"
                        styles={customStyles}
                        placeholder="Select Job Role"
                        onChange={(e) => {
                          field.onChange(e?.id);
                          setDropVal({...dropVal, jobRole: e});
                        }}
                        //menuPortalTarget={document.getElementById('modal')}
                      />
                    )}
                  />
                  <span className="err-form">
                    {formState.errors?.jobRoleId ? 
                      'Job Role is required' :
                      ''
                    }
                  </span>
                </Box>
                <ProButton
                  text="Save"
                  color="#6eb066"
                  handleClick={handleSubmit(validateSubmit)}
                  variant="contained"
                  mx0
                />
              </form>
            )}
            {selectedRecord && (
              <form
                onSubmit={handleSubmit(updateOperative)}
                className="add-form">
                <Box>
                  <Typography variant="h6">Operative Name</Typography>
                  <Controller
                    name="operativeId"
                    control={control}
                    defaultValue=""
                    rules={{required: true}}
                    error={formState.errors?.tag ? true : false}
                    render={({field}) => (
                      <ReactSelect
                        name="operativeId"
                        field={field}
                        required
                        menuPlacement="bottom"
                        styles={customStyles}
                        placeholder="Select Operative Name"
                        options={lstActiveOperativesList}
                        getOptionLabel={(option) => option?.operativeName}
                        value={dropVal?.operative || ''}
                        defaultValue=""
                        onChange={(e) => {
                          field.onChange(e?.id);
                          setDropVal({...dropVal, operative: e});
                        }}
                        //menuPortalTarget={document.getElementById('modal')}
                      />
                    )}
                  />
                  <span className="err-form">
                    {formState.errors?.operativeId ? 
                      'Operative Name is required' :
                      ''
                    }
                  </span>
                </Box>

                <Box>
                  <Typography variant="h6">Rate Card</Typography>
                  <Controller
                    name="rateCardId"
                    control={control}
                    defaultValue=""
                    rules={{required: true}}
                    error={formState.errors?.tag ? true : false}
                    render={({field}) => (
                      <ReactSelect
                        name="rateCardId"
                        menuPlacement="bottom"
                        field={field}
                        required
                        styles={customStyles}
                        placeholder="Select Rate Card"
                        options={lstActiveRateCardJobRoles}
                        getOptionLabel={(option) => option?.rateCardName}
                        value={dropVal?.rateCard || ''}
                        defaultValue=""
                        onChange={(e) => {
                          field.onChange(e?.rateCardId);
                          setClearDrop(true);
                          setDropVal({...dropVal, rateCard: e});
                        }}
                        //menuPortalTarget={document.getElementById('modal')}
                      />
                    )}
                  />
                </Box>
                <span className="err-form">
                  {formState.errors?.rateCardId ? 
                    'Rate Card is required' :
                    ''
                  }
                </span>
                <Box>
                  <Typography variant="h6">Job Role</Typography>
                  <Controller
                    name="jobRoleId"
                    control={control}
                    defaultValue=""
                    rules={{required: true}}
                    error={formState.errors?.tag ? true : false}
                    render={({field}) => (
                      <ReactSelect
                        name="jobRoleId"
                        field={field}
                        required
                        options={jobRolesOptions}
                        getOptionLabel={(option) => option?.jobRoleName}
                        value={dropVal?.jobRole || ''}
                        defaultValue=""
                        styles={customStyles}
                        menuPlacement="bottom"
                        placeholder="Select Job Role"
                        onChange={(e) => {
                          field.onChange(e?.id);
                          setDropVal({...dropVal, jobRole: e});
                        }}
                        //menuPortalTarget={document.getElementById('modal')}
                      />
                    )}
                  />
                  <span className="err-form">
                    {formState.errors?.jobRoleId ? 
                      'Job Role is required' :
                      ''
                    }
                  </span>
                </Box>
                <ProButton
                  text="Save"
                  color="#6eb066"
                  handleClick={handleSubmit(updateOperative)}
                  variant="contained"
                  mx0
                />
              </form>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default AddOperativeModal;

import React from 'react'

function StatusChip({text}) {
    const getBg = (value) => {
        //console.log()
        let bg;
    
        if(value === 'Admin Disapproved' || value === 'Approval Pending' || value === 'Admin Approval Pending' || value === 'Not Submitted'){
          bg = '#FFEAEA';
        }else if( value === 'Admin Approved'){
          bg = '#D6F2DA';
        }else if(value === 'Saved'){
          bg = '#dcebfc';
        }else if(value === 'Manager Approved' || value === 'Manager Disapproved' || value === 'Submitted'){
          bg = '#FAF4D5';
        }
    
        return bg;
      };
    
      const getColor = (value) => {
        let color;
    
        if(value === 'Admin Disapproved' || value === 'Approval Pending' || value === 'Admin Approval Pending' || value === 'Not Submitted'){
          color = '#920404';
        }else if( value === 'Admin Approved'){
          color = '#0C5918';
        }else if(value === 'Saved'){
          color = '#042c59';
        }else if(value === 'Manager Approved' || value === 'Manager Disapproved' || value === 'Submitted'){
          color = '#796502';
        }
    
        return color;
      };
    
      const statusStyles = (value) => {
        return {
          background: getBg(value),
          borderRadius: '.2rem',
          color: getColor(value),
          padding: '.5rem',
          width: 'max-content',
          fontSize: '1.3rem'
        };
      };

  return (
    <div style={statusStyles(text)}>{text}</div>
  )
}

export default StatusChip

import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {MdOutlineEdit, MdOutlineClose, MdOutlineDelete} from 'react-icons/md';
import {toast} from 'react-toastify';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import ReactSelect from 'react-select';
import DatePicker from 'react-datepicker';
import { addDays, startOfWeek, endOfWeek } from 'date-fns';

import {tableData} from '../../../utils/tableData';
import Navigation from '../../../components/navigation/Navigation';
import StatusChip from './components/StatusChip';

import {details} from '../../../utils/details';
import {
  getAllRecords,
  submitMultipleTimesheet,
  exportTimesheet,
  createTimesheet,
  timesheetsActions,
  deleteTimesheet,
} from '../../../data/reducers/app/timesheets.reducer';
import {getDashboard} from '../../../data/reducers/app/app.reducer';
//import {getAll} from '../../../data/reducers/app/dropdown.reducer';

import '../../../components/dataTable/datatable.css';
import '../../../components/common-comp/CommonComp.css';
import DataTable from '../../../components/dataTable/DataTable';
import ProButton from '../../../components/common-comp/ProButton';
import Title from '../../../components/common-comp/Title';
import { convertToPounds } from '../../../utils/currency';
import AddTimesheet from './AddTimesheet';
import ConfirmationDialog from '../../../components/ConfirmationDialog';

export default function Timesheets() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [responseData, setResponseData] = useState(null);
  const [selectedRows, setSelectedRows] = useState(null);
  const [showButtons, setShowButtons] = useState(false);
  const [showCross, setShowCross] = useState(false);
  const [clearable, setClearable] = useState(false);
  const [SortField, setSortField] = useState('');
  const [addPopup, setAddPopup] = useState(false);
  const [dateVal, setDateVal] = useState('')
  const [firstDay, setfirstDay] = useState('')
  const [lastDay, setlastDay] = useState('')
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [deleteId, setDeleteId] = useState('');
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [deleteConfirm, setdeleteConfirm] = useState(false);
  const [action, setAction] = useState(null);

  const [filters, setFilters] = useState({
    PageNumber: 1,
    SortDir: 0,
    SortField: '',
    PageSize: 10,
    siteId: '',
    jobRoleId: '',
    approvalStatus: '',
    operativeName: '',
  });
  const {operativeTimesheet, timesheets, error, message, paging} =
    useSelector((state) => state.timesheets);
  const {lstAllSites, lstAllJobRoles} = useSelector(
    (state) => state.dropdown,
  ).dropdown;
  const {dashboard} = useSelector((_) => _.app);

  let user = JSON.parse(localStorage.getItem('user'));
  const role = user?.user?.role;

  let lstApprovalStatus = [];
  {role === 'Admin' && (
    lstApprovalStatus = [
      {value: 'Admin Approved'},
      {value: 'Admin Disapproved'},
      {value: 'Manager Approved'},
      {value: 'Saved'},
    ]
  )}
  {role === 'Site Manager' && (
    lstApprovalStatus = [
      {value: 'Admin Approved'},
      {value: 'Admin Disapproved'},
      {value: 'Manager Approved'},
      {value: 'Manager Disapproved'},
      {value: 'Approval Pending'},
    ]
  )}

  useEffect(() => {
    if (error) {
      toast.error(error);
    } else if (message) {
      toast.success(message);
    }
  }, [error, message]);

  useEffect(() => {
    let debounce;
    if (filters?.operativeName !== '') {
      console.log('filter', filters?.operativeName)
      debounce = setTimeout(()=>{
        handleFetch();
        //setShowCross(true);
      },500);
    }else{
      handleFetch();
    }

    return()=>{
      clearTimeout(debounce);
    }
  }, [filters],[SortField]);

  useEffect(() => {
    if(operativeTimesheet) console.log('op', operativeTimesheet)
    
  }, [operativeTimesheet]);

  useEffect(() => {
    handleResponse()
  },[timesheets]);

  useEffect(()=>{
    if(startDate && endDate) handleFetch()
  },[startDate,endDate]);

  useEffect(()=>{
    firstAndLastDay();
  },[dateVal]);

  useEffect(()=>{
    startAndEndDay()
  },[firstDay, lastDay]);

  const firstAndLastDay = () =>{
    if(dateVal !== '' && dateVal !== null){
      setfirstDay(startOfWeek(dateVal, {weekStartsOn: 6}));
      setlastDay(endOfWeek(dateVal, {weekStartsOn: 6}));
    }else{
      setfirstDay('')
      setlastDay('')
    }
  }

  const startAndEndDay = () =>{
    if(firstDay!== '' || lastDay!==''){
      console.log('first', firstDay)
      var start = new Date(firstDay);
      setStartDate(yearMonthDate(start));
    
      var end = new Date(lastDay); 
      setEndDate(yearMonthDate(end));
    }else{
      setStartDate('');
      setEndDate('');
    }
  }

  const yearMonthDate = (dateVal) => {
    var date = new Date(dateVal);
    var print =
    
      date.getFullYear() +
      '-' +
      (date.getMonth() > 8
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)) +
      '-' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) ;

    return `${print}`;
  };

  const handleResponse = () =>{
    setResponseData(timesheets);
  }

  const handleFetch = async () => {
    dispatch(timesheetsActions.timesheetsReset());
    setResponseData(null);
    setShowButtons(false);
    setShowCross(false);
    setClearable(false);
    setdeleteConfirm(false);
    setConfirmPopup(false);
    setDeleteId('')

    if(startDate!=='' || endDate!=='')setClearable(true)
    
    await dispatch(
      getAllRecords({
        endpoint: `Timesheets/get-all?SortField=${
          SortField
        }&SortDir=${filters?.SortDir}&PageNumber=${
          filters?.PageNumber
        }&PageSize=${filters?.PageSize}&OperativeName=${
          filters?.operativeName
        }&StartDate=${startDate}&EndDate=${
          endDate
        }&JobRoleId=${
          filters?.jobRoleId ? filters?.jobRoleId?.id : ''
        }&SiteId=${
          filters?.siteId ? filters?.siteId?.id : ''
        }&ApprovalStatus=${filters?.approvalStatus ? filters?.approvalStatus.value : ''}`,
      }),
    );

    await dispatch(getDashboard());
  };

  const handleEdit = (tableMeta) => {
    // <--- For editing a record
    console.log('edit');
    let temp = responseData[tableMeta?.rowIndex];
    if(role?.includes('Operative')){
      if (
        temp.approvalStatus === 'Admin Disapproved' ||
        temp.approvalStatus === 'Manager Disapproved' ||
        temp.approvalStatus === 'Not Submitted'
      ) {
        navigate(`edit/${temp?.id}`, {
          state: {content: details.timesheets,
            operativeData: temp
          },
        });
      }
    }else {
      navigate(`edit/${temp?.id}`, {
        state: {content: details.timesheets,
          operativeData: temp
        }
      });
    }
  };

  const handleDelete = () =>{
    if(deleteId){
      if(deleteId?.approvalStatus === 'Admin Disapproved'){
        let id = deleteId?.id;
        dispatch(deleteTimesheet({endpoint: `TimeSheets/delete-timesheet/${id}`}))?.then((res)=>{
          if(res?.type === 'timesheets/deleteTimesheet/fulfilled') handleFetch();
        })
      }else{
        return null
      }
    }
  }

  const handleDisapproval = async () => {
    const ids = selectedRows?.map((rowIndex) => {
      return responseData[rowIndex]?.id;
    });

    await dispatch(
      submitMultipleTimesheet({
        endpoint: `Timesheets/submit-multipletimesheets?action=disapprove`,
        data: {id: ids},
      }),
    )?.then((res)=>{
      if (res?.type === 'timesheets/submitMultipleTimesheet/fulfilled') {
        //dispatch(timesheetsActions.timesheetsReset());
        //setShowButtons(false);
        setSelectedRows(null)
        handleFetch();
      }
    })
  };

  const handleApproval = async () => {
    const ids = selectedRows?.map((rowIndex) => {
      return responseData[rowIndex]?.id;
    });

    await dispatch(
      submitMultipleTimesheet({
        endpoint: `Timesheets/submit-multipletimesheets?action=approve`,
        data: {id: ids},
      }),
    )?.then((res)=>{
      if (res?.type === 'timesheets/submitMultipleTimesheet/fulfilled') {
        //dispatch(timesheetsActions.timesheetsReset());
        //setShowButtons(false);
        setSelectedRows(null)
        handleFetch();
      }
    })
  };

  const sortHandle = (name) => {
		//debugger
		if (SortField === name) {
			console.log("yes same field")
      setSortField(name);
			if (filters?.SortDir === 0){
        setFilters({...filters, SortDir: 1});
      }else{
        setFilters({...filters, SortDir: 0});
      }
		} else {
			setSortField(name);
			setFilters({...filters, SortDir: 1});
		}
	};

  const exportTimesheetFn = async () => {
    await dispatch(
      exportTimesheet({
        endpoint: `Timesheets/export-timesheets?OperativeName=${
          filters?.operativeName
        }&JobRoleId=${
          filters?.jobRoleId ? filters?.jobRoleId?.id : ''
        }&StartDate=${startDate}&EndDate=${
          endDate
        }&SiteId=${
          filters?.siteId ? filters?.siteId?.id : ''
        }&ApprovalStatus=${filters?.approvalStatus ? filters?.approvalStatus.value : ''}`,
      }),
    );
  };

  const createTimesheetFn = async(data, updated) =>{
    if(!updated){
      dispatch(createTimesheet({
        endpoint:`Timesheets/create-timesheet`,
        data
      }))?.then((response)=>{
        if(response?.type === 'timesheets/createTimesheet/fulfilled'){
          const {payload} = response;
          setAddPopup(false);
          dispatch(timesheetsActions.timesheetsReset());
          navigate(`edit-operative-timesheet/${payload?.data?.id}`);
        }
      })
    }
  }

  const handleDate = (dateVal) => {
    var date = new Date(dateVal);
    var print =
      (date.getMonth() > 8
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)) +
      '-' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
      '-' +
      date.getFullYear();
    return `${print}`;
  };

  const deleteBtnHandle = (index) =>{
    {responseData[index]?.approvalStatus === 'Admin Disapproved' &&
      setdeleteConfirm(true);
      setDeleteId(responseData[index]);
    }
  }

  const tableDataCol =
    role?.includes('Operative')
      ? tableData.timesheetsOperative
      : tableData.timesheets;
  const tableCols = tableDataCol?.columns.map((column) => {
    // <--- Creating table columns dynamically
    if (column?.link) {
      return {
        name: column.name,
        label: column.label,
        options: {
          customBodyRender: (value, tableMeta) => {
            return (
              <div
                onClick={() => {
                  let temp = responseData[tableMeta?.rowIndex];
                  navigate(`/timesheets/detail/${temp?.id}`, {
                    state: {
                      content: details.timesheets,
                      operativeData: temp
                    },
                  });
                }}
                style={{cursor: 'pointer', textDecoration: 'underline'}}>
                {value}
              </div>
            );
          },
          customHeadRender: () =>{
            return (
              <Title
                name={column?.name}
                title={column?.label}
                sortField={SortField}
                sortDir={filters?.SortDir}
                sortHandle={sortHandle}
				      />
            )
          },
        },
      };
    } else if (column?.status) {
      return {
        name: column.name,
        label: column.label,
        options: {
          customBodyRender: (value) => {
            return <StatusChip text={value} />;
          },
          customHeadRender: () =>{
            return (
              <Title
                name={column?.name}
                title={column?.label}
                sortField={SortField}
                sortDir={filters?.SortDir}
                sortHandle={sortHandle}
				      />
            )
          }
        },
      };
    } else if (column?.week) {
      return {
        name: column.name,
        label: column.label,
        options: {
          customBodyRender: (value, tableMeta) => {
            return <div>
              {responseData ?
               `${handleDate(value)} - ${handleDate(responseData[tableMeta.rowIndex]?.endDate)}` :
               ''
              }
              </div>;
          },
          customHeadRender: () =>{
            return (
              <Title
                name={column?.name}
                title={column?.label}
                sortField={SortField}
                sortDir={filters?.SortDir}
                sortHandle={sortHandle}
				      />
            )
          }
        },
      };
    } else {
      return {
        name: column.name,
        label: column.label,
        options:{
          customHeadRender: () =>{
            return (
              <Title
                name={column?.name}
                title={column?.label}
                sortField={SortField}
                sortDir={filters?.SortDir}
                sortHandle={sortHandle}
				      />
            )
          }
        }
      };
    }
  });

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: '100%',
      fontSize: '1.3rem',
      marginTop: '.6rem',
    }),
    option: (provided, {isFocused}) => ({
      ...provided,
      fontSize: '1.3rem',
      backgroundColor: isFocused ? '#329BEF' : 'white',
      color: isFocused ? 'white' : 'black',
      zIndex: 400,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 900,
    }),
    control: (styles) => ({...styles, padding: '0.2rem'}),
  };

  const buttonStyle = (index) => {
    let status = responseData[index]?.approvalStatus;
    if(role?.includes('Operative')){
      return {
        border: 'none',
        marginRight: '.5rem',
        cursor:
          status === 'Admin Disapproved' ||
          status === 'Manager Disapproved' ||
          status === 'Not Submitted'
            ? 'pointer'
            : 'unset',
        background: 'transparent',
        color:
          status === 'Admin Disapproved' ||
          status === 'Manager Disapproved' ||
          status === 'Not Submitted'
            ? '#000'
            : 'gray',
      };
    }else{
      return {
        border: 'none',
        marginRight: '.5rem',
        cursor: 'pointer',
        background: 'transparent',
        color: '#000',
      };
    }
    
  };

  {role === 'Admin' && (
    tableCols?.push({
      name: 'totalCost',
      label: 'Cost',
      options:{
        customBodyRender: (value) => {
          return <div>{convertToPounds(value)}</div>
        }
      }
    })
  )}

  const adminDeleteBtnStyle = (index) =>{
    let status = responseData[index]?.approvalStatus;
    return{
      border: 'none',
      marginRight: '.5rem',
      cursor: status === 'Admin Disapproved' ? 'pointer' : 'unset',
      background: 'transparent',
      color: status === 'Admin Disapproved' ? '#000' : 'gray',
    }
  }

  {
    role !== 'Site Manager' &&
      tableCols?.push({
        name: 'Actions',
        options: {
          customBodyRender: (_, tableMeta) => {
            return (
              <span
                style={{
                  padding: '.5rem',
                  width: 'max-content',
                }}>
                <button
                  onClick={() => handleEdit(tableMeta)}
                  style={buttonStyle(tableMeta.rowIndex)}>
                  <MdOutlineEdit size="1.8rem" />
                </button>
                {role === 'Admin' && (
                  <button
                    onClick={() => deleteBtnHandle(tableMeta?.rowIndex)}
                    style={adminDeleteBtnStyle(tableMeta.rowIndex)}>
                    <MdOutlineDelete size="1.8rem" />
                </button>
                )}
              </span>
            );
          },
        },
      });
  }

  const onRowSelectionChange = (
    currentRowsSelected,
    allRowsSelected,
    rowsSelected,
  ) => {
    if(rowsSelected?.length > 0){
      setShowButtons(true);
      setSelectedRows(rowsSelected);
    }
   
  };

  const navigation = [
    {
      name: ' Timesheets ',
      path: '/timesheets'
    },
  ]

  const deleteConfirmDialog = () =>{
    return (
      <Dialog maxWidth="sm" open={deleteConfirm}>
        <DialogContent dividers>
          <Typography variant="h5" pr={10}>
            Are you sure you want to delete this timesheet?
          </Typography>
        </DialogContent>
        <DialogActions>
          <ProButton
            text="Cancel"
            color="#cb272f"
            handleClick={()=> setdeleteConfirm(false)}
            variant="outlined"
          />
          <ProButton
            text="Yes"
            color="#cb272f"
            handleClick={handleDelete}
            variant="contained"
          />
        </DialogActions>
      </Dialog>
    );
  }

  const render = () => {
    return (
      <div className="page-container">
        <Navigation path={navigation} />
        <Box className="section-top">
          <h1>Timesheets</h1>

          {role === 'Admin' && (
            <Box className="weekly-cost" display="flex">
            <Typography variant="h4" fontWeight="light">
              Weekly Cost: &nbsp;
            </Typography>
            <Typography variant="h4" fontWeight="bold">
              £{dashboard?.costCalculation?.weeklyCost}
            </Typography>
          </Box>
          )}
          
        </Box>
        <Grid container 
          spacing={2} 
          sx={{ flexDirection: {xl: role!=='Site Manager' ? "row-reverse" : 'row' } }}
        >
          {role === 'Admin' && (
            <Grid
              item
              xl={4}
              md={12}
              sm={12}
              xs={12}
              display="flex"
              justifyContent="end"
              alignItems='flex-end'
              alignSelf='flex-end'>
              <ProButton
                text="Create Timesheet"
                color="#cb272f"
                handleClick={() => setAddPopup(true)}
                variant="contained"
                
              />
              <ProButton
                text="Export"
                color="#cb272f"
                handleClick={exportTimesheetFn}
                variant="outlined"
                
              />
            </Grid>
          )}
          <Grid
            item
            xl={8}
            md={12}
            sm={12}
            xs={12}
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
            container
            alignContent="center">

            {role !== "Site Manager" && (
              <Grid item md sm={6} xs={12} justifyContent='space-between'>
                <Typography variant="h6">Site</Typography>
                <ReactSelect
                  name="site"
                  required
                  styles={customStyles}
                  options={lstAllSites}
                  getOptionLabel={(option) => option?.siteName}
                  value={filters?.siteId || ''}
                  defaultValue=""
                  isClearable
                  placeholder="Select Site"
                  onChange={(e) => {
                    setFilters({...filters, siteId: e});
                  }}
                />
              </Grid>
            )} 
            
            <Grid item md sm={6} xs={12} justifyContent='space-between' >
              <Typography variant="h6">Job Role</Typography>
              <ReactSelect
                name="role"
                required
                styles={customStyles}
                options={lstAllJobRoles}
                getOptionLabel={(option) => option?.jobRole}
                value={filters?.jobRoleId || ''}
                defaultValue=""
                isClearable
                placeholder="Select Job Role"
                onChange={(e) => {
                  setFilters({...filters, jobRoleId: e});
                }}
              />
            </Grid>
            <Grid item md sm={6} xs={12} justifyContent='space-between' >
              <Typography variant="h6">Approval Status</Typography>
              <ReactSelect
                name="status"
                required
                styles={customStyles}
                options={lstApprovalStatus}
                getOptionLabel={(option) => option?.value}
                value={filters?.approvalStatus || ''}
                defaultValue=""
                isClearable
                placeholder="Select Status"
                onChange={(e) => {
                  setFilters({...filters, approvalStatus: e});
                }}
              />
            </Grid>
            <Grid item md sm={6} xs={12} justifyContent='space-between' >
              <Typography variant="h6">Week</Typography>
              <DatePicker
                //selectsRange={clearable ? true : false}
                selected={dateVal}
                startDate={firstDay}
                endDate={lastDay}
                onChange={(update) => {
                  setDateVal(update);
                }}
                placeholderText="Select week"
                className='dateInput'
                customInput={
                  <TextField
                    sx={{width:'100%', bgcolor:'#ffffff'}}
                    value={`${startDate} - ${endDate}`}
                    placeholder="Select week"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          {clearable && (
                            <IconButton
                              onClick={() => {
                                setDateVal('');
                                setStartDate('');
                                setEndDate('');
                              }}>
                              <MdOutlineClose size="2rem" />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                }
                //calendarClassName='datepicker-cal'
                maxDate={addDays(new Date(), 1)}
                showDisabledMonthNavigation
              />
            </Grid>
            {!role?.includes('Operative') && (
              <Grid item md sm={6} xs={12} justifyContent='space-between' >
                <Typography variant="h6">Operative Name</Typography>
                <TextField
                  id="outlined-required"
                  placeholder="Search..."
                  fullWidth
                  value={filters?.operativeName}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      operativeName: e.target.value,
                      PageNumber: 1,
                    });
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        {showCross && (
                          <IconButton
                            onClick={() => {
                              setFilters({...filters, operativeName: ''});
                            }}>
                            <MdOutlineClose size="2rem" />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        <section className="section-table">
          {role?.includes('Operative') && (
            <Box className="section-table">
              <DataTable
                newCols={tableCols}
                newData={responseData ? responseData : []}
                pagination={true}
                filter={filters}
                setFilter={setFilters}
                totalCount={paging?.totalItemsCount}
              />
            </Box>
          )}

          {!role?.includes('Operative') && (
              // <Box className="section-table">
                <DataTable
                  newCols={tableCols}
                  newData={responseData ? responseData : []}
                  pagination={true}
                  filter={filters}
                  setFilter={setFilters}
                  totalCount={paging?.totalItemsCount}
                  onRowSelectionChange={onRowSelectionChange}
                />
              // </Box>
          )}
        </section>
        
        {showButtons && (
          <section className="bottom-buttons">
            <ProButton
                text="Disapprove"
                color="#cb272f"
                handleClick={()=>{
                  setAction('disapprove')
                  setConfirmPopup(true);
                }}
                variant="outlined"
              />
              <ProButton
                text="Approve"
                color="#cb272f"
                handleClick={()=>{setAction('approve')
                  setConfirmPopup(true);
                }}
                variant="contained"
              />
          </section>
        )}

      </div>
    );
  };

  return (
    <>
      <div className="container">
        {render()}
        <AddTimesheet
          open={addPopup}
          handleClose={() => setAddPopup(false)}
          submitHandle={createTimesheetFn}
          //selectedRecord={editRow}
        />
        <ConfirmationDialog
        open={confirmPopup}
        text={
          action==='approve' ? 
          'Are you sure you want to approve selected timesheets?' :
          'Are you sure you want to disapprove selected timesheets?'
        }
        handleClose={() => setConfirmPopup(false)}
        submitHandle={() => {
          if(action === 'approve'){
            handleApproval();
          }else{ 
            handleDisapproval();
          }
          setConfirmPopup(false);
        }}
      />
      
      {deleteConfirmDialog()}
      </div>
    </>
  );
}

import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
  Modal,
  IconButton,
  TextField,
  Box,
  Typography,
  Divider,
} from '@mui/material';
import {MdOutlineClose} from 'react-icons/md';
import {useForm, Controller} from 'react-hook-form';
import ReactSelect from 'react-select';

import {getAll} from '../../../data/reducers/app/dropdown.reducer';

//import {TextInput} from '../../../components/Input';
import ProButton from '../../../components/common-comp/ProButton';

import '../../../components/addModal.css';

function AddModalSites({open, handleClose, submitHandle, selectedRecord}) {
  const dispatch = useDispatch();
  const {dropdown} = useSelector((state) => state.dropdown);

  const {lstActiveSiteManagers} = dropdown;
  const [dropVal, setDropVal] = useState({
    siteManager: '',
  });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 400,
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2.5,
    borderRadius: 1,
  };

  const {handleSubmit, control, register, formState, reset, setValue} =
    useForm({shouldUnregister: true});

  const validateSubmit = (data) => {
    submitHandle(data, false);
  };

  const updateSite = (data) => {
    submitHandle(data, true);
  };

  useEffect(() => {
    if (selectedRecord) {
      for (const [key, value] of Object.entries(selectedRecord)) {
        setValue(key, value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
      setDropVal({
        ...dropVal,
        siteManager: lstActiveSiteManagers?.filter(
          (option) =>
            option.siteManagerId == selectedRecord?.siteManagerId,
        )[0],
      });
    } else {
      reset();
      setDropVal({
        ...dropVal,
        siteManager: '',
      });
      handleFetch();
    }
  }, [open]);

  const handleFetch = async () => {
    await dispatch(getAll());
  };

  useEffect(() => {
    // <---for Edit Operative Modal
    if (selectedRecord) {
      for (const [key, value] of Object.entries(selectedRecord)) {
        setValue(key, value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    }

    setDropVal({
      ...dropVal,
      siteManager: lstActiveSiteManagers?.filter(
        (option) => option.siteManagerId == selectedRecord?.siteManagerId,
      )[0],
    });
  }, [selectedRecord]);

  useEffect(() => {
    reset();
    setDropVal({
      ...dropVal,
      siteManager: '',
    });
    handleFetch();
  }, []);

  const closeModal = () => {
    reset();
    setDropVal({
      ...dropVal,
      siteManager: '',
    });
    handleClose();
  };

  const customStyles = {
    container: (provided) => ({
      ...provided,
      fontSize: '1.3rem',
      marginTop: '.6rem',
    }),
    option: (provided, {isFocused}) => ({
      ...provided,
      fontSize: '1.3rem',
      backgroundColor: isFocused ? '#329BEF' : 'white',
      color: isFocused ? 'white' : 'black',
      zIndex: 400,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 900,
    }),
    control: (styles) => ({...styles, padding: '0.3rem'}),
  };

  function validateHhMm(inputField) {
    var sHours = inputField.split(':')[0];
    var sMinutes = inputField.split(':')[1];

    if (
      sHours == '' ||
      isNaN(sHours) ||
      (parseInt(sHours) > 23 && sHours == '') ||
      isNaN(sHours) ||
      parseInt(sHours) < 1
    ) {
      return false;
    }
    if (sMinutes == '' || isNaN(sMinutes) || parseInt(sMinutes) > 59) {
      return false;
    }
  }

  return (
    <Modal open={open} onClose={closeModal}>
      <Box sx={style}>
        <Box display="flex" justifyContent="space-between" mb={2}>
          {!selectedRecord ? (
            <Typography variant="h3">Add Site</Typography>
          ) : (
            <Typography variant="h3">Edit Site</Typography>
          )}
          <IconButton onClick={closeModal}>
            <MdOutlineClose size="2rem" />
          </IconButton>
        </Box>
        <Divider />
        <Box mt={3} mb={2}>
          {!selectedRecord && (
            <form
              onSubmit={handleSubmit(validateSubmit)}
              className="add-form">
              <Box>
                <Typography variant="h6">Site Manager Name</Typography>
                <Controller
                  name="siteManagerId"
                  control={control}
                  defaultValue=""
                  //rules={{required: true}}
                  error={formState.errors?.tag ? true : false}
                  render={({field}) => (
                    <ReactSelect
                      name="siteManagerId"
                      field={field}
                      //required
                      isClearable={true}
                      styles={customStyles}
                      options={lstActiveSiteManagers}
                      placeholder="Select Site Manager"
                      getOptionLabel={(option) => option?.siteManagerName}
                      value={dropVal?.siteManager || ''}
                      defaultValue=""
                      onChange={(e) => {
                        field.onChange(e?.siteManagerId);
                        setDropVal({...dropVal, siteManager: e});
                      }}
                    />
                  )}
                />
                {/* <span className="err-form">
                  {formState.errors?.siteManagerId ? 
                    'Site Manager Name is required' :
                    ''
                  }
                </span> */}
              </Box>

              <Box>
                <Typography variant="h6">Site Name</Typography>
                <TextField
                  {...register(`siteName`, {required: true})}
                  required
                  id="outlined-required"
                  placeholder="Site Name"
                  fullWidth
                />
                <span className="err-form">
                  {formState.errors?.siteName ? 
                    'Site Name is required' :
                    ''
                  }
                </span>
              </Box>
              <Box>
                <Typography variant="h6">Site Code</Typography>
                <TextField
                  {...register(`siteCode`, {required: true})}
                  required
                  id="outlined-required"
                  placeholder="Site Code"
                  maxLength={25}
                  fullWidth
                />
                <span className="err-form">
                  {formState.errors?.siteCode ? 
                    'Site Code is required' :
                    ''
                  }
                </span>
              </Box>
              <Box>
                <Typography variant="h6">Site Address</Typography>
                <TextField
                  {...register(`siteAddress`, {required: true})}
                  required
                  id="outlined-required"
                  placeholder="Site Address"
                  maxLength={50}
                  fullWidth
                />
                <span className="err-form">
                  {formState.errors?.siteAddress ? 
                    'Site Address is required' :
                    ''
                  }
                </span>
              </Box>
              <Box>
                <Typography variant="h6">Permitted Hours</Typography>
                <TextField
                  {...register(`permittedHours`, {
                    required: true,
                    validate: {
                      hours: (value) => validateHhMm(value),
                    },
                  })}
                  required
                  autoComplete={false}
                  id="outlined-required"
                  placeholder="Permitted Hours (HH:MM)"
                  fullWidth
                />
                <span className="err-form">
                  {formState.errors?.permittedHours ? 
                    (formState.errors?.permittedHours?.type === 'hours'
                    ? `Invalid Time`
                    : 'Permitted Hours is required.') :
                    ''
                  }
                </span>
              </Box>
              <ProButton
                text="Save"
                color="#6eb066"
                handleClick={handleSubmit(validateSubmit)}
                variant="contained"
                mx0
              />
            </form>
          )}
          {selectedRecord && (
            <form onSubmit={handleSubmit(updateSite)} className="add-form">
              <Box>
                <Typography variant="h6">Site Manager Name</Typography>
                <Controller
                  name="siteManagerId"
                  control={control}
                  defaultValue=""
                  //rules={{required: true}}
                  error={formState.errors?.tag ? true : false}
                  render={({field}) => (
                    <ReactSelect
                      name="siteManagerId"
                      field={field}
                      isClearable={true}
                      styles={customStyles}
                      options={lstActiveSiteManagers}
                      placeholder="Select Site Manager"
                      getOptionLabel={(option) => option?.siteManagerName}
                      value={dropVal?.siteManager || ''}
                      defaultValue=""
                      onChange={(e) => {
                        field.onChange(e?.siteManagerId);
                        setDropVal({...dropVal, siteManager: e});
                      }}
                    />
                  )}
                />
                
              </Box>
              <Box>
                <Typography variant="h6">Site Name</Typography>
                <TextField
                  {...register(`siteName`, {required: true})}
                  required
                  id="outlined-required"
                  placeholder="Site Name"
                  fullWidth
                />
                <span className="err-form">
                  {formState.errors?.siteName ? 
                    'Site Name is required' :
                    ''
                  }
                </span>
              </Box>
              <Box>
                <Typography variant="h6">Site Code</Typography>
                <TextField
                  {...register(`siteCode`, {required: true})}
                  required
                  id="outlined-required"
                  placeholder="Site Code"
                  maxLength={25}
                  fullWidth
                />
                <span className="err-form">
                  {formState.errors?.siteCode ? 
                    'Site Code is required' :
                    ''
                  }
                </span>
              </Box>
              <Box>
                <Typography variant="h6">Site Address</Typography>
                <TextField
                  {...register(`siteAddress`, {required: true})}
                  required
                  id="outlined-required"
                  placeholder="Site Address"
                  maxLength={50}
                  fullWidth
                />
                <span className="err-form">
                  {formState.errors?.siteAddress ? 
                    'Site Address is required' :
                    ''
                  }
                </span>
              </Box>
              <Box>
                <Typography variant="h6">Permitted Hours</Typography>
                <TextField
                  {...register(`permittedHours`, {
                    required: true,
                    validate: {
                      hours: (value) => validateHhMm(value),
                    },
                  })}
                  required
                  id="outlined-required"
                  placeholder="Permitted Hours (HH:MM)"
                  fullWidth
                />
                <span className="err-form">
                  {formState.errors?.permittedHours ? 
                    (formState.errors?.permittedHours?.type === 'hours'
                    ? `Invalid Time`
                    : 'Permitted Hours is required.') :
                    ''
                  }
                </span>
              </Box>
              <ProButton
                text="Save"
                color="#6eb066"
                handleClick={handleSubmit(updateSite)}
                variant="contained"
                mx0
              />
            </form>
          )}
        </Box>
      </Box>
    </Modal>
  );
}

export default AddModalSites;

import React from 'react';
import {NavLink} from 'react-router-dom';
import {GiCargoCrane} from 'react-icons/gi';
import {BiSpreadsheet} from 'react-icons/bi';
import {
  MdPeopleOutline,
  MdOutlineDashboard,
  MdInfoOutline,
  MdOutlineLibraryBooks,
} from 'react-icons/md';
import {Box} from '@mui/material';

import './sidebar.css';

function DrawerResponsive({sidebarNavlinks, clickFn}) {
  const handleIcons = (linkname) => {
    if (linkname === 'rateCards') {
      return <BiSpreadsheet size="2.5rem" />;
    } else if (linkname === 'sites') {
      return <GiCargoCrane size="2.5rem" />;
    } else if (linkname === 'timesheets') {
      return <MdOutlineLibraryBooks size="2.5rem" />;
    } else if (linkname === 'employees') {
      return <MdPeopleOutline size="2.5rem" />;
    } else if (linkname === 'dashboard') {
      return <MdOutlineDashboard size="2.5rem" />;
    } else if (linkname === 'details') {
      return <MdInfoOutline size="2.5rem" />;
    }
  };

  return (
    <Box className="sidebarDrawer" paddingLeft={1} onClick={clickFn}>
      {sidebarNavlinks.map((link) => {
        return (
          <NavLink
            className="nav-link "
            to={link.path}
            style={({isActive}) => ({
              background: isActive ? '#CB272F' : '',
              color: isActive ? '#fff' : '#000',
            })}
            key={link?.id}>
            {handleIcons(link.src)}
            <span className="link-name">{link.linkName}</span>
          </NavLink>
        );
      })}
    </Box>
  );
}

export default DrawerResponsive;

import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {appServices} from '../../services/app/app.service';

let key = '';

export const getAll = createAsyncThunk(
  'app/getAll',
  async (payload, {rejectWithValue}) => {
    const response = await appServices.getAll(payload);
    if (response.data) {
      return response;
    } else {
      return rejectWithValue('error message');
    }
  },
);

export const getDashboard = createAsyncThunk(
  'app/getDashboard',
  async (payload, {rejectWithValue}) => {
    const response = await appServices.getDashboard();
    if (response.data) {
      return response;
    } else {
      return rejectWithValue('Something went wrong');
    }
  },
);

export const getGraphs = createAsyncThunk(
  'app/getGraphs',
  async (payload, {rejectWithValue}) => {
    const response = await appServices.getGraphs();
    if (response.data) {
      return response;
    } else {
      return rejectWithValue('Something went wrong');
    }
  },
);

export const update = createAsyncThunk(
  'app/update',
  async (payload, {rejectWithValue}) => {
    const response = await appServices.update(payload);
    if (response.data) {
      console.log('update success');
      return response;
    } else {
      console.log('update error');
      return rejectWithValue('error message');
    }
  },
);

export const getAllRecords = createAsyncThunk(
  'app/getAllRecords',
  async (payload, {rejectWithValue}) => {
    key = payload.key;
    const response = await appServices.getAllRecords(payload);
    if (response.data) {
      return {data: response, key: payload.key};
    } else {
      return rejectWithValue('error message');
    }
  },
);

export const postRecords = createAsyncThunk(
  'app/postRecords',
  async (payload, thunkAPI) => {
    const response = await appServices.post(payload);
    console.log('reducer: ');
    if (response.success) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response.error);
    }
  },
);

export const updateAllRecords = createAsyncThunk(
  'app/updateAll',
  async (payload, {rejectWithValue}) => {
    key = payload.key;
    const response = await appServices.updateAllRecords(payload);

    if (response.success) {
      return {data: response, key: payload.key};
    } else {
      return rejectWithValue('error message');
    }
  },
);

export const deleteRecord = createAsyncThunk(
  'app/deleteRecord',
  async (payload, thunkAPI) => {
    const response = await appServices.deleteRecord(payload);
    if (response) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  },
);

const appSlice = createSlice({
  name: 'app',
  initialState: {
    [key]: [],
    stateData: [],
    errorMessage: '',
    graphs: [],
  },
  reducers: {},
  extraReducers: {
    [getAll.fulfilled]: (state, action) => {
      state.stateData = action.payload.data;
    },
    [getAllRecords.fulfilled]: (state, action) => {
      state[action.payload.key] = action.payload.data.data;
    },
    [postRecords.pending]: (state) => {
      state.errorMessage = '';
    },
    [postRecords.rejected]: (state, action) => {
      state.errorMessage = action.payload;
    },

    // Get Dashboard
    [getDashboard.pending]: (state) => {
      state.loading = true;
      state.error = '';
    },
    [getDashboard.fulfilled]: (state, action) => {
      state.dashboard = action?.payload?.data;
      state.loading = false;
      state.error = '';
    },
    [getGraphs.pending]: (state) => {
      state.loading = true;
      state.error = '';
    },
    [getGraphs.fulfilled]: (state, action) => {
      state.graphs = action?.payload?.data;
      state.loading = false;
      state.error = '';
    },
  },
});

export default appSlice.reducer;

import React from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';

import Login from '../screens/Auth/Login';
import Register from '../screens/Auth/Register';

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path={'/login'} element={<Login />} />
      <Route path={'/register'} element={<Register />} />

      {/* Catch All */}
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
};

export default AuthRoutes;

import axiosInstance from '../../../utils/apiHandler/axiosInstance';

const getAllOperatives = async (payload) => {
  try {
    const response = await axiosInstance.get(payload.endpoint);
    return response.data;
  } catch (error) {
    console.log(error);
    return {error: error.response.data.message};
  }
};

const createOperative = async (payload) => {
  try {
    const response = await axiosInstance.post(
      payload.endpoint,
      payload.data,
    );
    return response.data;
  } catch (error) {
    return {error: error.response.data.message};
  }
};

const updateOperative = async (payload) => {
  try {
    const response = await axiosInstance.put(
      payload.endpoint,
      payload.data,
    );
    return response.data;
  } catch (error) {
    return {error: error.response.data.message};
  }
};

const deleteOperative = async (payload) => {
  try {
    const response = await axiosInstance.delete(payload?.endpoint);
    return response.data;
  } catch (error) {
    return {error: error.response.data.message};
  }
};

const getMe = async (payload) => {
  try {
    const response = await axiosInstance.get(payload.endpoint);
    return response.data;
  } catch (error) {
    console.log(error);
    return {error: error.response.data.message};
  }
};

const exportOperatives = async (payload) => {
  try {
    const response = await axiosInstance.get(
      payload?.endpoint,
      {
        headers:
        {
            //'Content-Disposition': `attachment; filename=Timesheet-${+new Date()}.xlsx`,
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        responseType: 'blob',
      }
    );
    return response;
  } catch (error) {
    return {error: error.response.data.message};
  }
};

const resetPassword = async (payload) => {
  try {
    const response = await axiosInstance.put(
      payload.endpoint,
      payload.data,
    );
    return response.data;
  } catch (error) {
    return {error: error.response.data.message};
  }
};

export const operativesService = {
  getAllOperatives,
  createOperative,
  updateOperative,
  getMe,
  deleteOperative,
  exportOperatives,
  resetPassword,
};

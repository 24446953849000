import React, {useEffect, useState} from 'react';
import {Box, Grid} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';

import DashCard from './Card';
import Graphs from './Graphs';
import DataTable from '../../../components/dataTable/DataTable';
//import DashDetail from '../timesheets/DashDetail';
//import MyTimesheet from '../timesheets/MyTimesheet';

import {getDashboard, getGraphs} from '../../../data/reducers/app/app.reducer';
import {getAllRecords} from '../../../data/reducers/app/timesheets.reducer';

import './admin.css';
import StatusChip from '../timesheets/components/StatusChip';
import {tableData} from '../../../utils/tableData';
import { convertToPounds } from '../../../utils/currency';

export default function Admin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {dashboard, graphs} = useSelector((_) => _.app);
  const {timesheets} = useSelector((_) => _.timesheets);

  const [responseData, setResponseData] = useState(null);

  let user = JSON.parse(localStorage.getItem('user'));
  const role = user?.user?.role;

  const adminContent = [
    {
      title: 'Site',
      content: [
        {
          heading: 'Active Operative',
          right: dashboard ?
           `${dashboard?.operativeCounts?.activeOperative}/${dashboard?.operativeCounts?.totalOperative}`:
           `-/-`,
        },
        {
          heading: 'Active Site',
          right: dashboard ?
           `${dashboard?.sitesCount?.activeSites}/${dashboard?.sitesCount?.totalSites}`:
           `-/-`,
        },
      ],
    },
    {
      title: 'Timesheet',
      content: [
        {
          heading: 'Approved',
          right: dashboard ?
           `${dashboard?.timeSheetsStatusCounts?.approvedTimeSheets}/${dashboard?.timeSheetsStatusCounts?.totalTimeSheets}`:
           `-/-`,
        },
        {
          heading: 'Disapproved',
          right: dashboard ?
           `${dashboard?.timeSheetsStatusCounts?.disapprovedTimeSheets}/${dashboard?.timeSheetsStatusCounts?.totalTimeSheets}`:
           `-/-`,
        },
        {
          heading: 'Pending',
          right: dashboard ?
           `${dashboard?.timeSheetsStatusCounts?.pendingTimeSheets}/${dashboard?.timeSheetsStatusCounts?.totalTimeSheets}`:
           `-/-`,
        },
      ],
    },
    {
      title: 'Cost',
      content: [
        {
          heading: 'Daily',
          right: `${convertToPounds(dashboard ? dashboard?.costCalculation?.dailyCost : 0)}`,
        },
        {
          heading: 'Weekly',
          right: `${convertToPounds(dashboard ? dashboard?.costCalculation?.weeklyCost : 0)}`,
        },
        {
          heading: 'Monthly',
          right: `${convertToPounds(dashboard ? dashboard?.costCalculation?.monthlyCost : 0)}`,
        },
      ],
    },
  ];

  const managerContent = [
    {
      title: 'Approved Timesheets',
      content: [
        {
          heading: dashboard ?
           `${dashboard?.timeSheetsStatusCounts?.approvedTimeSheets}/${dashboard?.timeSheetsStatusCounts?.totalTimeSheets}`:
           `-/-`,
        },
      ],
    },
    {
      title: 'Disapproved Timesheets',
      content: [
        {
          heading: dashboard ?
          `${dashboard?.timeSheetsStatusCounts?.disapprovedTimeSheets}/${dashboard?.timeSheetsStatusCounts?.totalTimeSheets}`:
          `-/-`,
        },
      ],
    },
    {
      title: 'Pending Timesheets',
      content: [
        {
          heading: dashboard ?
          `${dashboard?.timeSheetsStatusCounts?.pendingTimeSheets}/${dashboard?.timeSheetsStatusCounts?.totalTimeSheets}`:
          `-/-`,
        },
      ],
    },
  ];

  const handleDate = (dateVal) => {
    var date = new Date(dateVal);
    var print =
      (date.getMonth() > 8
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)) +
      '-' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
      '-' +
      date.getFullYear();
    return `${print}`;
  };

  const fetchTimesheets = () =>{
    if(timesheets?.length > 5){
      let sheets = [];
      for (let index = 0; index < timesheets.length, index < 5; index++) {
        const element = timesheets[index];
        sheets.push(element);
      }
      setResponseData(sheets ? sheets : []);
    }else{
      setResponseData(timesheets)
    }
  }

  const tableDataCol =
    role?.includes('Operative')
      ? tableData.timesheetsOperative
      : tableData.timesheets;
  const tableCols = tableDataCol?.columns.map((column) => {
    if (column?.link) {
      return {
        name: column.name,
        label: column.label,
      };
    } else if (column?.status) {
      return {
        name: column.name,
        label: column.label,
        options: {
          customBodyRender: (value) => {
            return <StatusChip text={value} />;
          },
        },
      };
    }else if (column?.week) {
      return {
        name: column.name,
        label: column.label,
        options: {
          customBodyRender: (value, tableMeta) => {
            return <div>
              {value !== undefined ?
               `${handleDate(value)} - ${handleDate(responseData[tableMeta.rowIndex]?.endDate)}` :
               ''
              }
              </div>;
          },
        },
      };
    }else {
      return {
        name: column.name,
        label: column.label,
      };
    }
  });

  useEffect(() => {
    if (role === 'Admin' || role === 'Site Manager') {
      dispatch(getDashboard());
      dispatch(getAllRecords({endpoint: `Timesheets/get-all`}));
    }
    {role === 'Admin' && dispatch(getGraphs())}
  },[role]);

  useEffect(() => {
    fetchTimesheets();
  },[timesheets]);

  return (
    <div
      style={{
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        overflowX: 'hidden',
      }}>
      {!role?.includes('Operative') && (
        <div
          style={{width: '100%', background: '#FFFCFC', padding: '5rem'}}
          className="admin">
          <section className="dashboard">
            <h1 className="sec-title">Dashboard Overview</h1>
            {role === 'Admin' && (
              <Grid container spacing={3} sx={{height: 'max-content'}}>
                <DashCard cardContent={adminContent} />
              </Grid>
            )}
            {role === 'Site Manager' && (
              <Grid
                container
                spacing={'5rem'}
                sx={{height: 'max-content'}}>
                <DashCard cardContent={managerContent} />
              </Grid>
            )}
          </section>
          <section className="timesheet">
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '.6rem',
              }}>
              <h1 className="sec-title">Timesheet Summary</h1>
              <button
                onClick={() => navigate('/timesheets')}
                className="view-all">
                View All
              </button>
            </div>
            <div>
              <Box className="section-table">
                <DataTable
                  newCols={tableCols}
                  newData={responseData ? responseData : []}
                  pagination={false}
                  //short
                />
              </Box>
            </div>
          </section>
          {role === 'Admin' && graphs && (
            <Grid container spacing={3} sx={{height: 'max-content'}}>
              <Graphs graph={graphs?.operativesGraph} label='Cost per Operative' color='#5485E5' />
              <Graphs graph={graphs?.sitesGraph} label='Cost per Site' color='#FF9F6A' />
            </Grid>
          )}
        </div>
      )}
      {role?.includes('Operative') && (
        <div
          style={{width: '100%', background: '#FFFCFC', padding: '5rem'}}
          className="admin">
          {/* <MyTimesheet /> */}
          {navigate('/my-timesheets')}
        </div>
      )}
    </div>
  );
}

import axiosInstance from '../../../utils/apiHandler/axiosInstance';

const getAll = async (payload) => {
  try {
    const response = await axiosInstance.get(
      payload?.endpoint,
      payload?.data,
    );
    return response.data;
  } catch (error) {
    return {error: error.response.data.message};
  }
};
const getAllTimesheet = async (payload) => {
  try {
    const response = await axiosInstance.get(
      payload?.endpoint,
      payload?.data,
    );
    return response.data;
  } catch (error) {
    return {error: error.response.data.message};
  }
};

const getTimesheet = async (payload) => {
  try {
    const response = await axiosInstance.get(
      payload?.endpoint,
      payload?.data,
    );
    return response.data;
  } catch (error) {
    return {error: error.response.data.message};
  }
};

const deleteTimesheet = async (payload) => {
  try {
    const response = await axiosInstance.delete(payload?.endpoint);
    return response.data;
  } catch (error) {
    return {error: error.response.data.message};
  }
};

const exportTimesheet = async (payload) => {
  try {
    const response = await axiosInstance.get(
      payload?.endpoint,
      {
        headers:
        {
            //'Content-Disposition': `attachment; filename=Timesheet-${+new Date()}.xlsx`,
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        responseType: 'blob',
      }
    );
    return response;
  } catch (error) {
    return {error: error.response.data.message};
  }
};
const exportOperativeTimesheet = async (payload) => {
  try {
    const response = await axiosInstance.get(
      payload?.endpoint,
      {
        headers:
        {
            //'Content-Disposition': `attachment; filename=Timesheet-${+new Date()}.xlsx`,
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        responseType: 'blob',
      }
    );
    return response;
  } catch (error) {
    return {error: error.response.data.message};
  }
};

const updateTimesheet = async (payload) => {
  try {
    const response = await axiosInstance.put(
      payload?.endpoint,
      payload?.data,
    );
    return response.data;
  } catch (error) {
    return {error: error.response.data.message};
  }
};

const submitTimesheet = async (payload) => {
  try {
    const response = await axiosInstance.put(
      payload?.endpoint,
      payload?.data,
    );
    return response.data;
  } catch (error) {
    return {error: error.response.data.message};
  }
};

const submitMultipleTimesheet = async (payload) => {
  try {
    const response = await axiosInstance.put(
      payload?.endpoint,
      payload?.data,
    );
    return response.data;
  } catch (error) {
    return {error: error.response.data.message};
  }
};

const createTimesheet = async (payload) => {
  try {
    const response = await axiosInstance.post(
      payload.endpoint,
      payload.data,
    );
    return response.data;
  } catch (error) {
    return {error: error.response.data.message};
  }
};

export const timesheetsService = {
  getAll,
  getAllTimesheet,
  getTimesheet,
  exportTimesheet,
  exportOperativeTimesheet,
  updateTimesheet,
  submitTimesheet,
  submitMultipleTimesheet,
  createTimesheet,
  deleteTimesheet,
};

import {createSlice} from '@reduxjs/toolkit';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {authServices} from '../../services/auth/auth.service';

let user = JSON.parse(localStorage.getItem('user'));

export const login = createAsyncThunk(
  'auth/login',
  async (payload, {rejectWithValue}) => {
    const response = await authServices.login(payload);
    if (response.success) {
      window.localStorage.setItem('user', JSON.stringify(response.data));
      window.localStorage.setItem(
        'token',
        JSON.stringify(response.data?.accessToken),
      );
      return response.data;
    } else {
      return rejectWithValue(response.error);
    }
  },
);

export const register = createAsyncThunk(
  'auth/register',
  async (payload, {rejectWithValue}) => {
    const response = await authServices.register(payload);
    if (response.data) {
      return response;
    } else {
      return rejectWithValue('error message');
    }
  },
);

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoggedin: user ? true : false,
    role: user?.user?.role,
    loading: false,
    error: '',
    message: '',
  },

  extraReducers: {
    // Login
    [login.pending]: (state) => {
      state.loading = true;
    },
    [login.fulfilled]: (state, action) => {
      state.loading = false;
      state.isLoggedin = true;
      state.user = action.payload;
      state.error = '';
    },
    [login.rejected]: (state, action) => {
      state.loading = false;
      state.isLoggedin = false;
      state.error = action.payload;
    },

    // Register
    [register.pending]: (state) => {
      state.loading = true;
      state.error = '';
    },
    [register.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error = '';
    },
    [register.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
  reducers: {
    authReset: (state) => {
      state.loading = false;
      state.message = '';
      state.error = '';
    },
    logout: (state) => {
      state.error = '';
      state.isLoggedin = false;
      localStorage.removeItem('user');
    },
    tryLogin: (state) => {
      state.isLoggedin = true;
    },
  },
});

export default authSlice.reducer;
export const {logout, tryLogin, authReset} = authSlice.actions;

import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {MdOutlineDelete, MdOutlineClose, MdOutlineEdit} from 'react-icons/md';
import {
  Box,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from '@mui/material';
import {toast} from 'react-toastify';

import DataTable from '../../../components/dataTable/DataTable';
import {
  getSites,
  deleteSite,
  createSite,
  markSites,
  sitesActions,
  updateSite,
} from '../../../data/reducers/app/sites.reducer';
import {removeSeconds} from '../../../utils/time';

import {tableData} from '../../../utils/tableData';
import AddModalSites from './AddModalSites';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import Navigation from '../../../components/navigation/Navigation';

import '../../../components/common-comp/CommonComp.css';
import ProButton from '../../../components/common-comp/ProButton';
import Title from '../../../components/common-comp/Title';

const customContent = {
  key: 'sites',
  title: 'Sites',
  filters: [{name: 'Filter by Site Name'}, {name: 'Filter by Site Code'}],
  cta: [
    {
      className: 'view-all',
      key: 'sites',
      value: '+ Add Site',
    },
  ],
};

export default function Sites() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {sites, error, message, paging} = useSelector(
    (state) => state.sites,
  );

  const [confirmPopup, setConfirmPopup] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  
  const [selectedRows, setSelectedRows] = useState(null);
  const [siteData, setSiteData] = useState(null);
  const [addPopup, setAddPopup] = useState(false);
  const [editRow, setEditRow] = useState(null);
  const [deleteId, setDeleteId] = useState('');
  const [SortField, setSortField] = useState('');

  const [filter, setFilter] = useState({
    PageNumber: 1,
    SortDir: 0,
    SortField: '',
    PageSize: 50,
    functional: true,
    siteName: '',
    siteCode: '',
  });

  const tableCols = tableData?.sites;

  useEffect(() => {
    handleFetch();
    if (filter?.siteName || filter?.siteCode) {
      setShowButtons(true);
    }
  }, [filter],[SortField]);

  useEffect(()=>{
    setSiteData(sites);
  },[sites]);

  useEffect(() => {
    setConfirmPopup(false);
    if (error) {
      toast.error(error);
    } else if (message) {
      toast.success(message);
    }
  }, [error, message]);

  const sortHandle = (name) => {
		//debugger
		if (SortField === name) {
			console.log("yes same field")
      setSortField(name);
			if (filter?.SortDir === 0){
        setFilter({...filter, SortDir: 1});
      }else{
        setFilter({...filter, SortDir: 0});
      }
		} else {
			setSortField(name);
			setFilter({...filter, SortDir: 1});
		}
	};

  const handleFetch = async () => {
    setAddPopup(false);
    setConfirmPopup(false);
    setShowButtons(false);
    setSiteData(null);
    dispatch(sitesActions.siteReset)
    await dispatch(
      getSites({
        endpoint: `Site/get-all?isFunctional=${filter?.functional}&SortField=${SortField}&SortDir=${filter?.SortDir}&PageNumber=${filter?.PageNumber}&PageSize=${filter?.PageSize}&siteName=${filter?.siteName}&siteCode=${filter?.siteCode}`,
      }),
    );
  };

  const addSite = () => {
    setAddPopup(true);
    setEditRow(null)
  };

  const deleteSiteFunction = async () => {
    if (deleteId !== '') {
      await dispatch(deleteSite({endpoint: `Site/delete/${deleteId}`}));
    }
    setDeleteId('');
    setFilter({
      PageNumber: 1,
      SortDir: 0,
      SortField: '',
      PageSize: 50,
      functional: true,
      siteName: '',
      siteCode: '',
    });
    dispatch(sitesActions.siteReset());
  };

  const submitModal = async (saveData) => {
    const {
      siteName,
      siteCode,
      siteAddress,
      siteManagerId,
      permittedHours,
    } = saveData;
    const data = {
      siteName,
      siteCode,
      siteAddress,
      siteManagerId : siteManagerId ? siteManagerId : null ,
      permittedHours,
    };
    if(editRow){
      dispatch(updateSite({endpoint: `Site/update/${editRow?.id}`, data}))?.then((res)=>{
        if(res?.type === 'sites/updateSite/fulfilled'){
          handleFetch();
        }
      })
    }else{
      dispatch(createSite({endpoint: `Site/create`, data}))?.then((res)=>{
        if(res?.type === 'sites/createSite/fulfilled'){
          handleFetch();
        }
      })
    }
  };

  const onRowSelectionChange = (
    currentRowsSelected,
    allRowsSelected,
    rowsSelected,
  ) => {
    //setShowButtons(true);
    setSelectedRows(rowsSelected);
  };

  const markNonfunctional = async() =>{
    const sitesArr = selectedRows?.map((index)=>{
      return sites[index];
    });
    await dispatch(markSites({
      endpoint:`Site/mark-sites?action=nonfunctional`,
      data: {sites: sitesArr}
    }));
    if(!error){
      setSelectedRows(null);
      handleFetch();
    }
  }
  
  const markFunctional = async() =>{
    const sitesArr = selectedRows?.map((index)=>{
      return sites[index];
    });
    await dispatch(markSites({
      endpoint:`Site/mark-sites?action=functional`,
      data: {sites: sitesArr}
    }));
    if(!error){
      setSelectedRows(null);
      handleFetch();
    }
  }

  let cols = tableCols?.columns.map((column) => {
    // <--- Creating table columns dynamically
    if (column?.link) {
      return {
        name: column.name,
        label: column.label,
        options: {
          customBodyRender: (value, tableMeta) => {
            return (
              <div
                onClick={() => {
                  let card = sites[tableMeta.rowIndex];
                  navigate(`/sites/details/${card?.id}`, {
                    state: card?.id,
                  });
                }}
                style={{cursor: 'pointer', textDecoration: 'underline' , textOverflow: 'ellipsis' }}>
                {value}
              </div>
            );
          },
          customHeadRender: () =>{
            return (
              <Title
                name={column?.name}
                title={column?.label}
                sortField={SortField}
                sortDir={filter?.SortDir}
                sortHandle={sortHandle}
				      />
            )
          },
        },
      };
    } else if (column?.time) {
      return {
        name: column.name,
        label: column.label,
        options: {
          customBodyRender: (value) => {
            return <div>{removeSeconds(value)}</div>;
          },
          customHeadRender: () =>{
            return (
              <Title
                name={column?.name}
                title={column?.label}
                sortField={SortField}
                sortDir={filter?.SortDir}
                sortHandle={sortHandle}
				      />
            )
          },
        },
      };
    }else if(column?.noSort){
      return {
        name: column.name,
        label: column.label,
      };
    }else {
      return {
        name: column.name,
        label: column.label,
        options: {
          customHeadRender: () =>{
            return (
              <Title
                name={column?.name}
                title={column?.label}
                sortField={SortField}
                sortDir={filter?.SortDir}
                sortHandle={sortHandle}
				      />
            )
          },
        }
      };
    }
  });

  cols?.push({
    name: 'Actions',
    options: {
      customBodyRender: (_, tableMeta) => {
        return (
          <span
            style={{
              padding: '.5rem',
              width: 'max-content',
            }}>
            <button
              onClick={() => {
                console.log(tableMeta);
                let temp = siteData;
                setAddPopup(true);
                setEditRow(temp[tableMeta.rowIndex]);
                //setDeleteId(tableData[tableMeta.rowIndex]?.id);
              }}
              className="table-buttons">
              <MdOutlineEdit size="1.8rem" />
            </button>
            <button
              className="table-buttons"
              onClick={() => {
                setConfirmPopup(true);
                setDeleteId(sites[tableMeta.rowIndex]?.id);
              }}>
              <MdOutlineDelete size="1.8rem" />
            </button>
          </span>
        );
      },
    },
  });

  const navigation = [
    {
      name: ' Sites ',
    },
  ]

  return (
    <div className="container">
      <div className="page-container">
        <Navigation path={navigation} />
        <Box className="section-top">
          <h1>{filter?.functional ? customContent.title : 'Non-Functional Sites'}</h1>
        </Box>

        <Grid
          container
          spacing={2}
          display="flex"
          justifyContent="space-between">
          <Grid item xl={2} lg={2} md={3} sm={4} xs={12}>
            <Typography variant="h6">Site Name</Typography>
            <TextField
              id="outlined-required"
              placeholder="Search..."
              fullWidth
              value={filter?.siteName}
              onChange={(e) => {
                setFilter({...filter, siteName: e.target.value,
                  PageNumber: 1
                });
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {showButtons && (
                      <IconButton
                        onClick={() => {
                          setFilter({...filter, siteName: ''});
                        }}>
                        <MdOutlineClose size="2rem" />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xl={2} lg={2} md={3} sm={4} xs={12}>
            <Typography variant="h6">Site Code</Typography>
            <TextField
              id="outlined-required"
              placeholder="Search..."
              fullWidth
              value={filter?.siteCode}
              onChange={(e) => {
                setFilter({...filter, siteCode: e.target.value,
                  PageNumber: 1
                });
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {showButtons && (
                      <IconButton
                        onClick={() => {
                          setFilter({...filter, siteCode: ''});
                        }}>
                        <MdOutlineClose size="2rem" />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid
            item
            xl={8}
            lg={8}
            md={6}
            sm={12}
            alignItems="end"
            justifyContent="flex-end"
            display="flex">
              {filter?.functional && (
                <Box
                alignItems="center"
                display="flex"
                alignContent="center"
                flexDirection="row-reverse">
                  <ProButton
                    text=" + Add Site"
                    color="#6eb066"
                    variant="contained"
                    handleClick={addSite}
                  />
                  <ProButton
                    text="View Non-functional Sites"
                    color="#cb272f"
                    variant="outlined"
                    handleClick={()=>{setFilter({...filter, functional: false})}}
                  />
                </Box>
              )}
            
          </Grid>
        </Grid>
        <section className="section-table">
          <DataTable
            newCols={cols}
            newData={siteData ? siteData : []}
            pagination={true}
            totalCount={paging.totalItemsCount}
            filter={filter}
            setFilter={setFilter}
            onRowSelectionChange={onRowSelectionChange}
          />
        </section>
        <section className='bottom-buttons'>
          {!filter?.functional && (
            <Box
            alignItems="center"
            display="flex"
            alignContent="center"
            flexDirection="row">
              <ProButton
                text=" Back"
                color="#cb272f"
                variant="outlined"
                handleClick={()=>{setFilter({...filter, functional: true})}}
              />
            </Box>
          )}
          <ProButton
            text={filter?.functional ? "Mark as Non-Functional" : "Mark as Functional"}
            color="#cb272f"
            variant="contained"
            handleClick={()=>{
              filter?.functional ? markNonfunctional() : markFunctional() ;
            }}
          />
        </section>
      </div>
      <ConfirmationDialog
        open={confirmPopup}
        text="Are you sure you want to delete this site?"
        handleClose={() => setConfirmPopup(false)}
        submitHandle={() => {
          deleteSiteFunction();
          setConfirmPopup(false);
        }}
      />
      <AddModalSites
        open={addPopup}
        handleClose={() => setAddPopup(false)}
        submitHandle={submitModal}
        keyVal={customContent.key}
        selectedRecord={editRow}
      />
    </div>
  );
}

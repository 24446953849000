import React, {useEffect} from 'react';
import {Box, Card, Typography, Grid, Divider} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';

import {getMe} from '../../data/reducers/app/operatives.reducer';
import ProButton from '../../components/common-comp/ProButton';

const operativeData = {
  heading: 'Operative Details',
  content: [
    {title: 'Operative Name'},
    {title: 'Current Job Role'},
    {title: 'Current Site Name'},
    {title: 'Site Code'},
  ],
};

function Details() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const role = JSON.parse(localStorage.getItem('user'))?.user?.role;

  const {meDetails} = useSelector((state) => state.operatives);

  useEffect(() => {
    dispatch(getMe({endpoint: `Employee/get-me`}));
  }, []);

  const showContent = role?.includes('Operative') ? operativeData : [];

  const sectionStyle = {
    padding: '2rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '1.3rem',
    borderBottom: '.1rem solid #E0E0E0',
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%">
      {role?.includes('Operative') && (
        <Card sx={{padding: '2.6rem ', width: '57rem', background:'#F8F8F8'}}>
          <Typography variant="h4" fontWeight="bold">
            {showContent?.heading}
          </Typography>
          <Box
            mt={3}
            style={{
              boxShadow: '0px 4px 9px rgba(0, 0, 0, 0.08)',
            }}>
            <Box style={sectionStyle}>
              <Typography variant="h6" fontWeight="bold">
                Operative Name
              </Typography>
              <Typography variant="h6" fontWeight="normal">
                {meDetails?.fullName}
              </Typography>
            </Box>
            <Box style={sectionStyle}>
              <Typography variant="h6" fontWeight="bold">
                Current Job Role
              </Typography>
              <Typography variant="h6" fontWeight="normal">
                {meDetails?.jobRole}
              </Typography>
            </Box>
            <Box style={sectionStyle}>
              <Typography variant="h6" fontWeight="bold">
                Current Site Name
              </Typography>
              <Typography variant="h6" fontWeight="normal">
                {meDetails?.siteName}
              </Typography>
            </Box>
            <Box style={sectionStyle}>
              <Typography variant="h6" fontWeight="bold">
                Site Code
              </Typography>
              <Typography variant="h6" fontWeight="normal">
                {meDetails?.siteCode}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" justifyContent="flex-end" mt={1}>
            <ProButton
              text="Back"
              color="#cb272f"
              variant="contained"
              mx0
              handleClick={() => navigate('/')}
            />
          </Box>
        </Card>
      )}
      {role === 'Site Manager' && (
        <Card sx={{padding: '2.6rem ', width: '80rem'}}>
          <Typography variant="h4" fontWeight="bold">
            {showContent?.heading}
          </Typography>
          <Grid container>
            <Grid item xs={12} md={5}>
              <Box style={sectionStyle}>
                <Typography variant="h6" fontWeight="bold">
                  Operative Name:
                </Typography>
                <Typography variant="h6" fontWeight="normal">
                  {meDetails?.fullName}
                </Typography>
              </Box>
              <Box style={sectionStyle}>
                <Typography variant="h6" fontWeight="bold">
                  Job Role:
                </Typography>
                <Typography variant="h6" fontWeight="normal">
                  {meDetails?.jobRole}
                </Typography>
              </Box>
              <Box style={sectionStyle}>
                <Typography variant="h6" fontWeight="bold">
                  Email Id:
                </Typography>
                <Typography variant="h6" fontWeight="normal">
                  {meDetails?.email}
                </Typography>
              </Box>
            </Grid>

            <Grid item md={2} display="flex" justifyContent="center">
              <Divider orientation="vertical" sx={{marginRight: '-1px'}} />
            </Grid>

            <Grid item xs={12} md={5}>
              <Box style={sectionStyle}>
                <Typography variant="h6" fontWeight="bold">
                  Phone Number:
                </Typography>
                <Typography variant="h6" fontWeight="normal">
                  {meDetails?.phoneNumber}
                </Typography>
              </Box>
              <Box style={sectionStyle}>
                <Typography variant="h6" fontWeight="bold">
                  Site Name:
                </Typography>
                <Typography variant="h6" fontWeight="normal">
                  {meDetails?.siteName}
                </Typography>
              </Box>
              <Box style={sectionStyle}>
                <Typography variant="h6" fontWeight="bold">
                  Site Code:
                </Typography>
                <Typography variant="h6" fontWeight="normal">
                  {meDetails?.siteCode}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="flex-end" mt={1}>
            <ProButton
              text="Back"
              color="#cb272f"
              variant="contained"
              mx0
              handleClick={() => navigate('/')}
            />
          </Box>
        </Card>
      )}
    </Box>
  );
}

export default Details;

import {timesheetsService} from '../../services/app/timesheets.service';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

export const getAllRecords = createAsyncThunk(
  'timesheets/getAll',
  async (payload, {rejectWithValue}) => {
    const response = await timesheetsService.getAll(payload);

    if (response.status === 'Success') {
      return response;
    } else {
      return rejectWithValue(response.error);
    }
  },
);
export const getAllTimesheet = createAsyncThunk(
  'timesheets/getAllTimesheet',
  async (payload, {rejectWithValue}) => {
    const response = await timesheetsService.getAllTimesheet(payload);

    if (response.status === 'Success') {
      return response;
    } else {
      return rejectWithValue(response.error);
    }
  },
);

export const getTimesheet = createAsyncThunk(
  'timesheets/getTimesheet',
  async (payload, {rejectWithValue}) => {
    const response = await timesheetsService.getTimesheet(payload);

    if (response.success) {
      return response;
    } else {
      return rejectWithValue(response.error);
    }
  },
);

export const exportTimesheet = createAsyncThunk(
  'timesheets/exportTimesheet',
  async (payload, {rejectWithValue}) => {
    const response = await timesheetsService.exportTimesheet(payload);

    if (response) {
      return response.data;
    } else {
      return rejectWithValue(response.error);
    }
  },
);
export const exportOperativeTimesheet = createAsyncThunk(
  'timesheets/exportOperativeTimesheet',
  async (payload, {rejectWithValue}) => {
    const response = await timesheetsService.exportOperativeTimesheet(payload);

    if (response) {
      return response.data;
    } else {
      return rejectWithValue(response.error);
    }
  },
);

export const updateTimesheet = createAsyncThunk(
  'timesheets/updateTimesheet',
  async (payload, thunkAPI) => {
    const response = await timesheetsService.updateTimesheet(payload);

    if (response.success) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response.error ? response.error : 'Something Went Wrong');
    }
  },
);

export const submitTimesheet = createAsyncThunk(
  'timesheets/submitTimesheet',
  async (payload, thunkAPI) => {
    const response = await timesheetsService.submitTimesheet(payload);
    if (response.success) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response.error);
    }
  },
);

export const submitMultipleTimesheet = createAsyncThunk(
  'timesheets/submitMultipleTimesheet',
  async (payload, thunkAPI) => {
    const response = await timesheetsService.submitMultipleTimesheet(
      payload,
    );
    if (response.success) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response.error);
    }
  },
);

export const createTimesheet = createAsyncThunk(
  'timesheets/createTimesheet',
  async (payload, thunkAPI) => {
    const response = await timesheetsService.createTimesheet(
      payload,
    );
    if (response.success) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response.error);
    }
  },
);

export const deleteTimesheet = createAsyncThunk(
  'timesheets/deleteTimesheet',
  async (payload, thunkAPI) => {
    const response = await timesheetsService.deleteTimesheet(payload);

    if (response.success) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response.error);
    }
  },
);

const timesheetSlice = createSlice({
  name: 'timesheets',
  initialState: {
    timesheets: [],
    operativeTimesheet: [],
    timesheetDetail: [],
    newTimesheet: null,
    loading: false,
    error: '',
    message: '',
  },
  reducers: {
    timesheetsReset: (state) => {
      state.error = '';
      state.message = '';
      state.loading = false;
      state.timesheets = [];
      state.operativeTimesheet = [];
      state.timesheetDetail = [];
      state.newTimesheet = null;
    },
  },
  extraReducers: {
    // Getting all
    [getAllRecords.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [getAllRecords.fulfilled]: (state, action) => {
      state.timesheets = action.payload.data;
      state.paging = action.payload?.paging;
      state.loading = false;
      state.error = '';
    },
    [getAllRecords.rejected]: (state, action) => {
      state.error = action?.payload;
      state.loading = false;
      state.message = '';
    },
    // Getting all timesheets
    [getAllTimesheet.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [getAllTimesheet.fulfilled]: (state, action) => {
      state.operativeTimesheet = action.payload.data;
      state.paging = action.payload?.paging;
      state.loading = false;
      state.error = '';
    },
    [getAllTimesheet.rejected]: (state) => {
      state.loading = false;
      state.message = '';
    },

    // Getting timesheet details
    [getTimesheet.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [getTimesheet.fulfilled]: (state, action) => {
      state.timesheetDetail = action.payload.data;
      state.loading = false;
      state.error = '';
    },
    [getTimesheet.rejected]: (state) => {
      state.loading = false;
      state.message = '';
    },

    // exporting timesheet
    [exportTimesheet.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [exportTimesheet.fulfilled]: (state, action) => {
      //state.timesheetDetail = action.payload.data;
      state.loading = false;
      state.error = '';
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([action?.payload]));
      link.download = `Timesheet.xlsx`;
      link.click();
      link.remove();
    },
    [exportTimesheet.rejected]: (action, state) => {
      state.error = action?.payload;
      state.loading = false;
      state.message = '';
    },
    // exporting operative timesheet
    [exportOperativeTimesheet.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [exportOperativeTimesheet.fulfilled]: (state, action) => {
      //state.timesheetDetail = action.payload.data;
      state.loading = false;
      state.error = '';
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([action?.payload]));
      link.download = `Operative-timesheet.xlsx`;
      link.click();
      link.remove();
    },
    [exportOperativeTimesheet.rejected]: (action, state) => {
      state.error = action?.payload;
      state.loading = false;
      state.message = '';
    },

    // Submit timesheet
    [submitTimesheet.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [submitTimesheet.fulfilled]: (state, action) => {
      state.message = action?.payload?.message;
      state.loading = false;
      state.error = '';
    },
    [submitTimesheet.rejected]: (state, action) => {
      state.error = action?.payload;
      state.loading = false;
      state.message = '';
    },

    // Submit multiple timesheet
    [submitMultipleTimesheet.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [submitMultipleTimesheet.fulfilled]: (state, action) => {
      state.message = action?.payload?.message;
      state.loading = false;
      state.error = '';
    },
    [submitMultipleTimesheet.rejected]: (state, action) => {
      state.error = action?.payload;
      state.loading = false;
      state.message = '';
    },

    // Deleting Timesheet
    [deleteTimesheet.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [deleteTimesheet.fulfilled]: (state, action) => {
      state.message = action.payload.message;
      state.loading = false;
      state.error = '';
    },
    [deleteTimesheet.rejected]: (state, action) => {
      state.error = action?.payload;
      state.loading = false;
      state.message = '';
    },

    // Update Timesheet
    [updateTimesheet.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [updateTimesheet.fulfilled]: (state, action) => {
      state.message = action?.payload?.message;
      state.loading = false;
      state.error = '';
    },
    [updateTimesheet.rejected]: (state, action) => {
      state.error = action?.payload;
      state.loading = false;
      state.message = '';
    },

    // Create Timesheet
    [createTimesheet.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [createTimesheet.fulfilled]: (state, action) => {
      state.message = action?.payload?.message;
      state.newTimesheet = action?.payload?.data;
      state.loading = false;
      state.error = '';
    },
    [createTimesheet.rejected]: (state, action) => {
      state.error = action?.payload;
      state.loading = false;
      state.message = '';
    },
  },
});

export const timesheetsActions = timesheetSlice.actions;
export default timesheetSlice.reducer;

import React, {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux/es/exports';
import {Box} from '@mui/material';
import {toast} from 'react-toastify';

import {getSingleRateCard} from '../../../data/reducers/app/rateCards.reducer';
import {convertToPounds} from '../../../utils/currency';

import DataTable from '../../../components/dataTable/DataTable';
import Navigation from '../../../components/navigation/Navigation';

import '../../../components/common-comp/CommonComp.css';
import ProButton from '../../../components/common-comp/ProButton';

const RateCardsDetails = () => {
  const {id} = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {singleRateCard, error, message} = useSelector(
    (state) => state.rateCards,
  );

  useEffect(() => {
    dispatch(
      getSingleRateCard({endpoint: `RateCards/get-ratecard/${id}`}),
    );
  }, [id]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    } else if (message) {
      toast.success(message);
    }
  }, [error, message]);

  const cols = [
    {
      name: 'id',
      label: 'Sr. No.',
    },
    {
      name: 'jobRole',
      label: 'Job Role',
    },
    {
      name: 'nightShiftRate',
      label: 'Night Shift Rate',
      options: {
        customBodyRender: (value) => {
          return <div>{convertToPounds(value)}</div>;
        },
      },
    },
    {
      name: 'overtimeRate',
      label: 'Overtime Rate',
      options: {
        customBodyRender: (value) => {
          return <div>{convertToPounds(value)}</div>;
        },
      },
    },
    {
      name: 'regularRate',
      label: 'Regular Rate',
      options: {
        customBodyRender: (value) => {
          return <div>{convertToPounds(value)}</div>;
        },
      },
    },
  ];

  const navigation = [
    {
      name: ' Rate Cards / ',
      path: '/rate-cards'
    },
    {
      name: 'details / ',
    },
  ]

  return (
    <div className="page-container">
      <Navigation path={navigation} />

      <section className="section-top">
        <h1>{singleRateCard?.rateCardName}</h1>
        <Box spacing={2} flexDirection="row-reverse" display="flex">
          <ProButton
            text="Edit"
            color="#cb272f"
            handleClick={() => {
              navigate(`/rate-cards/edit/${id}`, {
                state: singleRateCard,
              });
            }}
            variant="contained"
            mx0
          />
        </Box>
      </section>

      <section className="section-table">
        <DataTable
          newCols={cols}
          newData={singleRateCard?.rateDetailsResponse}
        />
      </section>
    </div>
  );
};

export default RateCardsDetails;

import {operativesService} from '../../services/app/operatives.service';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

export const getAllOperatives = createAsyncThunk(
  'operatives/getAllOperatives',
  async (payload, {rejectWithValue}) => {
    const response = await operativesService.getAllOperatives(payload);

    if (response.status === 'Success') {
      return response;
    } else {
      return rejectWithValue(response.error);
    }
  },
);

export const createOperative = createAsyncThunk(
  'operatives/createOperative',
  async (payload, thunkAPI) => {
    const response = await operativesService.createOperative(payload);
    console.log('create response: ', response);
    if (response.success) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response.error);
    }
  },
);

export const updateOperative = createAsyncThunk(
  'operatives/updateOperative',
  async (payload, {rejectWithValue}) => {
    const response = await operativesService.updateOperative(payload);

    if (response.success) {
      return response;
    } else {
      return rejectWithValue(response.error);
    }
  },
);

export const getMe = createAsyncThunk(
  'operatives/getMe',
  async (payload, {rejectWithValue}) => {
    const response = await operativesService.getMe(payload);

    if (response.success) {
      return response;
    } else {
      return rejectWithValue(response.error);
    }
  },
);

export const deleteOperative = createAsyncThunk(
  'operatives/deleteOperative',
  async (payload, thunkAPI) => {
    const response = await operativesService.deleteOperative(payload);

    if (response.success) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response.error);
    }
  },
);

export const exportOperatives = createAsyncThunk(
  'operatives/exportOperatives',
  async (payload, {rejectWithValue}) => {
    const response = await operativesService.exportOperatives(payload);

    if (response) {
      return response.data;
    } else {
      return rejectWithValue(response.error);
    }
  },
);

export const resetPassword = createAsyncThunk(
  'operatives/resetPassword',
  async (payload, {rejectWithValue}) => {
    const response = await operativesService.resetPassword(payload);

    if (response.success) {
      return response;
    } else {
      return rejectWithValue(response.error);
    }
  },
);

const operativesSlice = createSlice({
  name: 'operatives',
  initialState: {
    meDetails: {},
    operatives: [],
    loading: false,
    error: '',
    message: '',
  },
  reducers: {
    operativeReset: (state) => {
      state.error = '';
      state.message = '';
      state.loading = false;
    },
  },
  extraReducers: {
    // Getting Operative
    [getAllOperatives.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [getAllOperatives.fulfilled]: (state, action) => {
      state.operatives = action.payload.data;
      state.paging = action.payload?.paging;
      state.loading = false;
      state.error = '';
    },
    [getAllOperatives.rejected]: (state, action) => {
      state.error = action?.payload;
      state.loading = false;
      state.message = '';
    },

    // Create Operative
    [createOperative.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [createOperative.fulfilled]: (state, action) => {
      toast.success(action?.payload?.message);
      //state.message = action?.payload?.message;
      state.loading = false;
      state.error = '';
    },
    [createOperative.rejected]: (state, action) => {
      toast.error(action?.payload)
      //state.error = action?.payload;
      state.loading = false;
      state.message = '';
    },

    // Update Operative
    [updateOperative.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [updateOperative.fulfilled]: (state, action ) => {
      toast.success(action?.payload?.message);
      //state.message = action?.payload?.message;
      state.loading = false;
      state.error = '';
    },
    [updateOperative.rejected]: (state, action) => {
      toast.error(action?.payload)
      //state.error = action?.payload;
      state.loading = false;
      state.message = '';
    },

    // Get details
    [getMe.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [getMe.fulfilled]: (state, action) => {
      state.meDetails = action?.payload?.data;
      state.loading = false;
      state.error = '';
    },
    [getMe.rejected]: (state, action) => {
      state.error = action?.payload;
      state.loading = false;
      state.message = '';
    },

    // Deleting Operative
    [deleteOperative.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [deleteOperative.fulfilled]: (state, action) => {
      toast.success(action?.payload?.message);
      //state.message = action?.payload?.message;
      state.loading = false;
      state.error = '';
    },
    [deleteOperative.rejected]: (state, action) => {
      toast.error(action?.payload?.error)
      //state.error = action?.payload?.error;
      state.loading = false;
      state.message = '';
    },

    // exporting operatives
    [exportOperatives.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [exportOperatives.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = '';
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([action?.payload]));
      link.download = `Operatives.xlsx`;
      link.click();
      link.remove();
    },
    [exportOperatives.rejected]: (action, state) => {
      state.error = action?.payload;
      state.loading = false;
      state.message = '';
    },

    // Reset Password
    [resetPassword.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [resetPassword.fulfilled]: (state, action) => {
      state.message = action?.payload?.message;
      state.loading = false;
      state.error = '';
    },
    [resetPassword.rejected]: (state, action) => {
      state.error = action?.payload;
      state.loading = false;
      state.message = '';
    },
  },
});

export const operativesActions = operativesSlice.actions;
export default operativesSlice.reducer;

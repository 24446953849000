import React, {useEffect} from 'react';
import {MdOutlineClose} from 'react-icons/md';
import {
    Typography, 
    IconButton,
    TextField,
    Box,
    Dialog,
    DialogContent,
    Divider,
} from '@mui/material';
import {useForm} from 'react-hook-form';
import ProButton from '../../../components/common-comp/ProButton';



function AddRateCard({
    open, 
    handleClose, 
    submitHandle,
    selectedRecord,
}) {

    const {handleSubmit, register, reset, formState, setValue} =
    useForm({shouldUnregister: true});

  const handleClick = (data) => {
    console.log('handleClick');
    if (selectedRecord) {
      submitHandle(data, true);
    } else {
      submitHandle(data, false);
    }
  };

  const closeModal = () => {
    handleClose();
  };

  useEffect(() => {
    if (selectedRecord) {
      for (const [key, value] of Object.entries(selectedRecord)) {
        setValue(key, value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    } else {
      reset();
    }
  }, [open]);

  useEffect(() => {
    // <---for Edit Operative Modal
    console.log('');
    if (selectedRecord) {
      for (const [key, value] of Object.entries(selectedRecord)) {
        setValue(key, value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    }

  }, [selectedRecord]);

  useEffect(() => {
    reset();
  }, []);


  return (
    <Dialog maxWidth="sm" open={open}>
      <DialogContent dividers>
      <Box sx={{width:'300px'}}>
          <Box display="flex" justifyContent="space-between" mb={2}>
            {!selectedRecord ? (
              <Typography variant="h4">Add Rate Card</Typography>
            ) : (
              <Typography variant="h4">Edit Rate Card</Typography>
            )}
            <IconButton onClick={closeModal}>
              <MdOutlineClose size="2rem" />
            </IconButton>
          </Box>
          <Divider />

          <Box mt={3} mb={2}>
            <form onSubmit={handleSubmit(handleClick)} className="add-form">
              
              <Box className="rate-card-modal">
                <Typography variant="h6">Rate Card Name</Typography>
                <TextField
                  {...register(`rateCardName`, {required:true})}
                  required
                  id="outlined-required"
                  placeholder="Rate Card Name"
                  fullWidth
                />
                <span className="err-form">
                  {formState.errors?.rateCardName ? 
                    'Rate Card Name is required' :
                    ''
                  }
                </span>
              </Box>
              <Divider />
              {/* <button
                className="form-button"
                onClick={handleSubmit(handleClick)}>
                Save
              </button> */}
              <ProButton
                text={selectedRecord ? 'Edit Rate Card' : 'Add Rate Card'}
                color="#6eb066"
                handleClick={handleSubmit(handleClick)}
                variant="contained"
              />
            </form>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default AddRateCard;

import {Grid, Card, CardContent} from '@mui/material';
import React from 'react';
import './card.css';

const DashCard = ({cardContent}) => {
  const renderCards = cardContent.map((card, i) => {
    return (
      <Grid item xs={12} sm={4} key={i} sx={{height: '100%'}}>
        <Card
          sx={{
            //minWidth: '26rem',
            padding: '1.2rem',
            boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.08);',
            borderRadius: '.4rem',
            border: '1px solid #E0E0E0',
          }}
        >
          <CardContent>
            <h2 className="card-content-heading">{card.title}</h2>
            <div className="card-content-content">
              {card.content.map((section, i) => {
                return (
                  <section key={i}>
                    <span>{section.heading}</span>
                    <span>{section.right}</span>
                  </section>
                );
              })}
            </div>
          </CardContent>
        </Card>
      </Grid>
    );
  });

  return <>{renderCards}</>;
};
export default DashCard;

import React,{useEffect, useState} from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import {useDispatch, useSelector} from 'react-redux';
import {logout} from '../data/reducers/auth/auth.reducer';
import {toast} from 'react-toastify';
import ResetPass from './ResetPass';
import { resetPassword } from '../data/reducers/app/operatives.reducer';
import './fadeMenu.css';

export default function FadeMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [addPopup, setAddPopup] = useState(false);
  const dispatch = useDispatch();
  const {error, message} = useSelector(
    (state) => state.operatives,
  );
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    dispatch(logout());
  };

  const resetPasswordFn = async(data) =>{
    await dispatch(resetPassword({
      endpoint:`Employee/resetpassword`,
      data
    }));
    if(!error){
      setAddPopup(false);
      setAnchorEl(null);
    }
  }

  const user = JSON.parse(localStorage.getItem('user'));
  const iconStyles = {
    color: '#000',
  };

  const buttonStyle = {
    minWidth: 'max-content',
    padding: '.8rem .8rem',
    color: '#000',
    borderRadius: '5rem',
    cursor: 'pointer',
    border: 'none',
    ':hover': {
      background: '#0d0d0d',
    },
    display: 'flex',
    alignItems: 'center',
    gap: '.8rem',
    marginLeft: 'auto'
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
    } else if (message) {
      toast.success(message);
    }
  }, [error, message]);

  return (
    <div >
      <button
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={buttonStyle}>
        <div style={iconStyles}>
          <PersonOutlinedIcon />
        </div>
        <div className='name'>{user?.user?.fullName}</div>
      </button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
        TransitionComponent={Fade}
        placement="bottom-end">
        <MenuItem onClick={()=>{setAddPopup(true)}} sx={{fontSize: '1.3rem'}}>
          Reset Password
        </MenuItem>
        <MenuItem onClick={handleClose} sx={{fontSize: '1.3rem'}}>
          Logout
        </MenuItem>
      </Menu>
      <ResetPass
        open={addPopup}
        handleClose={() => setAddPopup(false)}
        submitHandle={resetPasswordFn}
      />
    </div>
  );
}

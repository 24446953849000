import React, {useEffect, useState} from 'react';
import {MdOutlineClose} from 'react-icons/md';
import {
    Typography, 
    IconButton,
    TextField,
    Box,
    Dialog,
    DialogContent,
    Divider,
} from '@mui/material';
import {useForm} from 'react-hook-form';
import ProButton from './common-comp/ProButton';



function ResetPass({
    open, 
    handleClose, 
    submitHandle,
    selectedRecord,
}) {
  
  const [helperText, setHelperText] = useState('');

  const {handleSubmit, register, formState, reset, setValue} =
  useForm({shouldUnregister: true});

  const handleClick = (data) => {
   submitHandle(data);
  };

  const closeModal = () => {
    handleClose();
  };

  const validatePass = (password) => {
        
    let re = {
      digit: /[0-9]/,
      character: /[!@#$%^&*]/,
      full: /^[A-Za-z0-9]/,
      uppercase: /(?=.*[A-Z])/,
      lowercase: /(?=.*[a-z])/,
    };

    if (!re.digit.test(password)) {
      setHelperText('Must contain a number');
      return false;
    } else if (!re.character.test(password)) {
      setHelperText('Must contain a special character');
      return false;
    } else if (!re.uppercase.test(password)) {
      setHelperText('Must contain an Upper Case letter');
      return false;
    } else if (!re.lowercase.test(password)) {
      setHelperText('Must contain a lower case letter');
      return false;
    }else if(password?.length < 8){
      setHelperText('Must contain more than 8 characters');
      return false;
    }else if(password.indexOf('/') > -1){
      setHelperText('Should not contain / or #');
      return false;
    }else if(password.indexOf('#') > -1){
      setHelperText('Should not contain / or #');
      return false;
    }else {
      setHelperText('');
      return true;
    }
};

  useEffect(() => {
    if (selectedRecord) {
      for (const [key, value] of Object.entries(selectedRecord)) {
        setValue(key, value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    } else {
      reset();
    }
  }, [open]);

  useEffect(() => {
    // <---for Edit Operative Modal
    console.log('');
    if (selectedRecord) {
      for (const [key, value] of Object.entries(selectedRecord)) {
        setValue(key, value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    }

  }, [selectedRecord]);

  useEffect(() => {
    reset();
  }, []);


  return (
    <Dialog maxWidth="sm" open={open}>
      <DialogContent dividers>
      <Box sx={{width:'300px'}}>
          <Box display="flex" justifyContent="space-between" mb={2}>
            
            <IconButton onClick={closeModal} sx={{marginLeft:'auto'}}>
              <MdOutlineClose size="2rem" />
            </IconButton>
          </Box>
          <Divider />

          <Box mt={3} mb={2}>
            <form onSubmit={handleSubmit(handleClick)} className="add-form">
              
              <Box>
                <Typography variant="h6">Current Password</Typography>
                <TextField
                  {...register(`currentPassword`)}
                  required
                  id="outlined-required"
                  placeholder="Current Password"
                  fullWidth
                />
              </Box>
              <Box>
                <Typography variant="h6">New Password</Typography>
                <TextField
                  {...register(`newPassword`, {
                    required: true,
                    validate: {
                      pass: (value) => validatePass(value),
                    },
                  })}
                  required
                  id="outlined-required"
                  placeholder="New Password"
                  fullWidth
                />
                <span className="err-form">
                  {formState.errors?.newPassword?.type === 'pass'
                    ? `${helperText}`
                    : ''}
                </span>
              </Box>
              <Divider />
              <ProButton
                text={'Reset Password'}
                color="#cb272f"
                handleClick={handleSubmit(handleClick)}
                variant="contained"
              />
            </form>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default ResetPass;

import {rateCardsService} from '../../services/app/rateCards.service';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

export const getAllRateCards = createAsyncThunk(
  'rateCards/getAllRateCards',
  async (payload, {rejectWithValue}) => {
    const response = await rateCardsService.getAll(payload);

    if (response.status === 'Success') {
      return response;
    } else {
      return rejectWithValue(response.error);
    }
  },
);

export const deleteRateCard = createAsyncThunk(
  'rateCards/deleteRateCard',
  async (payload, thunkAPI) => {
    const response = await rateCardsService.deleteRateCard(payload);

    if (response.success) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response.error);
    }
  },
);

export const saveRateCard = createAsyncThunk(
  'rateCards/saveRateCard',
  async (payload, thunkAPI) => {
    const response = await rateCardsService.saveRateCard(payload);

    if (response.success) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response.error);
    }
  },
);

export const updateRateCard = createAsyncThunk(
  'rateCards/updateRateCard',
  async (payload, {rejectWithValue}) => {
    const response = await rateCardsService.updateRateCard(payload);

    if (response.success) {
      return response;
    } else {
      return rejectWithValue(response.error);
    }
  },
);

export const getSingleRateCard = createAsyncThunk(
  'rateCards/getSingleRateCard',
  async (payload, thunkAPI) => {
    const response = await rateCardsService.getSingleRateCard(payload);

    if (response.success) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response.error);
    }
  },
);

//---Job Rate---
export const createJobRate = createAsyncThunk(
  'rateCards/createJobRate',
  async (payload, thunkAPI) => {
    const response = await rateCardsService.createJobRate(payload);

    if (response.success) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response.error);
    }
  },
);

export const updateJobRate = createAsyncThunk(
  'rateCards/updateJobRate',
  async (payload, {rejectWithValue}) => {
    const response = await rateCardsService.updateJobRate(payload);

    if (response.success) {
      return response;
    } else {
      return rejectWithValue(response.error);
    }
  },
);
export const deleteJobRate = createAsyncThunk(
  'rateCards/deleteJobRate',
  async (payload, thunkAPI) => {
    const response = await rateCardsService.deleteJobRate(payload);

    if (response.success) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response.error);
    }
  },
);

//---Job Role---

export const deleteJobRole = createAsyncThunk(
  'rateCards/deleteJobRole',
  async (payload, thunkAPI) => {
    const response = await rateCardsService.deleteJobRole(payload);

    if (response.success) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response.error);
    }
  },
);

export const createJobRole = createAsyncThunk(
  'rateCards/createJobRole',
  async (payload, thunkAPI) => {
    const response = await rateCardsService.createJobRole(payload);

    if (response.success) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response.error);
    }
  },
);

export const updateJobRole = createAsyncThunk(
  'rateCards/updateJobRole',
  async (payload, {rejectWithValue}) => {
    const response = await rateCardsService.updateJobRole(payload);

    if (response.success) {
      return response;
    } else {
      return rejectWithValue(response.error);
    }
  },
);


const rateCardSlice = createSlice({
  name: 'rateCards',
  initialState: {
    rateCards: [],
    paging: {},
    singleRateCard: {},
    loading: false,
    error: '',
    message: '',
  },
  reducers: {
    rateCardReset: (state) => {
      state.error = '';
      state.message = '';
      state.loading = false;
    },
  },
  extraReducers: {
    // Getting Rate Cards
    [getAllRateCards.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [getAllRateCards.fulfilled]: (state, action) => {
      state.rateCards = action.payload.data;
      state.paging = action.payload.paging;
      state.loading = false;
      state.error = '';
    },
    [getAllRateCards.rejected]: (state, action) => {
      state.error = action.payload.error;
      state.loading = false;
      state.message = '';
    },

    // Deleting Rate Card
    [deleteRateCard.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [deleteRateCard.fulfilled]: (state, action) => {
      state.message = action.payload.message;
      state.loading = false;
      state.error = '';
    },
    [deleteRateCard.rejected]: (state, action) => {
      state.error = action?.payload;
      state.loading = false;
      state.message = '';
    },

    // Saving Rate Card
    [saveRateCard.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [saveRateCard.fulfilled]: (state, action) => {
      state.message = action.payload.message;
      state.loading = false;
      state.error = '';
    },
    [saveRateCard.rejected]: (state, action) => {
      state.error = action?.payload;
      state.loading = false;
      state.message = '';
    },

    // Get Single Rate Card
    [getSingleRateCard.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [getSingleRateCard.fulfilled]: (state, action) => {
      state.singleRateCard = action.payload.data;
      state.loading = false;
      state.error = '';
    },
    [getSingleRateCard.rejected]: (state, action) => {
      state.error = action.payload.error;
      state.loading = false;
      state.message = '';
    },

    // Update Rate Card
    [updateRateCard.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [updateRateCard.fulfilled]: (state, action) => {
      state.message = action.payload.message;
      state.loading = false;
      state.error = '';
    },
    [updateRateCard.rejected]: (state, action) => {
      state.error = action.payload.error;
      state.loading = false;
      state.message = '';
    },

    //---Job Rate---
    [deleteJobRate.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [deleteJobRate.fulfilled]: (state, action) => {
      state.message = action.payload.message;
      state.loading = false;
      state.error = '';
    },
    [deleteJobRate.rejected]: (state, action) => {
      state.error = action.payload.error;
      state.loading = false;
      state.message = '';
    },

    [updateJobRate.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [updateJobRate.fulfilled]: (state, action) => {
      state.message = action.payload.message;
      state.loading = false;
      state.error = '';
    },
    [updateJobRate.rejected]: (state, action) => {
      state.error = action.payload.error;
      state.loading = false;
      state.message = '';
    },

    [createJobRate.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [createJobRate.fulfilled]: (state, action) => {
      state.message = action.payload.message;
      state.loading = false;
      state.error = '';
    },
    [createJobRate.rejected]: (state, action) => {
      state.error = action.payload.error;
      state.loading = false;
      state.message = '';
    },

    //---Job Role---
    [deleteJobRole.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [deleteJobRole.fulfilled]: (state, action) => {
      state.message = action.payload.message;
      state.loading = false;
      state.error = '';
    },
    [deleteJobRole.rejected]: (state, action) => {
      state.error = action.payload.error;
      state.loading = false;
      state.message = '';
    },

    [updateJobRole.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [updateJobRole.fulfilled]: (state, action) => {
      state.message = action.payload.message;
      state.loading = false;
      state.error = '';
    },
    [updateJobRole.rejected]: (state, action) => {
      state.error = action.payload.error;
      state.loading = false;
      state.message = '';
    },

    [createJobRole.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.error = '';
    },
    [createJobRole.fulfilled]: (state, action) => {
      state.message = action.payload.message;
      state.loading = false;
      state.error = '';
    },
    [createJobRole.rejected]: (state, action) => {
      state.error = action.payload.error;
      state.loading = false;
      state.message = '';
    },
  },
});

export const rateCardActions = rateCardSlice.actions;
export default rateCardSlice.reducer;

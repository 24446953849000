export const details = {
  employees: {
    key: 'employees',
    title: 'Joe James',
    topRight: true,
    infoTitle: '',
    infoContent: [
      {
        heading: 'Operative Name',
        value: 'Joe James',
      },
      {
        heading: 'Email ID',
        value: 'Joe123@mail.com',
      },
      {
        heading: 'Phone No.',
        value: '989767',
      },
    ],
    sectionThird: true,
    filters: [
      {name: 'Filter by Site Code/ Site Name'},
      {name: 'Filter by Job Role'},
      {name: 'Filter by Approval Status'},
      {name: 'Filter by Week'},
    ],
    sectionThreeButtons: [
      {
        className: 'sec-outlined',
        key: 'timesheets',
        value: 'Send Email',
      },
      {
        className: 'contained',
        key: 'timesheets',
        value: 'Export Data',
      },
    ],
  },
  timesheets: {
    key: 'timesheets',
    title: 'Joe James -Timesheets',
    topRight: false,
    infoTitle: 'Rate Card ABC',
    infoContent: [
      {
        heading: 'Operative Name',
        value: 'Joe James',
      },
      {
        heading: 'Email ID',
        value: 'Joe123@mail.com',
      },
      {
        heading: 'Phone No.',
        value: '989767',
      },
    ],
    sectionThird: false,
    filters: [
      {
        name: 'May 01 - May 07',
        dropdown: true,
        placeholder: 'May 01 - May 07',
      },
    ],
    cta: [
      {
        className: 'button-outline',
        key: 'timesheets',
        value: 'Cancel',
      },
      {
        className: 'contained',
        key: 'timesheets',
        value: 'Save',
      },
    ],
  },
  sites: {
    key: 'sites',
    title: 'Site Details',
    topRight: false,
    infoTitle: 'Rate Card Abc',
    infoContent: [
      {
        heading: 'Operative Name',
        value: 'Joe James',
      },
      {
        heading: 'Email ID',
        value: 'Joe123@mail.com',
      },
      {
        heading: 'Phone No.',
        value: '989767',
      },
    ],
    sectionThird: false,
  },
  rateCards: {
    key: 'rateCrads',
    title: 'Rate Card Abc',
    topRight: false,
    topRightButton: true,
    sectionThird: false,
  },
};

export const edit = {
  employees: {
    title: 'Edit',
    filters: [
      {name: 'Filter by Operative Name'},
      {name: 'Filter by Operative Type'},
    ],
    cta: [
      {
        className: 'view-all',
        key: 'employees',
        value: '+ Add Operative',
      },
    ],
  },
  timesheets: {
    title: 'Edit',
    filters: [
      {name: 'Filter by Site Code/ Site Name'},
      {name: 'Filter by Job Role'},
      {name: 'Filter by Approval Status'},
      {name: 'Filter by Week'},
      {name: 'Filter by Operative'},
    ],
    cta: [
      {
        className: 'sec-outlined',
        key: 'timesheets',
        value: 'Export Data',
      },
    ],
  },
  sites: {
    key: 'sites',
    title: 'Edit Sites',
    filters: [
      {name: 'Site Manager Name'},
      {name: 'Site Name'},
      {name: 'Site Code'},
      {name: 'Site Address'},
      {name: 'Permitted Hour'},
    ],
    cta: [
      {
        className: 'view-all',
        key: 'sites',
        value: '+ Add Site',
      },
    ],
  },
  rateCards: {
    key: 'rateCards',
    title: '',
    filters: [{name: 'Rate Card Name'}],
    cta: [
      {
        className: 'view-all',
        key: 'rateCards',
        value: '+ Add Rate Card',
      },
    ],
  },
};
export const add = {
  sites: {
    key: 'sites',
    title: 'Add Sites',
    filters: [
      {
        label: 'Site Manager Name',
        name: 'siteManagerName'
      },
      {
        label: 'Site Name',
        name: 'siteName'
      },
      {
        label: 'Site Code',
        name: 'siteCode'
      },
      {
        label: 'Site Address',
        name: 'siteAddress'
      },
      {
        label: 'Permitted Hour',
        name:'permittedHours'
      },
    ],
    buttons: [
      {
        className: 'view-all',
        key: 'add-sites',
        value: '+ Add Operative',
      },
      {
        className: 'contained',
        key: 'sites',
        value: 'Save',
      },
    ],
  },
  rateCards: {
    key: 'rateCards',
    title: 'Add Rate Cards',
    filters: [{name: 'Rate Card Name'}],
    buttons: [
      {
        className: 'contained',
        key: 'add-ratecard',
        value: '+ Add Job Role',
      },
      {
        className: 'contained',
        key: 'rateCards',
        value: 'Save',
      },
    ],
    showTable: true,
  },
};

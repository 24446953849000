import React, {useState, useEffect} from 'react';
import {
  Box,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {
  MdOutlineEdit,
  MdOutlineDelete,
  MdOutlineClose,
} from 'react-icons/md';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';

import {tableData} from '../../../utils/tableData';
import DataTable from '../../../components/dataTable/DataTable';
import {
  deleteRateCard,
  getAllRateCards,
  rateCardActions,
  saveRateCard
} from '../../../data/reducers/app/rateCards.reducer';

import ConfirmationDialog from '../../../components/ConfirmationDialog';
import Navigation from '../../../components/navigation/Navigation';
import ProButton from '../../../components/common-comp/ProButton';
import AddRateCard from './AddRateCard';

import '../../../components/common-comp/CommonComp.css';
import Title from '../../../components/common-comp/Title';

const customContent = {
  key: 'rateCards',
  title: 'Rate Cards',
  filters: [{name: 'Name'}],
  cta: [
    {
      className: 'view-all',
      key: 'rateCards',
      value: '+ Add Rate Card',
    },
  ],
};

const tableDataCol = tableData.rateCards;

const RateCards = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [addPopup, setAddPopup] = useState(false);
  //const [editRow, setEditRow] = useState(null);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [showButtons, setShowButtons] = useState(false);
  const [SortField, setSortField] = useState('');

  const {rateCards, error, message, paging} = useSelector(
    (state) => state.rateCards,
  );

  const [filter, setFilter] = useState({
    PageNumber: 1,
    SortDir: 0,
    SortField: '',
    PageSize: 10,
    Name: '',
  });

  useEffect(() => {
    handleFetch();
    if (filter?.Name) {
      setShowButtons(true);
    }
  }, [filter],[SortField]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    } else if (message) {
      toast.success(message);
    }
  }, [error, message]);

  const handleFetch = async () => {
    setShowButtons(false);
    setConfirmPopup(false);
    await dispatch(
      getAllRateCards({
        endpoint: `RateCards/get-all?SortField=${SortField}&SortDir=${filter?.SortDir}&PageNumber=${filter?.PageNumber}&PageSize=${filter?.PageSize}&rateCardName=${filter?.Name}`,
      }),
    );
  };

  const deleteRateCardFunction = async () => {
    if (deleteId) {
      await dispatch(
        deleteRateCard({endpoint: `RateCards/delete/${deleteId}`}),
      );
    }
    setDeleteId('');
    setFilter({
      PageNumber: 1,
      SortDir: 0,
      SortField: '',
      PageSize: 50,
      Name: '',
    });
    dispatch(rateCardActions.rateCardReset());
  };

  const handleEdit = (tableMeta) => {
    // <--- For editing a record
    let card = rateCards[tableMeta.rowIndex];
    navigate(`/rate-cards/edit/${card?.id}`);
  };

  const handleOnClick = () => {
    setAddPopup(true);
  };

  const handleDate = (dateVal) => {
    var date = new Date(dateVal);
    var print =
      (date.getMonth() > 8
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)) +
      '/' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
      '/' +
      date.getFullYear();
    return `${print}`;
  };

  const sortHandle = (name) => {
		//debugger
		if (SortField === name) {
			console.log("yes same field")
      setSortField(name);
			if (filter?.SortDir === 0){
        setFilter({...filter, SortDir: 1});
      }else{
        setFilter({...filter, SortDir: 0});
      }
		} else {
			setSortField(name);
			setFilter({...filter, SortDir: 1});
		}
	};

  const addRatecard = async(data,updated) =>{
    if(!updated){
      await dispatch(
        saveRateCard({
          endpoint: `RateCards/create`,
          data,
        }),
      );
      if(!error){
        setAddPopup(false);
        handleFetch();
      }
    }
    
  }

  const tableCols = tableDataCol?.columns.map((column) => {
    // <--- Creating table columns dynamically
    if (column?.link) {
      return {
        name: column.name,
        label: column.label,
        options: {
          customBodyRender: (value, tableMeta) => {
            return (
              <div
                onClick={() => {
                  let card = rateCards[tableMeta.rowIndex];
                  navigate(`/rate-cards/details/${card?.id}`, {
                    state: card?.id,
                  });
                }}
                style={{cursor: 'pointer', textDecoration: 'underline'}}>
                {value}
              </div>
            );
          },
          customHeadRender: () =>{
            return (
              <Title
                name={column?.name}
                title={column?.label}
                sortField={SortField}
                sortDir={filter?.SortDir}
                sortHandle={sortHandle}
				      />
            )
          },
        },
      };
    } else if (column?.date) {
      return {
        name: column.name,
        label: column.label,
        options: {
          customBodyRender: (value) => {
            return handleDate(value);
          },
          customHeadRender: () =>{
            return (
              <Title
                name={column?.name}
                title={column?.label}
                sortField={SortField}
                sortDir={filter?.SortDir}
                sortHandle={sortHandle}
				      />
            )
          },
        },
      };
    }else if(column?.noSort){
      return {
        name: column.name,
        label: column.label,
      };
    }else {
      return {
        name: column.name,
        label: column.label,
        options:{
          customHeadRender: () =>{
            return (
              <Title
                name={column?.name}
                title={column?.label}
                sortField={SortField}
                sortDir={filter?.SortDir}
                sortHandle={sortHandle}
				      />
            )
          },
        }
      };
    }
  });

  tableCols?.push({
    name: 'Actions',
    options: {
      customBodyRender: (_, tableMeta) => {
        return (
          <span
            style={{
              padding: '.5rem',
              width: 'max-content',
            }}>
            <button
              onClick={() => handleEdit(tableMeta)}
              className="table-buttons">
              <MdOutlineEdit size="1.8rem" />
            </button>
            <button
              className="table-buttons"
              onClick={() => {
                setConfirmPopup(true);
                setDeleteId(rateCards[tableMeta.rowIndex]?.id);
              }}>
              <MdOutlineDelete size="1.8rem" />
            </button>
          </span>
        );
      },
    },
  });

  const navigation = [
    {
      name: ' Rate Cards ',
    },
  ]

  return (
    <div className="container">
      <div className="page-container">
        <Navigation path={navigation} />
        <Box className="section-top">
          <h1>{customContent.title}</h1>
        </Box>
        <Grid
          container
          spacing={3}
          display="flex"
          justifyContent="space-between">
          {customContent.filters.map((fil, i) => {
            return (
              <Grid key={i} item xl={3} lg={4} md={5} sm={8} xs={12}>
                <Typography variant="h6">{fil?.name}</Typography>
                <TextField
                  id="outlined-required"
                  placeholder="Search..."
                  fullWidth
                  value={filter.Name}
                  onChange={(e) => {
                    setFilter({...filter, Name: e.target.value,
                      PageNumber: 1
                    });
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        {showButtons && (
                          <IconButton
                            onClick={() => {
                              setFilter({...filter, Name: ''});

                            }}>
                            <MdOutlineClose size="2rem" />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            );
          })}

          <Box
            alignItems="center"
            display="flex"
            alignContent="center"
            flexDirection="column-reverse">
            <ProButton
              text="+ Add Rate Card"
              color="#6eb066"
              handleClick={handleOnClick}
              variant="contained"
              mx0
            />
          </Box>
        </Grid>

        <Box className="section-table">
          <DataTable
            newCols={tableCols}
            newData={rateCards}
            pagination={true}
            totalCount={paging.totalItemsCount}
            filter={filter}
            setFilter={setFilter}
          />
        </Box>
      </div>
      <ConfirmationDialog
        open={confirmPopup}
        text="Are you sure you want to delete this rate card?"
        handleClose={() => setConfirmPopup(false)}
        submitHandle={() => {
          deleteRateCardFunction();
          setConfirmPopup(false);
        }}
      />
      <AddRateCard
        open={addPopup}
        handleClose={() => setAddPopup(false)}
        submitHandle={addRatecard}
        //selectedRecord={editRow}
      />
    </div>
  );
};

export default RateCards;
